@charset "utf-8";

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Anybody:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box;
}

article, aside, details, figcaption, figure, footer, header, menu, nav, section {
  display: block;
}

:focus {
  outline: 0;
}

ol, ul {
  list-style: none;
}

a {
  text-decoration: none;
  display: block;
}

a:hover {
  text-decoration: none;
}

input, textarea {
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
}

label, button, input[type="submit"], input[type="button"] {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

img {
  max-width: 100%;
  vertical-align: middle;
  height: auto;
}

button, input[type="submit"] {
  -webkit-appearance: none;
  -moz-appearance: none;
}

* {
  box-sizing: border-box;
}

:root {
  --cl-black: #000000;
  --cl-black-rgb: 0 0 0;

  --cl-navy: #061344;

  --cl-blue: #5500EC;

  --cl-purple: #8900EA;

  --cl-turquoise: #1bd4ed;

  --cl-lilac: #E1E8FF;

  --cl-primary: #ec612a;
  --cl-primary-rgb: 236 97 42;

  --cl-white: #ffffff;
  --cl-white-rgb: 255 255 255;

  --font-main: "Poppins", sans-serif;
  --font-second: "Anybody", sans-serif;

  --input-border-color: rgba(6,19,68,0.15);

  /* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
  --mobile-menu-start-point: 1200;

  --vh: calc(100vh / 100);
  --dvh: calc(100vh / 100);
}

/* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */

/* @custom-media --mobile-menu-start-point (max-width: 1200px); */

/* --mobile-menu-end-point must be greater than 1 of the  --mobile-menu-start-point and @custom-media --mobile-menu-start-point */

/* @custom-media --mobile-menu-end-point (min-width: 1201px); */

@font-face {
  font-family: 'icomoon';
  src:  url('../../assets/fonts/icomoon.eot?my69dg');
  src:  url('../../assets/fonts/icomoon.eot?my69dg#iefix') format('embedded-opentype'),
  url('../../assets/fonts/icomoon.ttf?my69dg') format('truetype'),
  url('../../assets/fonts/icomoon.woff?my69dg') format('woff'),
  url('../../assets/fonts/icomoon.svg?my69dg#icomoon') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: 400;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-phone:before {
  content: "\e910";
}

.icon-email:before {
  content: "\e911";
}

.icon-search:before {
  content: "\e912";
}

.icon-upload:before {
  content: "\e90f";
}

.icon-chevron-right:before {
  content: "\e90d";
}

.icon-chevron-left:before {
  content: "\e90e";
}

.icon-arrow-right-long:before {
  content: "\e90c";
}

.icon-arrow-down:before {
  content: "\e900";
}

.icon-arrow-left:before {
  content: "\e901";
}

.icon-arrow-next:before {
  content: "\e902";
}

.icon-arrow-prev:before {
  content: "\e903";
}

.icon-arrow-right:before {
  content: "\e904";
}

.icon-arrow-up:before {
  content: "\e905";
}

.icon-check:before {
  content: "\e906";
}

.icon-close:before {
  content: "\e907";
}

.icon-facebook:before {
  content: "\e908";
}

.icon-instagram:before {
  content: "\e909";
}

.icon-linkedin:before {
  content: "\e90a";
}

.icon-twitter:before {
  content: "\e90b";
}

h1 {
  font-size: clamp(42px, calc(42px + 98.00 * ((100vw - 768px) / 672)), 140px);
  font-weight: 800;
  line-height: 1.0354;
  font-family: var(--font-second);
}

@media not all and (min-width: 374px) {

h1 {
    font-size: 40px
}
	}

@media not all and (min-width: 330px) {

h1 {
    font-size: 38px
}
	}

h2 {
  font-size: clamp(36px, calc(36px + 64.00 * ((100vw - 768px) / 672)), 100px);
  font-weight: 800;
  line-height: 1.03846;
  font-family: var(--font-second);
}

h2.big-size {
    font-size: clamp(42px, calc(42px + 88.00 * ((100vw - 768px) / 672)), 130px);
  }

@media not all and (min-width: 374px) {

h2.big-size {
      font-size: 40px
  }
	}

@media not all and (min-width: 330px) {

h2.big-size {
      font-size: 38px
  }
	}

h2.small-size {
    font-size: clamp(36px, calc(36px + 24.00 * ((100vw - 768px) / 672)), 60px);
  }

h3 {
  font-size: clamp(26px, calc(26px + 18.00 * ((100vw - 768px) / 672)), 44px);
  font-weight: 700;
  line-height: normal;
}

h4 {
  font-size: clamp(24px, calc(24px + 6.00 * ((100vw - 768px) / 672)), 30px);
  line-height: normal;
  font-weight: 800;
}

h5 {
  font-size: clamp(20px, calc(20px + 8.00 * ((100vw - 768px) / 672)), 28px);
  font-weight: 500;
  line-height: 1.5;
}

h6 {
  font-size: clamp(18px, calc(18px + 6.00 * ((100vw - 768px) / 672)), 24px);
  font-weight: 500;

  line-height: 2;
}

@media not all and (min-width: 640px) {

p {
    line-height: 1.33
}
  }

li {
  font-size: clamp(18px, calc(18px + 2.00 * ((100vw - 768px) / 672)), 20px);
  font-weight: 400;
  line-height: 1.25;
}

@media not all and (min-width: 640px) {

li {
    line-height: 1.33
}
  }

a {
  display: inline-block;
  font-size: clamp(14px, calc(14px + 2.00 * ((100vw - 768px) / 672)), 16px);
  font-weight: 400;
  line-height: 1.62;
}

@media not all and (min-width: 640px) {

a {
    line-height: 1.57
}
  }

.content-element h1, .content-element h2, .content-element h3, .content-element h4, .content-element h5, .content-element h6 {
  }

.content-element.first-big-paragraph p:first-child {
        font-family: var(--font-second);
        font-weight: 500;
        font-size: clamp(22px, calc(22px + 2.00 * ((100vw - 768px) / 672)), 24px);
        line-height: 1.42;
      }

.content-element.first-big-paragraph p:first-child:not(:last-child) {
          margin-bottom: clamp(24px, calc(24px + 1.00 * ((100vw - 768px) / 672)), 25px);
        }

@media not all and (min-width: 640px) {

.content-element.first-big-paragraph p:first-child{
          line-height: 1.2;
  }
  }

.content-element h4 {
    color: var(--cl-medium-grey);
    font-weight: 500;
  }

.content-element h4:not(:last-child) {
      margin-bottom: clamp(16px, calc(16px + 4.00 * ((100vw - 768px) / 672)), 20px);
    }

.content-element ol li, .content-element ul li {
      list-style-position: inside;
    }

.content-element ol {
    list-style: decimal;
  }

.content-element ul {
    list-style: disc;
  }

/*Not Design*/

.content-element li:not(:last-child), .content-element p:not(:last-child) {
      margin-bottom: clamp(5px, calc(8px + -3.00 * ((100vw - 768px) / 672)), 8px);
    }

.content-element.smaller-size p, .content-element.smaller-size li {
      font-size: clamp(14px, calc(14px + 2.00 * ((100vw - 768px) / 672)), 16px);
      font-weight: 400;
      line-height: normal;
    }

.content-element.small-size p, .content-element.small-size li {
      font-size: 18px;
      font-weight: 400;
      line-height: normal;
    }

.content-element.big-size {
    /*p {
      font-size: ac(24px, 22px);
      line-height: 1.42;
    }*/
  }

.content-element .simplebar-track.simplebar-vertical {
    width: clamp(2px, calc(2px + 1.00 * ((100vw - 768px) / 672)), 3px);
    background: var(--cl-turquoise);
    border-radius: 0;
  }

.content-element .simplebar-track.simplebar-vertical .simplebar-scrollbar {
      background: var(--cl-navy);
      border-radius: 0;
    }

.content-element .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
        content: none;
      }

.dark-bg h1, .dark-bg h2, .dark-bg h3, .dark-bg h4, .dark-bg h5, .dark-bg h6, .dark-bg p, .dark-bg li {
    color: var(--cl-white);
  }

.fp-watermark {
  display: none;
}

html {
  scrollbar-width: thin;
  scrollbar-color: var(--cl-navy) transparent;
  font-size: clamp(18px, calc(18px + 2.00 * ((100vw - 768px) / 672)), 20px);
  font-weight: 400;
  line-height: 1.5;
}

body {
  position: relative;
  min-width: 320px;
  margin: auto;
  background: var(--cl-white);
  color: var(--cl-navy);
  font-family: var(--font-main);
  min-height: calc(100 * var(--vh));
  display: flex;



}

body::-webkit-scrollbar {
    width: 6px;
  }

body::-webkit-scrollbar-track {
    background: var(--cl-navy);
    border-radius: 0;
  }

body::-webkit-scrollbar-thumb {
    background: var(--cl-blue);
    border-radius: 0;
  }

body .simplebar-scrollbar::before {
     background-color: var(--cl-blue);
     opacity: 1!important;
     left: 1px;
     right: 1px;
  }

body .simplebar-track.simplebar-vertical{
     background: rgba(255,255,255, 0.2)!important;
     opacity: 1!important;
     width: 6px;
     border-radius: 5px;
     transform: translateX(8px);
  }

.swiper-wrapper {
  left: 0 !important;
}

#site {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

@media not all and (min-width: 640px) {

#site {
    overflow: hidden!important
}
  }

.main {
  flex-grow: 1;
}

.cont {
  margin: 0 auto;
  max-width: 1400px;
  width: 97.22222222222223%;
}

@media not all and (min-width: 1024px) {

.cont {
    width: 94%
}
  }

@media not all and (min-width: 640px) {

.cont {
    width: 93.6%
}
  }

@media (min-width: 1450px) {

.cont {
    width: 94.58%;
    max-width: 1816px
}
	}

.cont-second {
  width: 91.57%;
  margin: 0 auto;
  max-width: 1282px;
}

@media not all and (min-width: 640px) {

.cont-second {
    width: 93.16%
}
  }

.cont-main {
  width: 100%;
  max-width: 1336px;
  margin: 0 auto;
}

section, footer, header {
  position: relative;
  width: 100%;
  max-width: 100vw;
}

.sections-container {
  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  overflow: hidden;
}

.title-animation {
  opacity: 0;
}

.title-animation .title-line {
    position: relative;
    z-index: 1;
  }

.title-animation .title-line:before {
      bottom: 13%;
      right: clamp(-18px, calc(-4px + -14.00 * ((100vw - 768px) / 672)), -4px);
      width: 0;
      height: clamp(12px, calc(12px + 22.00 * ((100vw - 768px) / 672)), 34px);
      opacity: 0;
      content: '';
      position: absolute;
      background: var(--cl-leaf);
      transition: width 1s ease, opacity 1s ease;
    }

.title-animation.animated {
    opacity: 1;
  }

.title-animation.animated .title-line:before {
        opacity: 1;
        width: calc(100% + (clamp(4px, calc(4px + 14.00 * ((100vw - 768px) / 672)), 18px) * 2));
      }

.title-animation.animated .splitting .word {
      animation: slide-in 0.6s cubic-bezier(0.5, 0, 0.5, 1) both;
      animation-delay: calc(150ms * var(--line-index));
      /*clip-path: polygon(110% 0, 110% 100%, 0 100%, 0 0);*/
    }

.second-title-animation {
  transition: all 0.3s ease;
  opacity: 0;
  transform: scaleX(1.1) translateX(5%);
  transform-origin: top left;
}

.second-title-animation.animated {
    opacity: 1;
    transform: scaleX(1) translateX(0);
  }

.sepia-background {
  position: absolute;
  inset: 0;
  background-image: url('../../assets/static/sepia-background.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  opacity: 0.1;
}

@keyframes slide-in {
  from {
    transform: translateX(0.5em) rotate(0);
    opacity: 0;
    /*clip-path: polygon(110% 80%, 110% 100%, 0 100%, 0 80%);*/
  }
}

.swiper {
  width: 100%;
  max-width: 100%;
}

.logo {
  transition: 0.3s ease;
}

.logo img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
       object-fit: contain;
  }

.logo:hover {
    transform: scale(1.04);
  }

.sticky-buttons-container {
  position: absolute;
  padding-top: 40vh;
  top: 0;
  right: 0;
  bottom: 0;
}

.sticky-buttons {
  position: sticky;
  right: 0;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 17px;
  z-index: 500;
  transform: rotate(-90deg) translateY(-50%) translate(50%);
  transform-origin: right;
  margin-top: 100vh;
}

.sticky-buttons .btn {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.24),
      -2px 0px 32px rgba(0, 0, 0, 0.12);
    height: clamp(40px, calc(40px + 24.00 * ((100vw - 768px) / 672)), 64px);
  }

.sticky-buttons .btn__text {
      font-size: clamp(16px, calc(16px + 4.00 * ((100vw - 768px) / 672)), 20px);
    }

@media not all and (min-width: 640px) {

.sticky-buttons{
    display: none;
  }
  }

#bold-credits {
  width: 150px;
  transition: ease-in-out 0.25s;
}

#bold-credits::before, #bold-credits::after {
    display: none;
  }

#bold-credits:hover {
    transform: scale(1.1);
  }

#site.cookie-terms-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  position: relative;
}

#site.cookie-terms-page .header:before{
    opacity: 0.6;
    background-color: var(--cl-navy);
  }

#site.cookie-terms-page .section-bg{
    z-index: -1;
  }

#site.cookie-terms-page .main {
    flex-grow: 1;
    padding: 0;
  }

.cookie-terms-section{
  padding-top: clamp(140px, calc(140px + 110.00 * ((100vw - 768px) / 672)), 250px);
  padding-bottom: clamp(50px, calc(50px + 30.00 * ((100vw - 768px) / 672)), 80px);



}

.cookie-terms-section ol, .cookie-terms-section ul{
    padding-left: 23px;
    margin: 25px 0;
  }

.cookie-terms-section ol li,.cookie-terms-section ul li{
     list-style-position: outside;
   }

.cookie-terms-section h1, .cookie-terms-section h2, .cookie-terms-section h3, .cookie-terms-section h4, .cookie-terms-section h5{
      line-height: 1.15;
      font-weight: 700;
      margin-bottom: 15px;
  }

.cookie-terms-section h1{
      font-size: clamp(40px, calc(40px + 40.00 * ((100vw - 768px) / 672)), 80px);
    margin-bottom: 30px;
  }

.cookie-terms-section h2{
      font-size: clamp(35px, calc(35px + 35.00 * ((100vw - 768px) / 672)), 70px);
    margin-bottom: 25px;
  }

.cookie-terms-section h3{
      font-size: clamp(28px, calc(28px + 22.00 * ((100vw - 768px) / 672)), 50px);
    margin-bottom: 20px;
  }

.cookie-terms-section h4{
      font-size: clamp(24px, calc(24px + 21.00 * ((100vw - 768px) / 672)), 45px);
    margin-bottom: 15px;
  }

.cookie-terms-section h5{
      font-size: clamp(22px, calc(22px + 18.00 * ((100vw - 768px) / 672)), 40px);
    margin-bottom: 10px;
  }

.cookie-terms-section h5{
      font-size: clamp(20px, calc(20px + 15.00 * ((100vw - 768px) / 672)), 35px);
    margin-bottom: 10px;
  }

.cookie-terms-section ul, .cookie-terms-section ol{
      list-style: disc;
      padding-left: 23px;
      margin: 20px 0;
  }

.cookie-terms-section ol{
      list-style: decimal;
  }

.cookie-terms-section a:not([class]) {
      display: inline;
      color: var(--purple);
      text-decoration: underline;
      transition: 0.3s ease;
  }

.cookie-terms-section a:not([class]):hover {
          color: var(--orange);
      }

.cookie-terms-section b, .cookie-terms-section strong{
      font-weight: 700;
  }

.cookie-terms-section p:not(:last-child) {
          margin-bottom: 20px;
      }

.bg-turquoise {
  background: var(--cl-turquoise);
}

.section-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
}

.section-bg img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    transition: 0.3s;
  }

.section-preview {
  position: relative;
  overflow: hidden;
  min-height: 200px;
  border-radius: 20px;
}

.section-preview .thumbnail-layer {
    opacity: 0.8;
  }

.section-preview::before {
    content: '';
    display: block;
    padding-top: 28.67%;
  }

.section-preview img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    transition: 0.3s;
  }

.page-pagination{
  padding-top: clamp(40px, calc(40px + 24.00 * ((100vw - 768px) / 672)), 64px);
}

.page-pagination ul{
    display: flex;
    justify-content: center;
    gap: 8px;
  }

.page-pagination ul li{
      width: clamp(34px, calc(34px + 10.00 * ((100vw - 768px) / 672)), 44px);
      height: clamp(34px, calc(34px + 10.00 * ((100vw - 768px) / 672)), 44px);
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s;
      border-radius: 50%;
      font-weight: 500;
      font-size: clamp(16px, calc(16px + 2.00 * ((100vw - 768px) / 672)), 18px);
    }

.page-pagination ul li:not([class]){
        width: 28px;
      }

.page-pagination ul li.active{
        font-weight: 700;
        font-size: clamp(18px, calc(18px + 2.00 * ((100vw - 768px) / 672)), 20px);
        color: var(--cl-white);
        background: var(--cl-blue);
      }

.page-pagination ul li.page-pagination-button{
        font-size: 24px;
        cursor: pointer;
      }

.page-pagination ul li.page-pagination-button:hover{
          background: var(--cl-turquoise);
          color: var(--cl-white);
        }

.page-pagination-item{
    cursor: pointer;
  }

.page-pagination-item:hover{
      background: var(--cl-turquoise);
    }

.fz-16{
  font-size: 16px;
 }

.about-us-page .first-section__subtitle {
      text-align: left;
    }

.about-us-page .first-section__wrap {
      padding-bottom: clamp(0px, calc(0px + 190.00 * ((100vw - 768px) / 672)), 190px);
    }

.about-us-page .first-section__title h1 span:nth-of-type(2) {
          margin-left: clamp(30px, calc(30px + 60.00 * ((100vw - 768px) / 672)), 90px);
        }

.about-us-page .first-section .fig-wrapper {
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(45.4%, -36.2%);
      width: 81.04166666666667%;
      max-width: 1228px;
    }

.about-us-page .first-section .fig-wrapper-top {
        transform: translate(50.8%, -38.9%);
      }

.about-us-page .first-section .fig-wrapper-top img {
          position: relative;
        }

.about-us-page .first-section .fig img {
        width: 100%;
        height: auto;
        position: relative;
        z-index: 0;
        mix-blend-mode: screen;
      }

.wlc-section-wrapper {
  background: var(--cl-lilac);
}

.wlc-section {
  position: relative;
  background: var(--cl-lilac);
  padding: clamp(58px, calc(58px + 90.00 * ((100vw - 768px) / 672)), 148px) 0 80px;
}

.wlc-section .btn {
    margin-top: 32px;
  }

.wlc-section .bg-fig {
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-50%, -50%);
    width: 15.972222222222221%;
    max-width: 230px;
    min-width: 100px;
    z-index: 120;
  }

.wlc-section .bg-fig img {
      width: 100%;
      height: auto;
    }

.wlc-section .bg-layer-wr {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-62%, -48%);
    z-index: 0;
    overflow: hidden;
  }

.wlc-section .bg-layer-wr .bg-layer {
      position: absolute;
      left: 50% !important;
      top: 50% !important;
      transform: translate(-50%, -50%) !important;
    }

.wlc-section .bg-layer-wr img {
      max-width: revert;
      width: 157.15277777777777%;
    }

.wlc-section .cont-second {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
  }

.wlc-section-thumbnail {
    max-width: 592px;
    flex: 1;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
  }

.wlc-section-thumbnail::before {
      content: '';
      display: block;
      padding-top: 100%;
    }

.wlc-section-thumbnail img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
    }

.wlc-section-text {
    flex: 1;
    max-width: 632px;
    font-weight: 300;
  }

.wlc-section .section-description {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

.wlc-section .title-animation {
    text-align: right;
    color: var(--cl-blue);
    margin-bottom: 16px;
  }

.wlc-section .title-animation i {
      display: inline-block;
      margin-bottom: 16px;
      font-size: 60.19%;
      font-style: normal;
    }

.values-section {
  position: relative;
  z-index: 2;
}

.values-section .section-bg{
  z-index: -1;
}

.values-section .section-bg::before{
    content: '';
    display:block;
    position: absolute;
    inset: 0;
    z-index: 1;
    opacity: 0.9;
    background: var(--cl-turquoise);
  }

.values-section .title-animation {
    mix-blend-mode: normal;
    color: #47ffff;
    margin-bottom: clamp(40px, calc(40px + 13.00 * ((100vw - 768px) / 672)), 53px);
  }

.values-section .title-animation span {
      display: inline-block;
      line-height: 1;
    }

.values-section .title-animation span:nth-of-type(2) {
        margin-left: clamp(30px, calc(30px + 37.00 * ((100vw - 768px) / 672)), 67px);
        transform: translateY(-6%);
      }

.values-section .cont {
    border-radius: 12px;
    padding: clamp(50px, calc(50px + 70.00 * ((100vw - 768px) / 672)), 120px) 0;
    transform: translate(0);
    overflow: hidden;
  }

@media (max-width: 1100px){

.values-section .cont-second{
        padding: 0 15px
    }
      }

.values-section .section-figures-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    mix-blend-mode: overlay;
    overflow: hidden;
    pointer-events: none;
  }

.values-section .section-figures {
    width: 81.66666666666667%;
    max-width: 1176px;
    position: absolute;
    top: 32.8%;
    left: 80.5%;
    transform: translate(-50%, -50%);
  }

.values-section .section-figures::before {
      content: '';
      display: block;
      padding-top: 96.96%;
    }

.values-section .section-figures .fig {
      position: absolute;
    }

.values-section .section-figures .fig img {
        width: 100%;
        height: auto;
      }

.values-section .section-figures .fig.fig-1 {
        width: 1016px;
        top: 0;
        right: 0;
        max-width: 89%;
      }

.values-section .section-figures .fig.fig-2 {
        width: 897px;
        bottom: -28%;
        left: 0.4%;
        max-width: 79%;
      }

.values-section .section-figures .fig.fig-3 {
        width: 505px;
        left: 1.7%;
        top: 31.2%;
        max-width: 44%;
      }

.values-section .bg-layer {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);
    width: 15.972222222222221%;
    min-width: 100px;
  }

.values-list {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: clamp(10px, calc(10px + 10.00 * ((100vw - 768px) / 672)), 20px);
  position: relative;
  z-index: 2;
}

.timeline-section {
  --mt: 258px;
  --mb: clamp(250px, calc(250px + 266.00 * ((100vw - 768px) / 672)), 516px);
  padding-top: var(--mt);
  margin-top: calc(var(--mt) * -1);
  transform: translate(0);
  padding-bottom: var(--mb);
  margin-bottom: calc(var(--mb) * -1);
  background: linear-gradient(
    270deg,
    var(--cl-turquoise),
    var(--cl-purple),
    var(--cl-blue)
  );
  overflow: hidden;
}

.timeline-section .bg-layer {
    position: absolute;
    right: 0;
    bottom: clamp(50px, calc(50px + 61.00 * ((100vw - 768px) / 672)), 111px);
    max-width: 56%;
    z-index: -1;
  }

.timeline-section .bg-layer img {
      width: 100%;
    }

.timeline-section .cont {
    padding-top: clamp(70px, calc(70px + 50.00 * ((100vw - 768px) / 672)), 120px);
    padding-bottom: clamp(50px, calc(50px + 70.00 * ((100vw - 768px) / 672)), 120px);
  }

.timeline-section .title-animation {
    font-size: clamp(32px, calc(32px + 28.00 * ((100vw - 768px) / 672)), 60px);
    color: var(--cl-white);
  }

.timeline-section .title-wr {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    padding-bottom: 60px;
  }

.timeline-section .title-wr .slider-btn {
      margin: 0;
    }

.timeline-swiper-wr .swiper {
    width: 630px;
    max-width: 100%;
    margin: 0;
    overflow: visible;
  }

.timeline-swiper-wr .swiper-slide {
      position: relative;
    }

.timeline-swiper-wr .swiper-slide.swiper-slide-active .timeline-date-card-date {
            color: var(--cl-white);
          }

.timeline-swiper-wr .swiper-slide.swiper-slide-active .timeline-date-card-title {
            position: absolute;
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.3s;
          }

.timeline-swiper-wr .swiper-timeline-date {
    mix-blend-mode: overlay;
  }

.timeline-date-card {
  color: var(--cl-white);
}

.timeline-date-card-date {
    font-size: clamp(60px, calc(60px + 80.00 * ((100vw - 768px) / 672)), 140px);
    font-weight: 800;
    display: flex;
    gap: 20px;
    margin-bottom: 12px;
    line-height: 1.03;
    color: rgba(255, 255, 255, 0.05);
    -webkit-text-stroke: 1px #ffffff;
    transition: color 0.5s;
  }

.timeline-date-card-date span {
      display: block;
      flex: 1;
      height: 1px;
      background: #000;
      background: var(--cl-white);
      align-self: center;
    }

.timeline-date-card-title {
    font-size: clamp(24px, calc(24px + 10.00 * ((100vw - 768px) / 672)), 34px);
    font-weight: 800;
    line-height: 1.5;
    transition: opacity 0.3s 0.5s;
  }

.timeline-card {
  position: relative;
  width: 522px;
  max-width: 100%;
}

.timeline-card-thumbnail-wr {
    position: relative;
    margin-bottom: 24px;
  }

.timeline-card-thumbnail-wr .layer {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      z-index: 1;
      mix-blend-mode: screen;
      border-radius: 25px;
    }

.timeline-card-thumbnail-wr .layer img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
           object-fit: cover;
      }

.timeline-card-thumbnail {
    position: relative;
    overflow: hidden;
    border-radius: 25px;
    z-index: 0;
  }

.timeline-card-thumbnail::before {
      content: '';
      display: block;
      padding-top: 56.32%;
    }

.timeline-card-thumbnail img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
    }

.timeline-card-title {
    color: var(--cl-white);
    font-size: clamp(24px, calc(24px + 10.00 * ((100vw - 768px) / 672)), 34px);
    font-weight: 800;
    margin-bottom: 8px;
    line-height: 1.5;
  }

.timeline-card-description {
    color: var(--cl-white);
    font-weight: 400;
    width: 100%;

    --line-count:7;
    --fz: 18px;
    --line-height: 1.5;
    line-height: var(--line-height);
    font-size: var(--fz);
    max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
  }

.timeline-card-description p{
      line-height: inherit;
    }

.timeline-card-description.simplebar-scrollable-y{
        padding-right: 15px;
    }

.timeline-card-description .simplebar-scrollbar::before {
      background: var(--cl-white);
    }

.timeline-card-description .simplebar-track.simplebar-vertical{

    }

.team-section .bg-layer {
    width: 410px;
    max-width: 30%;
    min-width: 250px;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-50%, -18.4%);
    z-index: -1;
  }

.team-section .cont {
    padding: clamp(35px, calc(35px + 86.00 * ((100vw - 768px) / 672)), 121px) 0 273px;
    position: relative;
    border-radius: 12px;
    background: var(--cl-white);
    transform: translate(0);
    overflow: hidden;
  }

.team-section .title-wr {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: flex-end;
    padding-bottom: clamp(40px, calc(40px + 12.00 * ((100vw - 768px) / 672)), 52px);
    padding-right: 7px;
  }

@media (max-width: 450px) {

.team-section .title-wr {
      flex-direction: column-reverse
  }
    }

.team-section .title-wr .slider-btn {
      margin: 0 0 6px;
    }

.team-section .title-animation {
    text-align: right;
    color: var(--cl-blue);
    margin: 0;
  }

.team-section .title-animation span:nth-of-type(2) {
        margin-right: clamp(30px, calc(30px + 42.00 * ((100vw - 768px) / 672)), 72px);
        transform: translateY(-6%);
      }

.team-swiper-wr .swiper {
    overflow: visible;
  }

.team-swiper-wr .swiper-slide {
      width: -moz-fit-content;
      width: fit-content;
      max-width: 100%;
    }

.teammate-card {
  width: clamp(330px, calc(330px + 47.00 * ((100vw - 768px) / 672)), 377px);
  max-width: 100%;
  position: relative;
  overflow: hidden;
  transform: translate(0);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 32px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0);
  transition: transform 0.3s, box-shadow 0.3s;
}

.teammate-card-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
  }

.teammate-card-bg img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
    }

.teammate-card-thumbnail-wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 50%;
    margin-bottom: 34px;
  }

.teammate-card-thumbnail {
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    z-index: 0;
    width: 100%;
  }

.teammate-card-thumbnail::before {
      content: '';
      display: block;
      padding-top: 100%;
    }

.teammate-card-thumbnail img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
    }

.teammate-card-thumbnail-layer {
      position: absolute;
      inset: 0;
      z-index: 2;
      opacity: 0.3;
    }

.teammate-card-thumbnail-layer img {
        position: absolute;
        left: 0;
        top: 0;
        -o-object-fit: cover;
           object-fit: cover;
        width: 100%;
        height: 100%;
      }

.teammate-card-logo {
    max-width: 107px;
    max-height: 25px;
    width: 100%;
    margin-bottom: 15px;
  }

.teammate-card-logo img {
      width: auto;
      height: auto;
    }

.teammate-card-name {
    font-size: clamp(24px, calc(24px + 10.00 * ((100vw - 768px) / 672)), 34px);
    font-weight: 800;
    line-height: 1.5;
    margin-bottom: 8px;
    margin-right: -10px;
  }

.teammate-card-position {
    font-family: var(--font-second);
    font-size: clamp(20px, calc(20px + 4.00 * ((100vw - 768px) / 672)), 24px);
    font-weight: 500;
    line-height: 1.3333;
    margin-bottom: 40px;
  }

.teammate-card-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    padding-right: 3px;
    margin-bottom: -2px;
  }

.teammate-card-button i {
      font-size: 14px;
    }

.teammate-card-button:hover{
      color: var(--cl-blue);
    }

.teammate-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  }

.get-in-touch-section.get-in-touch-section-about-us .get-in-touch-section__container {
    margin-left: auto;
    margin-right: auto;
  }

.get-in-touch-section.get-in-touch-section-about-us .get-in-touch-section__bg-decor-container {
    width: 607px;
    height: auto;
    top: 46% !important;
    left: 39% !important;
    bottom: auto;
    transform: translateY(-84%) !important;
  }

.get-in-touch-section.get-in-touch-section-about-us .get-in-touch-section__bg-decor-container::before {
      display: none;
    }

.get-in-touch-section.get-in-touch-section-about-us .get-in-touch-section__bg-decor-container img {
      position: relative;
      width: 100%;
      height: auto;
    }

@media (max-width: 768px) {
    .wlc-section .cont-second {
      flex-direction: column-reverse;
      gap: 40px;
    }

    .wlc-section-thumbnail {
      width: 400px;
      max-width: 100% !important;
    }

    .wlc-section-text {
      width: 100%;
      max-width: revert;
    }
}

@media (max-width: 650px) {
  .values-list {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 551px) {
    .about-us-page .first-section__wrap {
      justify-content: center;
    }
}

.areas-page .first-section__wrap{
            min-height: clamp(470px, calc(470px + 230.00 * ((100vw - 768px) / 672)), 700px);
            height: auto;
            padding-bottom: clamp(129px, calc(130px + -1.00 * ((100vw - 768px) / 672)), 130px);
            padding-top: clamp(140px, calc(170px + -30.00 * ((100vw - 768px) / 672)), 170px);
        }

.areas-page .first-section .fig-wrapper {
            position: absolute;
            right: 0;
            top: 0;
            transform: translate(56.9%, -48.3%);
            width: 80.83333333333333%;
            max-width: 1228px;
        }

.areas-page .first-section .fig-wrapper-top {
                transform: translate(62.4%, -51.1%);
            }

.areas-page .first-section .fig-wrapper-top img {
                    position: relative;

                }

.areas-page .first-section .fig img {
                width: 100%;
                height: auto;
                position: relative;
                z-index: 0;
                mix-blend-mode: screen;
            }

.areas-page .first-section + .areas-tabs-section .areas-tabs-wrapper{
                margin-top: clamp(-120px, calc(-90px + -30.00 * ((100vw - 768px) / 672)), -90px);
                z-index: 11;
                position: relative;
            }

.areas-page .first-section__title span:last-of-type{
                    margin-left: clamp(25px, calc(25px + 46.00 * ((100vw - 768px) / 672)), 71px);
                }

.areas-page .footer__wrap{
        padding-top: clamp(60px, calc(60px + 58.00 * ((100vw - 768px) / 672)), 118px) !important;
    }

.areas-page .testimonials-section__bg img{
        -o-object-fit: cover;
           object-fit: cover;
        -o-object-position: center bottom;
           object-position: center bottom;
    }

.areas-tab-switchers-item{
    border: 1px solid rgba(6,19,68,0.15);
    border-radius: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 25px;
    padding: 5px 12px 5px 24px;
    min-height: 64px;
    cursor: pointer;
    transition: 0.3s;
    font-weight: 700;
    font-family: var(--font-second);
    font-size: clamp(18px, calc(18px + 6.00 * ((100vw - 768px) / 672)), 24px);
    line-height: 1.3333;
}

.areas-tab-switchers-item-icon{
        position: relative;
        width: -moz-fit-content;
        width: fit-content;
        display: block;
    }

.areas-tab-switchers-item-icon::before{
            content: '';
            display:block;
            width: 40px;
            height: 40px;
            background: url("../../assets/images/tab.svg") no-repeat center / contain;
            transition: 0.1s;
        }

.areas-tab-switchers-item-icon::after{
            content: '';
            display:block;
            width: 100%;
            height: 100%;
            background: url("../../assets/images/tab-active.svg") no-repeat center / contain;
            transition: 0.1s;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
        }

.areas-tab-switchers-item-icon.active::before{
                opacity: 0;
            }

.areas-tab-switchers-item-icon.active::after{
                opacity: 1;
            }

.areas-tab-switchers-item.active{
        background: var(--cl-white);
    }

.areas-tab-switchers-item.active .areas-tab-switchers-item-icon::before{
                opacity: 0;
            }

.areas-tab-switchers-item.active .areas-tab-switchers-item-icon::after{
                opacity: 1;
            }

.areas-tab-switchers-item:hover{
        border-color: var(--cl-purple);
    }

.areas-tabs-wrapper{
    display: flex;
    justify-content: space-between;
    position: relative;
    transform: translateX(0);
    padding-left: clamp(20px, calc(20px + 40.00 * ((100vw - 768px) / 672)), 60px);
    filter:  drop-shadow(0 4px 12px rgba(0,0,0,0.04));
    margin-left: clamp(0px, calc(0px + 20.00 * ((100vw - 768px) / 672)), 20px);
    gap: clamp(20px, calc(20px + 40.00 * ((100vw - 768px) / 672)), 60px);
}

.areas-tabs-wrapper .section-bg{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        overflow: hidden;
        background: var(--cl-lilac);
        border-radius: 20px 0 0 20px;
        pointer-events: none;
    }

.areas-tabs-wrapper .section-bg img{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
               object-fit: cover;
            -o-object-position: center bottom;
               object-position: center bottom;
            transform: none;
            mix-blend-mode: screen;
        }

.areas-tab-switchers{
    padding: clamp(50px, calc(50px + 70.00 * ((100vw - 768px) / 672)), 120px) 0;
    max-width: 502px;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;

}



.swiper-tab-bg{
    position: absolute;
    top: 0;
    right: 0;
    width: 90%;
    height: 100%;
    z-index: 0;
    background: var(--cl-navy);
}

.swiper-tab-bg .swiper-slide{
        position: relative;
        height: auto;
    }

.areas-tab-info-bg-card{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
}

.areas-tab-info-bg-card img{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
           object-fit: cover;
        transition: 0.3s;
    }

.swiper-tab-content{
    position: relative;
    z-index: 1;
    max-width: 90%;
    margin: 0;
}

.areas-tab-info-item{
    position: relative;
    padding: clamp(30px, calc(30px + 10.00 * ((100vw - 768px) / 672)), 40px)clamp(20px, calc(20px + 20.00 * ((100vw - 768px) / 672)), 40px);
    border-radius: 20px;
    overflow: hidden;
    background: linear-gradient(103.02deg, rgba(6, 19, 68, 0.2) -1.78%, rgba(137, 0, 234, 0.2) 119.69%), url("../../assets/images/areas-tab-info-item-bg-el.jpg") no-repeat center / cover;
}

.areas-tab-info-item-inner{
        position: relative;
    }

.areas-tab-info-item-title{
        color: var(--cl-white);
        font-size: clamp(25px, calc(25px + 35.00 * ((100vw - 768px) / 672)), 60px);
        font-weight: 800;
        line-height: 1.035;
        margin-bottom: 8px;
        mix-blend-mode: overlay;
        font-family: var(--font-second);
    }

.areas-tab-info-item-content{
        --line-count:7;
        --fz: clamp(18px, calc(18px + 2.00 * ((100vw - 768px) / 672)), 20px);
        --line-height: 1.5;
        line-height: var(--line-height);
        font-size: var(--fz);
        max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
        color: var(--cl-white);
        font-weight: 300;

    }

.areas-tab-info-item .btn{
        margin-top: 32px;
    }

.wlc-section.wlc-section-areas{
    overflow: hidden;
    padding: clamp(50px, calc(50px + 70.00 * ((100vw - 768px) / 672)), 120px) 0;
}

.wlc-section.wlc-section-areas .section-bg{
        pointer-events: none;
    }

.wlc-section.wlc-section-areas .section-bg img{
            -o-object-position: left top;
               object-position: left top;
        }

.wlc-section.wlc-section-areas .title-animation{
        text-align: left;
    }

.wlc-section.wlc-section-areas .title-animation h2{

        }

.wlc-section.wlc-section-areas .title-animation span:nth-of-type(2){
                margin-left: clamp(25px, calc(25px + 22.00 * ((100vw - 768px) / 672)), 47px);
            }

.wlc-section.wlc-section-areas .wlc-section-thumbnail{
     max-width: 522px;
    }

.wlc-section.wlc-section-areas .wlc-section-text{
     max-width: 702px;
    }

.wlc-section.wlc-section-areas .bg-layer-wr{

        overflow: visible;
    }

.wlc-section.wlc-section-areas .bg-layer{
        width: 100%;
        transform: translate(-86.8%, -29.4%) !important;
    }

.roles-section.roles-section-areas{
    padding:clamp(50px, calc(50px + 70.00 * ((100vw - 768px) / 672)), 120px) 0;

}

.testimonials-section.testimonials-section-areas{
    margin: 0;
    padding-top: 11px;
    padding-bottom: clamp(40px, calc(40px + 78.00 * ((100vw - 768px) / 672)), 118px);
    overflow: visible;
}

.testimonials-section.testimonials-section-areas .bg-layer{
        position: absolute;
        right: 0;
        width: 15.972222222222221%;
        max-width: 230px;
        opacity: 0;
        transform: translate(50%, -50%) scale(0.5);
        transition: 0.5s;
        min-width: 100px;
    }

.testimonials-section.testimonials-section-areas .bg-layer.animated{
            opacity: 1;
            transform: translate(50%, -55%)scale(1);
        }

@media (max-width: 750px) {
    .areas-tabs-wrapper{
        flex-direction: column;
        width: calc(100% + 24px);
    }
    .areas-tab-switchers{
        max-width: 100%;
        width: 100%;
        padding-bottom: 40px;
        padding-right: 24px;
    }
    .areas-tab-swipers-wr{
        width: 100%;
        max-width: 100%;
        padding-top: clamp(195px, calc(195px + 105.00 * ((100vw - 375px) / 375)), 300px);
    }
}

@media (max-width: 450px) {
    .swiper-tab-content{
        width: 100%;
    }
}

.hiring-solutions-page {
  background: var(--cl-turquoise);
}

.hiring-solutions-page .first-section__wrap {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      padding-bottom: clamp(60px, calc(60px + 150.00 * ((100vw - 768px) / 672)), 210px);
      gap: 35px;
    }

.hiring-solutions-page .hero-thumbnail-wrapper {
    flex-shrink: 0;
    width: 412px;
    max-width: 37%;
    position: relative;
    align-self: center;
  }

.hiring-solutions-page .hero-thumbnail-wrapper::before {
      content: '';
      display: block;
      padding-top: 100%;
    }

.hiring-solutions-page .hero-thumbnail-wrapper .thumb-shadow {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      -o-object-fit: contain;
         object-fit: contain;
      -o-object-position: left bottom;
         object-position: left bottom;
      mix-blend-mode: overlay;
    }

.hiring-solutions-page .hero-thumbnail {
    width: 81.4%;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
  }

.hiring-solutions-page .hero-thumbnail::before {
      content: '';
      display: block;
      padding-top: 100%;
    }

.hiring-solutions-page .hero-thumbnail img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
      transition: 0.3s;
    }

.hiring-solutions-page .footer__wrap {
    padding-top: clamp(60px, calc(60px + 60.00 * ((100vw - 768px) / 672)), 120px) !important;
  }

.lds-section-wrapper {
  --mb: 200px;
  margin-bottom: calc(var(--mb) * -1);
  padding-bottom: var(--mb);
  position: relative;
  overflow: hidden;
  background: linear-gradient(180deg, var(--cl-white), var(--cl-turquoise));
}

.lds-section-wrapper .section-figures {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

.lds-section-wrapper .section-figures .fig {
      position: absolute;
      width: -moz-fit-content;
      width: fit-content;
      opacity: 0.4;
      left: 50% !important;
      top: 50% !important;
      transform: translate(-50%, -50%);
    }

.lds-section-wrapper .section-figures .fig img {
        height: auto;
        width: 100%;
      }

.lds-section-wrapper .section-figures .fig.lines {
        width: 157.15277777777777%;
        max-width: 2263px;
        top: 67.2% !important;
        left: 55% !important;
        transform: translate(-50%, -50%) !important;
      }

.lds-section-wrapper .section-figures .fig.cube {
        width: 71.875%;
        max-width: 1035px;
        opacity: 0.2;
        left: 59% !important;
        top: 50.6% !important;
      }

.lds-section-wrapper .section-figures .fig.cubes {
        width: 71.66666666666667%;
        min-width: 350px;
        max-width: 1032px;
        left: 0 !important;
        top: 0 !important;
        transform: translate(-24.5%, -14%) !important;
        opacity: 0.2;
      }

.lds-section {
  padding: clamp(60px, calc(60px + 60.00 * ((100vw - 768px) / 672)), 120px) 0 clamp(25px, calc(25px + 75.00 * ((100vw - 768px) / 672)), 100px);
}

.lds-section .title-animation {
    font-size: clamp(36px, calc(36px + 104.00 * ((100vw - 768px) / 672)), 140px);
    color: var(--cl-blue);
    width: 100%;
    margin-bottom: clamp(30px, calc(30px + 10.00 * ((100vw - 768px) / 672)), 40px);
    text-align: right;
  }

.lds-section .title-animation span:last-of-type {
        display: block;
        text-align: left;
      }

.lds-section .section-description {
    font-size: clamp(20px, calc(20px + 10.00 * ((100vw - 768px) / 672)), 30px);
    line-height: 1.5;
    max-width: 955px;
  }

.areas-section {
  padding: clamp(35px, calc(35px + 67.00 * ((100vw - 768px) / 672)), 102px) 0 clamp(40px, calc(40px + 77.00 * ((100vw - 768px) / 672)), 117px);
}

.areas-section .title-wr {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: clamp(40px, calc(40px + 12.00 * ((100vw - 768px) / 672)), 52px);
    flex-wrap: wrap;
    gap: 25px;
  }

.areas-section .title-wr .btn {
      margin-bottom: clamp(0px, calc(0px + 13.00 * ((100vw - 768px) / 672)), 13px);
    }

.areas-section .title-animation h2 {
      line-height: 0.98;
    }

.areas-section .title-animation span:nth-of-type(2) {
        margin-left: clamp(25px, calc(25px + 43.00 * ((100vw - 768px) / 672)), 68px);
      }

.areas-card {
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  width: clamp(500px, calc(500px + 130.00 * ((100vw - 768px) / 672)), 630px);
  max-width: 100%;
}

.areas-card-thumbnail-wr {
    flex-shrink: 0;
    width: 44.92%;
    position: relative;
    overflow: hidden;
  }

.areas-card-thumbnail-wr::before {
      content: '';
      display: block;
      padding-top: 125.08%;
    }

.areas-card .thumbnail-layer {
    overflow: hidden;
    border-radius: 20px 0 0 20px;
  }

@media (max-width: 550px) {

.areas-card .thumbnail-layer {
      border-radius: 20px 20px 0 0
  }
    }

.areas-card-thumbnail {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

.areas-card-thumbnail img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
      transition: 0.3s;
    }

.areas-card-thumbnail-bg-layer {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 2;
      mix-blend-mode: overlay;
      transform: translate(30%, 37%);
    }

.areas-card-text {
    flex: 1;
    background: var(--cl-white);
    padding: clamp(20px, calc(20px + 12.00 * ((100vw - 768px) / 672)), 32px);
    display: flex;
    flex-direction: column;
  }

.areas-card-title {
    font-size: clamp(22px, calc(22px + 8.00 * ((100vw - 768px) / 672)), 30px);
    font-weight: 800;
    line-height: 1.5;
    color: var(--cl-blue);
    margin-bottom: 16px;
  }

.areas-card-description {
    margin-bottom: 16px;
    width: 100%;
    font-size: 16px;
    padding: 0 !important;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

.areas-card .btn {
    margin-top: auto;
    font-size: 16px;
    font-weight: 500;
    border-color: var(--cl-navy);
    min-width: 155px;
  }

.areas-card .btn .btn__text {
      font-size: inherit;
    }

.areas-swiper-wr .swiper {
    overflow: visible;
  }

.areas-swiper-wr .swiper-slide {
      width: -moz-fit-content;
      width: fit-content;
      max-width: 100%;
      height: auto;
      display: flex;
    }

.areas-swiper-wr .slider-btn {
    margin-top: 50px;
  }

.solutions-section {
  padding-top: 1px;
  position: relative;
  margin-bottom: 17px;
}

.solutions-section-blue {
    margin-bottom: 0;
    padding-bottom: 0;
  }

.solutions-section-blue .features-wrapper {
      margin-bottom: 0;
      padding-bottom: 0;
      background: var(--cl-blue);
    }

.solutions-section-blue .features {
      padding-bottom: 80px;
    }

.solutions-section-blue .benefits-block .benefits-grid-item-icon {
        background: url('../../assets/images/ben-icon-border-blue.svg') center /
          contain no-repeat;
      }

.solutions-section-blue .benefits-block .benefits-thumbnail-img{
        width: clamp(150px, calc(150px + 319.00 * ((100vw - 768px) / 672)), 469px);
        opacity: 1;
      }

.features-wrapper {
  --mt: clamp(100px, calc(100px + 100.00 * ((100vw - 768px) / 672)), 200px);
  --mb: 250px;
  border-radius: 20px 20px 0 0;
  margin-top: calc(var(--mt) * -1);
  padding-top: var(--mt);
  margin-bottom: calc(var(--mb) * -1);
  padding-bottom: var(--mb);

  background: linear-gradient(103.02deg, #061344 -1.78%, #8900ea 119.69%);
}

.features-wrapper .cont {
    padding-top: 60px;
    padding-bottom: clamp(40px, calc(40px + 40.00 * ((100vw - 768px) / 672)), 80px);
  }

.features {
  display: flex;
  gap: 30px;
  justify-content: space-between;
}

.features-col {
    flex-shrink: 0;
    width: 522px;
    max-width: 42%;
  }

.features-col .btn {
      margin-top: 32px;
    }

.features .title-animation {
    mix-blend-mode: normal;
  }

.features .title-animation h2 {
      font-size: clamp(32px, calc(32px + 48.00 * ((100vw - 768px) / 672)), 80px);
      line-height: 1.05;
      margin-bottom: 32px;
      color: var(--cl-white);
      font-weight: 700;
    }

.features-grid {
    flex: 1;
    max-width: 678px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 24px;
    align-self: flex-start;
  }

.features .section-description {
    color: var(--cl-white);
  }

.features-card {
    color: var(--cl-white);
  }

.features-card-title {
      line-height: 1.5;
      font-size: clamp(20px, calc(20px + 10.00 * ((100vw - 768px) / 672)), 30px);
      font-weight: 800;
      padding-bottom: 8px;
      position: relative;
      margin-bottom: 9px;
    }

.features-card-title::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 48px;
        height: 1px;
        background: var(--cl-white);
        mix-blend-mode: overlay;
      }

.features-card-description {
      font-size: 16px;

      --line-count: 10;
      --fz: 16px;
      --line-height: 1.5;
      line-height: var(--line-height);
      font-size: var(--fz);
      max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
    }

.features-card-description.simplebar-scrollable-y {
        /* padding-right: 15px;*/
      }

.features-card-description .simplebar-scrollbar::before {
      }

.features-card-description .simplebar-track.simplebar-vertical {
      }

.benefits-block {
  border-radius: 12px;
  position: relative;
  transform: translate(0);
  display: flex;
  gap: 40px;
  justify-content: space-between;
  overflow: hidden;
  padding: clamp(30px, calc(30px + 30.00 * ((100vw - 768px) / 672)), 60px) clamp(20px, calc(20px + 40.00 * ((100vw - 768px) / 672)), 60px);
  border: 1px solid var(--cl-purple);

  -webkit-clip-path: inset(0 0 0 0 round 12px);

          clip-path: inset(0 0 0 0 round 12px);
}

.benefits-block-bg {
    position: absolute;
    inset: 0;
    z-index: -1;
  }

/*&::before {
              content: '';
              display: block;
              position: absolute;
              inset: 0;
              z-index: 1;
              background: var(--cl-white);
              opacity: 0.7;
            }*/

.benefits-block-bg picture img {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
      }

.benefits-block .benefits-thumbnail-img {
    position: absolute;
    top: auto;
    left: -21px;
    bottom: 0;
    width: clamp(150px, calc(150px + 297.00 * ((100vw - 768px) / 672)), 447px);
    height: auto;
    mix-blend-mode: overlay;
    opacity: 0.6;
    -o-object-fit: contain;
       object-fit: contain;
  }

.benefits-block .benefits-thumbnail-img.top {
      z-index: 2;
    }

.benefits-block-col {
    flex-shrink: 0;
    max-width: 484px;
  }

.benefits-block .benefits-title {
    font-size: clamp(24px, calc(24px + 20.00 * ((100vw - 768px) / 672)), 44px);
    font-weight: 700;
    line-height: 1.2727;
    padding-bottom: 275px;
    color: var(--cl-blue);
  }

.benefits-block .benefits-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: clamp(25px, calc(25px + 15.00 * ((100vw - 768px) / 672)), 40px) clamp(25px, calc(25px + 33.00 * ((100vw - 768px) / 672)), 58px);
    flex: 1;
    max-width: clamp(630px, calc(630px + 125.00 * ((100vw - 1024px) / 416)), 755px);
    align-self: flex-start;
  }

@media (min-width: 1440px) {

.benefits-block .benefits-grid {
      max-width: clamp(755px, calc(755px + 455.00 * ((100vw - 1440px) / 480)), 1210px)
  }
    }

.benefits-block .benefits-grid-item {
      display: flex;
      align-items: center;
      gap: clamp(10px, calc(10px + 10.00 * ((100vw - 768px) / 672)), 20px);
    }

.benefits-block .benefits-grid-item-icon {
        flex-shrink: 0;
        width: clamp(64px, calc(64px + 20.00 * ((100vw - 768px) / 672)), 84px);
        height: clamp(64px, calc(64px + 20.00 * ((100vw - 768px) / 672)), 84px);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 7px 7px 0;
        background: url('../../assets/images/ben-icon-border.svg') no-repeat
          center / contain;
      }

.benefits-block .benefits-grid-item-icon img {
          max-width: 75%;
          max-height: 75%;
        }

.benefits-block .benefits-grid-item-text {
        flex: 1;
        font-size: clamp(18px, calc(18px + 6.00 * ((100vw - 768px) / 672)), 24px);
        font-weight: 700;
        line-height: 1.3333;
        font-family: var(--font-second);
      }

.benefits-block-reverse {
    flex-direction: row-reverse;
  }

.benefits-block-reverse .benefits-title {
      text-align: right;
    }

.benefits-block-reverse .benefits-thumbnail-img {
      left: auto;
      right: clamp(20px, calc(20px + 39.00 * ((100vw - 768px) / 672)), 59px);
    }

.counts-section {
  padding: clamp(50px, calc(50px + 68.00 * ((100vw - 768px) / 672)), 118px) 0 clamp(40px, calc(40px + 80.00 * ((100vw - 768px) / 672)), 120px);
  position: relative;
  overflow: hidden;
  background: linear-gradient(103.02deg, #061344 -1.78%, #8900ea 119.69%);
}

.counts-section .bg-layer {
    position: absolute;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 105%;
    height: 105%;
    mix-blend-mode: luminosity;
    opacity: 0.3;
    z-index: 0;
  }

.counts-section .bg-layer img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
    }

.counts-section .cont-second {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    position: relative;
    z-index: 2;
  }

.counts-section-text {
    flex: 1;
    max-width: 620px;
    color: var(--cl-white);
  }

.counts-section-text .btn {
      margin-top: 40px;
    }

.counts-section .title-animation {
    margin-bottom: 8px;
  }

.counts-section .title-animation h2 {
      color: var(--cl-white);
    }

.counts-section .section-description {
    font-size: clamp(20px, calc(20px + 10.00 * ((100vw - 768px) / 672)), 30px);
  }

.safari-support .counts-section .bg-layer {
      mix-blend-mode: plus-lighter;
    }

.counts-grid {
  width: 580px;
  flex-shrink: 0;
  max-width: 50%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 20px;
}

.counts-box {
  position: relative;
}

.counts-box::before {
    content: '';
    display: block;
    padding-top: 100%;
  }

.counts-box-border {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

.counts-box-inner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    color: var(--cl-white);
    gap: 16px;
    font-family: var(--font-second);
    padding: 0 22px 22px 0;
  }

.counts-box .count-up {
    font-size: clamp(35px, calc(35px + 25.00 * ((100vw - 768px) / 672)), 60px);
    font-weight: 800;
    line-height: 1.035;
    mix-blend-mode: overlay;
  }

.counts-box-text {
    width: 100%;
    --line-count: 2;
    --fz: clamp(18px, calc(18px + 6.00 * ((100vw - 768px) / 672)), 24px);
    --line-height: 1.3333;
    font-weight: 700;
    line-height: var(--line-height);
    font-size: var(--fz);
    max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
    padding: 0 10px;
  }

.counts-box-text.simplebar-scrollable-y {
      /* padding-right: 15px;*/
    }

.counts-box-text .simplebar-scrollbar::before {
      /*background-color: var(--orange);
                              color: var(--orange);
                              opacity: 1!important;
                              left: 1px;
                              right: 1px;*/
    }

.counts-box-text .simplebar-track.simplebar-vertical {
      /*  background: rgba(255,255,255, 0.2)!important;
                                opacity: 1!important;
                                width: 5px;
                                border-radius: 5px;
                                right: -8px;*/
    }

.ts-section.ts-section-hiring .section-bg img {
    -o-object-position: center bottom;
       object-position: center bottom;
  }

.ts-section.ts-section-hiring .cont {
    background: none;
  }

@media (max-width: 992px) {
  .features {
    flex-direction: column;
    gap: 45px;
  }

    .features-col, .features-grid {
      max-width: 100%;
      width: 100%;
    }

  .benefits-block {
    flex-direction: column;
    gap: 35px;
  }

    .benefits-block-col {
      max-width: 100%;
      width: 100%;
    }

    .benefits-block .benefits-title {
      padding-bottom: 0;
      text-align: center;
    }

    .benefits-block .benefits-grid {
      max-width: 100%;
      width: 100%;
      padding-bottom: 90px;
    }

  .benefits-block .benefits-thumbnail {
    width: 150px;
    bottom: -24px;
  }
    .counts-section .cont-second {
      flex-direction: column-reverse;
      gap: 40px;
    }

    .counts-section-text {
      max-width: 100%;
    }

  .counts-grid {
    max-width: 100%;
  }
}

@media (max-width: 700px) {
  .benefits-block .benefits-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 650px) {
    .hiring-solutions-page .first-section__wrap {
      flex-direction: column-reverse;
      justify-content: center;
    }

    .hiring-solutions-page .hero-thumbnail-wrapper {
      max-width: 90%;
      width: 280px;
    }

  .features-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 550px) {
  .areas-card {
    width: 375px;
    flex-direction: column;
  }

    .areas-card-thumbnail-wr {
      max-width: 100%;
      width: 100%;
    }

      .areas-card-thumbnail-wr::before {
        padding-top: 62%;
      }
      .areas-card-thumbnail img {
        -o-object-position: center 19%;
           object-position: center 19%;
      }

      .areas-card-thumbnail-bg-layer {
        transform: translate(30%, 58%);
      }

  .counts-grid {
    grid-template-columns: minmax(0, 250px);
    justify-content: center;
  }
}

.job-details-page {
  overflow: visible !important;
}

.job-details-page .first-section__bg img {
        -o-object-position: center bottom;
           object-position: center bottom;
      }

.job-details-page .first-section__wrap {
      height: auto;
      min-height: clamp(470px, calc(470px + 130.00 * ((100vw - 768px) / 672)), 600px);
      padding-bottom: clamp(50px, calc(50px + 78.00 * ((100vw - 1100px) / 340)), 128px);
      justify-content: flex-end;
      flex-direction: column !important;
    }

@media (max-width: 650px) {

.job-details-page .first-section__wrap {
        min-height: calc(100 * var(--vh, 1vh))
    }
      }

.job-details-page .first-section__title {
      mix-blend-mode: normal;
    }

.job-details-page .first-section__title-container {
        order: 0;
        margin-bottom: clamp(-22px, calc(0px + -22.00 * ((100vw - 1200px) / 240)), 0px) !important;
      }

.job-details-page .first-section__title h1 {
        font-size: clamp(30px, calc(30px + 50.00 * ((100vw - 768px) / 672)), 80px);
        line-height: 1.05;
        font-weight: 700;
      }

.job-details-page .first-section__title span:nth-of-type(2) {
          margin-left: clamp(25px, calc(25px + 29.00 * ((100vw - 768px) / 672)), 54px);
        }

.job-details-page .first-section .fig-wrapper {
      position: absolute;
      pointer-events: none;
    }

.job-details-page .first-section .fig-wrapper.fig-1 {
        left: 0 !important;
        top: 0 !important;
        transform: translate(2.7%, 31.5%) !important;
        width: 50.833333333333336%;
        max-width: 732px;
      }

.job-details-page .first-section .fig-wrapper.fig-2 {
        right: 0;
        top: 50% !important;
        transform: translate(34%, -52%) !important;
        width: 64.93055555555556%;
        max-width: 935px;
      }

.job-details-page .first-section .btn:not(.btn-transparent) {
      min-width: 145px;
    }

.job-details-page .first-section .btn:not(.btn-transparent) .btn__text {
        font-size: 16px;
      }

.job-details-page .roles-section {
    overflow: visible;
    padding-bottom: 1px;
    padding-top: 0;
  }

.job-details-page .roles-section-cont-wr {
      padding-top: clamp(80px, calc(80px + 40.00 * ((100vw - 768px) / 672)), 120px);
      overflow: hidden;
    }

.job-details-page .roles-section__bg-decor {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

.job-details-page .roles-section__bg-decor::before {
        display: none;
      }

.job-details-page .roles-section__bg-decor img {
        width: 1411px;
        max-width: 117%;
        top: 50%;
        left: 50%;
        transform: translate(-34.9%, -31.7%);
        height: auto;
        -o-object-fit: contain;
           object-fit: contain;
      }

.job-details-page .roles-section__bg-decor-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        overflow: hidden;
      }

.job-details-page .roles-section .upload-cv-section {
      padding-top: clamp(50px, calc(50px + 70.00 * ((100vw - 768px) / 672)), 120px);
    }

.job-details-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 35px;
  width: 100%;
  flex-wrap: wrap;
}

.job-details-info .buttons {
    margin: 0 !important;
  }

.job-details-info ul {
    flex: 1;
    max-width: 593px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: clamp(15px, calc(15px + 5.00 * ((100vw - 768px) / 672)), 20px);
  }

.job-details-info ul li {
      font-size: clamp(18px, calc(18px + 6.00 * ((100vw - 768px) / 672)), 24px);
      line-height: 1.333;
      padding: 8px;
      position: relative;
      font-family: var(--font-second);
      font-weight: 700;
    }

.job-details-info ul li::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: var(--cl-lilac);
        mix-blend-mode: overlay;
      }

.job-details-section {
  position: relative;
  transform: translate(0);
  padding: 80px 0 clamp(80px, calc(80px + 40.00 * ((100vw - 768px) / 672)), 120px);
}

.job-details-section .section-bg {
    z-index: -1;
  }

.article {
  display: flex;
  justify-content: space-between;
  gap: clamp(35px, calc(35px + 57.00 * ((100vw - 992px) / 448)), 92px);
}

.article-main {
    flex: 1;
  }

.article-main .buttons {
      margin: 48px 0;
    }

.article-content {
    font-size: clamp(16px, calc(16px + 4.00 * ((100vw - 768px) / 672)), 20px);
  }

.article-content h1, .article-content h2, .article-content h3, .article-content h4, .article-content h5 {
      line-height: 1.5;
      font-weight: 800;
      font-family: var(--font-second);
      margin-bottom: 24px;
      color: var(--cl-blue);
    }

.article-content h1 {
      font-size: clamp(35px, calc(35px + 35.00 * ((100vw - 768px) / 672)), 70px);
      line-height: 1.0333;
    }

.article-content h2 {
      font-size: clamp(32px, calc(32px + 28.00 * ((100vw - 768px) / 672)), 60px);
      line-height: 1.0333;
    }

.article-content h3 {
      font-size: clamp(28px, calc(28px + 6.00 * ((100vw - 768px) / 672)), 34px);
      margin-top: clamp(35px, calc(35px + 13.00 * ((100vw - 768px) / 672)), 48px);
      margin-bottom: 16px;
      font-family: var(--font-main);
    }

.article-content h4 {
      font-size: clamp(26px, calc(26px + 6.00 * ((100vw - 768px) / 672)), 32px);
    }

.article-content h5 {
      font-size: clamp(24px, calc(24px + 6.00 * ((100vw - 768px) / 672)), 30px);
    }

.article-content h6 {
      font-size: clamp(22px, calc(22px + 6.00 * ((100vw - 768px) / 672)), 28px);
    }

.article-content p {
      font-weight: 300;
      line-height: 1.5;
      margin-bottom: clamp(25px, calc(25px + 12.00 * ((100vw - 768px) / 672)), 37px);
    }

.article-content p:first-of-type {
        font-weight: 400;
        font-size: clamp(20px, calc(20px + 10.00 * ((100vw - 768px) / 672)), 30px);
        margin-bottom: clamp(35px, calc(35px + 13.00 * ((100vw - 768px) / 672)), 48px);
      }

.article-content ul, .article-content ol {
      margin-top: 27px;
      margin-bottom: 51px;
      display: flex;
      flex-direction: column;
      gap: clamp(15px, calc(15px + 8.00 * ((100vw - 768px) / 672)), 23px);
    }

.article-content ul {
      padding-left: 10px;
    }

.article-content ul li {
        position: relative;
        padding-left: clamp(20px, calc(20px + 7.00 * ((100vw - 768px) / 672)), 27px);
      }

.article-content ul li::before {
          content: '';
          display: block;
          width: 14px;
          height: 14px;
          background-image: url("data:image/svg+xml,%3Csvg width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.67797 8.32394C5.35344 8.95775 6.11334 9.29578 6.95768 9.29578C7.88644 9.29578 8.64635 8.95775 9.32182 8.32394C9.95507 7.69014 10.2928 6.88732 10.2928 6C10.2928 5.11268 9.95507 4.35211 9.32182 3.67606C8.68857 3.04225 7.88644 2.70423 6.95768 2.70423C6.11334 2.70423 5.35344 3.04225 4.67797 3.67606C4.0025 4.30986 3.70698 5.11268 3.70698 6C3.70698 6.88732 4.04472 7.64789 4.67797 8.32394ZM6.95768 12C5.39565 12 4.0025 11.4085 2.77822 10.2254C1.55393 9.04225 0.962891 7.64789 0.962891 6C0.962891 4.35211 1.55393 2.95775 2.77822 1.77465C4.0025 0.591549 5.39565 0 6.95768 0C8.64635 0 10.0817 0.591549 11.2638 1.77465C12.4459 2.95775 13.0369 4.35211 13.0369 6C13.0369 7.64789 12.4459 9.04225 11.2638 10.2254C10.0817 11.4085 8.64635 12 6.95768 12Z' fill='%23061344'/%3E%3C/svg%3E%0A");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          left: 0;
          top: clamp(3px, calc(3px + 2.00 * ((100vw - 768px) / 672)), 5px);
        }

.article-content ol {
      padding-left: 23px;
      list-style: decimal;
    }

.article-content a:not([class]) {
      display: inline;
      color: var(--cl-blue);
      text-decoration: underline;
      transition: 0.3s ease;
      font-size: inherit;
    }

.article-content a:not([class]):hover {
        color: var(--cl-turquoise);
      }

.article-content b, .article-content strong {
      font-weight: 700;
    }

.article-aside {
    width: clamp(280px, calc(280px + 61.00 * ((100vw - 768px) / 672)), 341px);
    max-width: 100%;
  }

.article-aside .teammate-card {
      position: sticky;
      top: 110px;
    }

.article-aside .teammate-card-logo {
        max-width: 87px;
        margin-bottom: 16px;
      }

.article-aside .teammate-card-thumbnail-wrapper {
          margin-bottom: 32px;
        }

.article-aside .teammate-card-name {
        font-size: clamp(24px, calc(24px + 6.00 * ((100vw - 768px) / 672)), 30px);
        margin-bottom: 10px;
      }

.article-aside .teammate-card-position {
        font-size: clamp(20px, calc(20px + 2.00 * ((100vw - 768px) / 672)), 22px);
      }

.article-socials {
    display: flex;
    align-items: center;

    flex-wrap: wrap;
    gap: 24px;
  }

.article-socials span {
      font-size: clamp(16px, calc(16px + 2.00 * ((100vw - 768px) / 672)), 18px);
      font-weight: 500;
      line-height: 1.44;
    }

.article-socials a {
      color: var(--cl-blue);
    }

.article-socials a:hover {
        color: var(--cl-turquoise);
      }

@media (max-width: 1100px) {
  .job-details-info {
    flex-direction: column-reverse;
    align-items: flex-start;
    width: 100%;
    padding-top: 40px;
  }

    .job-details-info ul {
      width: 100%;
    }
}

@media (max-width: 992px) {
  .article {
    flex-direction: column;
  }

    .article-aside {
      width: 100%;
    }
}

@media (max-width: 650px) {
    .job-details-info ul {
      grid-template-columns: 1fr;
    }

    .job-details-info .btn {
      width: 100%;
    }
}

@media (max-width: 420px) {
  .article-main .buttons {
    grid-gap: 20px;
  }
    .article-aside .teammate-card {
      width: 100%;
      max-width: 100%;
    }
}

.jobs-page .first-section__wrap {
      height: auto;
      min-height: clamp(400px, calc(400px + 300.00 * ((100vw - 768px) / 672)), 700px);
      padding-bottom: clamp(80px, calc(80px + 50.00 * ((100vw - 768px) / 672)), 130px);
    }

.jobs-page .first-section__title span:nth-of-type(2) {
          margin-left: clamp(0px, calc(0px + 54.00 * ((100vw - 768px) / 672)), 54px);
        }

.jobs-page .first-section .fig-wrapper {
      position: absolute;
    }

.jobs-page .first-section .fig-wrapper.fig-1 {
        left: 0 !important;
        top: 0 !important;
        transform: translate(-27.3%, -23.5%) !important;
        width: 78.05555555555556%;
        max-width: 1124px;
      }

.jobs-page .first-section .fig-wrapper.fig-2 {
        right: 0;
        top: 0 !important;
        transform: translate(11.4%, -17.7%) !important;
        width: 62.84722222222222%;
        max-width: 905px;
      }

.job-results-wrapper {
  background: var(--cl-lilac);
  position: relative;
}

.job-results-wrapper .section-bg {
    z-index: 0;
  }

.job-result-section {
  padding:clamp(45px, calc(45px + 35.00 * ((100vw - 768px) / 672)), 80px) 0;
  position: relative;
  z-index: 2;
}

.job-result-section .title {
    font-size: clamp(30px, calc(30px + 30.00 * ((100vw - 768px) / 672)), 60px);
    font-weight: 800;
    line-height: 1.0333;
    font-family: var(--font-second);
    margin-bottom: 24px;
    color: var(--cl-blue);
  }

.job-result-section .meta-select {
    margin-bottom: clamp(35px, calc(35px + 29.00 * ((100vw - 768px) / 672)), 64px);
  }

@media (max-width: 1024px) {
    .job-result-section .cont-second {
      width: 100%;
    }
  }

.job-results {
  display: flex;
  justify-content: space-between;
  gap: clamp(25px, calc(25px + 36.00 * ((100vw - 992px) / 448)), 61px);
}

.job-results-main {
    flex: 1;
    max-width: 848px;
  }

.job-results-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: clamp(10px, calc(10px + 7.80 * ((100vw - 768px) / 672)), 17.8px) clamp(10px, calc(10px + 10.00 * ((100vw - 768px) / 672)), 20px);
  }

.job-results-aside {
    width: clamp(270px, calc(270px + 83.00 * ((100vw - 992px) / 448)), 353px);
    max-width: 100%;
  }

.job-results-aside h3 {
      font-size: clamp(35px, calc(35px + 9.00 * ((100vw - 768px) / 672)), 44px);
      font-weight: 700;
      margin-bottom: clamp(22px, calc(22px + 10.00 * ((100vw - 768px) / 672)), 32px);
    }

.job-results-aside h4 {
      font-weight: 700;
      font-size: clamp(22px, calc(22px + 2.00 * ((100vw - 768px) / 672)), 24px);
      margin-bottom: 13px;
      line-height: 1.33;
      font-family: var(--font-second);
    }

.job-results-filters ul {
      display: flex;
      flex-direction: column;
      gap: clamp(10px, calc(10px + 5.00 * ((100vw - 768px) / 672)), 15px);
      padding-left: 4px;
    }

.filters-group {
  padding-bottom: 43px;
  display: flex;
  flex-direction: column;
  gap: clamp(10px, calc(10px + 5.00 * ((100vw - 768px) / 672)), 15px);
}

.filters-group-toggle {
    margin-top: 7px;
  }

.filters-group-type h4 {
      margin-bottom: 4px;
    }

.filters-group-type ul {
      gap: clamp(15px, calc(15px + 8.00 * ((100vw - 768px) / 672)), 23px);
    }

.filters-group-range {
    gap: 4px;
  }

.filters-group-location {
    gap: 4px;
  }

.filters-group-location .filters-group-toggle.active {
      margin-top: 27px;
    }

.filters-group .checkbox-wr i{
      font-style: normal;
      font-size: 18px;
    }

.filters-list-hidden:not(.active) {
    display: none;
  }

.upload-cv {
  transform: translate(0);
  display: flex;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: -184px;
  z-index: 3;
}

.upload-cv-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
  }

.upload-cv-bg img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
      transition: 0.3s;
    }

.upload-cv .title-animation {
    mix-blend-mode: overlay;
    color: var(--cl-white);
    margin-bottom: 24px;
  }

.upload-cv-thumbnail {
    flex-shrink: 0;
    width: 594px;
    max-width: 50%;
    position: relative;
    overflow: hidden;
  }

.upload-cv-thumbnail::before {
      content: '';
      display: block;
      padding-top: 93.94%;
    }

.upload-cv-thumbnail img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
      transition: 0.3s;
    }

.upload-cv-text {
    flex: 1;
    padding: clamp(50px, calc(50px + 70.00 * ((100vw - 768px) / 672)), 120px) clamp(20px, calc(20px + 36.00 * ((100vw - 768px) / 672)), 56px) clamp(35px, calc(35px + 25.00 * ((100vw - 768px) / 672)), 60px) clamp(20px, calc(20px + 40.00 * ((100vw - 768px) / 672)), 60px);
    color: var(--cl-white);
  }

.upload-cv-text .btn {
      margin-top: 40px;
    }

.upload-cv-text .btn .btn__icon{
        font-size: 20px;
      }

.upload-cv .section-description {
    font-size: clamp(16px, calc(16px + 4.00 * ((100vw - 768px) / 672)), 20px);
  }

.upload-cv .section-description p:first-of-type {
        font-size: clamp(24px, calc(24px + 10.00 * ((100vw - 768px) / 672)), 34px);
        font-weight: 600;
        line-height: 1.29;
        margin-bottom: 8px;
        font-family: var(--font-second);
      }

@media (max-width: 992px) {
  .job-results{
    flex-direction: column-reverse;
  }
    .job-results-aside{
      max-width: 100%;
      width: 100%;

    }
    .job-results-main{
      max-width: 100%;
    }
    .job-results-filters{
      display: flex;
      flex-wrap: wrap;
      gap: 25px 50px;
    }
      .job-results-filters h3{
        width: 100%;
        margin-bottom: 0;
      }
  .filters-group{
    gap: 8px!important;
    padding-bottom: 0;
  }
    .filters-group h4{
      margin-bottom: 10px!important;
    }
    .filters-group ul{
      gap: 8px!important;
    }
    .filters-group-range{
      order: 2;
      width: 100%;
      max-width: 100%;
    }
      .filters-group-range .double-range-container{
        max-width: 100%;
      }
    .filters-group-toggle{
      margin-top: 10px!important;
    }

}

@media (max-width: 768px) {
  .upload-cv{
    flex-direction: column-reverse;
  }
    .upload-cv-thumbnail{
      width: 100%;
      max-width: 100%;
    }
      .upload-cv-thumbnail::before{
        padding-top: 63%;
      }
      .upload-cv-thumbnail img{
        -o-object-position: center 20%;
           object-position: center 20%;
      }
}

@media (max-width: 650px) {
  .job-results-grid{
    grid-template-columns: minmax(0, 420px);
    justify-content: center;
  }
  .filters-group{
    max-width: 100%;
    width: 100%;
  }
}

@media (max-width: 450px) {
  .upload-cv-text .btn {
    width: 100%;
    justify-content: center;
  }
}

@media (max-width: 370px) {
    .jobs-page .first-section__title h1{
      font-size: 34px;
    }
}

.join-team-page .first-section__bg {
    background: linear-gradient(107.53deg, #061344 0%, #8900ea 99.67%);
  }

.join-team-page .first-section__wrap {
      padding-bottom: clamp(130px, calc(130px + 113.00 * ((100vw - 768px) / 672)), 243px);
      height: auto;
      min-height: calc(var(--vh, 1vh) * 100 + clamp(0px, calc(0px + 51.00 * ((100vw - 768px) / 672)), 51px));
    }

.join-team-page .first-section .fig-wrapper {
      width: 110.34722222222223%;
      max-width: 1589px;
      position: absolute;
      left: 50%;
      top: 51px;
      z-index: 0;
      transform: translateX(-10.5%);
      min-width: 840px;
    }

.join-team-page .first-section .fig-wrapper img {
        width: 100%;
        height: auto;
      }

.join-team-page .first-section .cont {
      position: relative;
    }

.join-team-page .first-section__subtitle {
      text-align: left;
    }

.join-team-page .first-section .first-section__title {
      mix-blend-mode: normal;
    }

.join-team-page .first-section .first-section__title h1 {
        /*color: #990fff;*/
        color: #AA05FF;
      }

.join-team-page .first-section .first-section__title h1 span:nth-of-type(2) {
            margin-left: clamp(20px, calc(20px + 49.00 * ((100vw - 768px) / 672)), 69px);
          }

.join-team-page .first-section + .journey-section-wrapper .journey-section .cont {
          margin-top: clamp(-212px, calc(-100px + -112.00 * ((100vw - 768px) / 672)), -100px);
          position: relative;
          z-index: 11;
        }

.join-team-page .footer__wrap{
        padding-top: clamp(60px, calc(60px + 58.00 * ((100vw - 768px) / 672)), 118px) !important;
    }

.thumbnail-layer {
  position: absolute;
  inset: 0;
  z-index: 2;
}

.thumbnail-layer img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }

.journey-section {
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  background: var(--cl-lilac);
}

.journey-section .cont {
    display: flex;
    justify-content: space-between;
    border-radius: 12px 0 0 12px;
    background: var(--cl-white);
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.04);
    left: 20px;
  }

.journey-section-text {
    flex: 1;
    padding: clamp(35px, calc(35px + 67.00 * ((100vw - 768px) / 672)), 102px) clamp(20px, calc(20px + 40.00 * ((100vw - 768px) / 672)), 60px);
  }

.journey-section-text p {
      margin-bottom: 16px;
    }

.journey-section-text p:last-of-type {
        margin-bottom: 0;
      }

.journey-section-thumbnail {
    flex-shrink: 0;
    width: 648px;
    max-width: 48%;
    position: relative;
    overflow: hidden;
    transform: translateX(0);
  }

.journey-section-thumbnail::before {
      content: '';
      display: block;
      padding-top: 100%;
    }

.journey-section-thumbnail img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
      transform: none;
    }

.journey-section .sub-title {
    font-size: clamp(25px, calc(25px + 19.00 * ((100vw - 768px) / 672)), 44px);
    font-weight: 700;
    line-height: 1.2727;
    margin-bottom: clamp(14px, calc(14px + 10.00 * ((100vw - 768px) / 672)), 24px);
  }

.journey-section .title-animation {
    font-size: clamp(35px, calc(35px + 45.00 * ((100vw - 768px) / 672)), 80px);
    color: var(--cl-blue);
    font-weight: 700;
    line-height: 1.05;
    margin-bottom: 24px;
  }

.journey-section .section-description {
    font-weight: 300;
  }

.benefits-section {
  --mb: 270px;
  padding-bottom: var(--mb);
  margin-bottom: calc(var(--mb) * -1);

  background: var(--cl-lilac);
}

.benefits-section .title-animation {
    margin-bottom: clamp(40px, calc(40px + 16.00 * ((100vw - 768px) / 672)), 56px);
  }

.benefits-section .cont {
    position: relative;
    z-index: 1;
    padding: clamp(50px, calc(50px + 50.00 * ((100vw - 768px) / 672)), 100px) 0 clamp(50px, calc(50px + 30.00 * ((100vw - 768px) / 672)), 80px);
  }

.benefits-section .section-bg img {
      -o-object-position: center top;
         object-position: center top;
    }

.benefits-section .title-animation {
    color: var(--cl-blue);
    text-align: center;
  }

.benefit-card {
  position: relative;
  padding: clamp(35px, calc(35px + 13.00 * ((100vw - 768px) / 672)), 48px) clamp(15px, calc(15px + 19.00 * ((100vw - 768px) / 672)), 34px) clamp(35px, calc(35px + 21.00 * ((100vw - 768px) / 672)), 56px);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.benefit-card-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
  }

.benefit-card-bg img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
    }

.benefit-card-icon {
    padding: 0 9px 9px 0;
    width: 112px;
    height: 112px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('../../assets/images/ben-border.svg') no-repeat center /
      contain;
    margin-bottom: 24px;
  }

.benefit-card-icon img {
      max-width: 90%;
      max-height: 70%;
    }

.benefit-card-title {
    width: 100%;
    text-align: center;
    font-size: clamp(24px, calc(24px + 10.00 * ((100vw - 768px) / 672)), 34px);
    font-weight: 800;
    line-height: 1.5;
    margin-bottom: 12px;
  }

.benefit-card-descroption {
    width: 100%;
    text-align: center;
    font-weight: 300;
    --line-count:5;
    --fz: clamp(16px, calc(16px + 4.00 * ((100vw - 768px) / 672)), 20px);
    --line-height: 1.5;
    line-height: var(--line-height);
    font-size: var(--fz);
    max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
  }

.benefit-card-descroption p{
      line-height: inherit;
    }

.benefit-card-descroption.simplebar-scrollable-y{

    }

.benefit-card-descroption .simplebar-scrollbar::before {

    }

.benefit-card-descroption .simplebar-track.simplebar-vertical{

    }

.benefits-swiper-wr .swiper {
    overflow: visible;
    width: calc(100% - clamp(0px, calc(0px + 40.00 * ((100vw - 768px) / 672)), 40px));
  }

@media (max-width: 1023px){
      .benefits-swiper-wr .swiper-slide{
        width: 320px;
        max-width: 100%;
      }
    }

.benefits-swiper-wr .slider-buttons {
    margin: 0 auto;
    width: -moz-fit-content;
    width: fit-content;
  }

.benefits-swiper-wr .slider-btn {
    margin-top: 50px;
  }

.ts-section .cont {
    padding: clamp(70px, calc(70px + 74.00 * ((100vw - 768px) / 672)), 144px) 0 clamp(40px, calc(40px + 81.00 * ((100vw - 768px) / 672)), 121px);
    position: relative;
    border-radius: 12px;
    background: linear-gradient(103.02deg, #061344 -1.78%, #8900ea 119.69%);
  }

.ts-section-header {
    display: flex;
    justify-content: space-between;
    gap: 35px;
    align-items: flex-end;
    padding-bottom: clamp(45px, calc(45px + 43.00 * ((100vw - 768px) / 672)), 88px);
  }

.ts-section .title-wr {
    position: relative;
    width: clamp(377px, calc(377px + 548.00 * ((100vw - 768px) / 672)), 925px);
  }

.ts-section .title-animation {
    color: var(--cl-white);
    padding-right: 100px;
    position: relative;
    z-index: 1;
  }

.ts-section .ts-section-title-duplicate {
    z-index: 0;
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    font-size: clamp(53px, calc(53px + 71.00 * ((100vw - 768px) / 672)), 124px);
    font-family: var(--font-second);
    font-weight: 800;
    line-height: 1.035;
    color: var(--cl-white);
    mix-blend-mode: overlay;
    opacity: 0.3;
    padding-left: clamp(10px, calc(10px + 25.00 * ((100vw - 768px) / 672)), 35px);
  }

.ts-section .ts-section-title-duplicate span:nth-of-type(1) {
        margin-left: clamp(25px, calc(25px + 60.00 * ((100vw - 768px) / 672)), 85px);
      }

.ts-section .slider-buttons {
    margin-bottom: clamp(-26px, calc(0px + -26.00 * ((100vw - 768px) / 672)), 0px);
  }

.ts-section .bg-layer {
    width: 340px;
    max-width: 25%;
    min-width: 120px;
    position: absolute;
    z-index: 0;
    right: clamp(0px, calc(0px + 150.00 * ((100vw - 768px) / 672)), 150px);
    bottom: clamp(50px, calc(50px + 133.00 * ((100vw - 768px) / 672)), 183px);
  }

.ts-section .bg-layer img {
      width: 100%;
      height: auto;
    }

.ts-swiper-wr .swiper {
    margin: 0;
  }

.ts-swiper-outer {
  width: clamp(408px, calc(408px + 330.00 * ((100vw - 768px) / 672)), 738px);
  max-width: 100%;
}

.ts-swiper-header {
  position: relative;
  display: flex;
  gap: 28px;
  flex-shrink: 0;
  align-items: center;
  margin-bottom: 3px;
}

.ts-swiper-header img {
    width: clamp(30px, calc(30px + 34.00 * ((100vw - 768px) / 672)), 64px);
    height: auto;
    z-index: 1;
  }

.ts-swiper-header img:nth-of-type(2) {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
      transform: translate(10%, 10%);
      mix-blend-mode: overlay;
    }

.ts-swiper-header::after {
    content: '';
    display: block;
    flex: 1;
    height: 1px;
    mix-blend-mode: overlay;
    background: var(--cl-lilac);
  }

.ts-card {
  padding-top: clamp(20px, calc(20px + 8.00 * ((100vw - 768px) / 672)), 28px);
}

.ts-card-description {

    color: var(--cl-white);
    margin-bottom: clamp(35px, calc(35px + 21.00 * ((100vw - 768px) / 672)), 56px);

    --line-count:7;
    --fz: clamp(18px, calc(18px + 12.00 * ((100vw - 768px) / 672)), 30px);
    --line-height: 1.5;
    line-height: var(--line-height);
    font-size: var(--fz);
    max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
  }

.ts-card-description.simplebar-scrollable-y{
        padding-right: 10px;
    }

.ts-card-description .simplebar-track.simplebar-vertical{
        transform: none;
    }

.ts-card-author {
    display: flex;
    gap: clamp(14px, calc(14px + 10.00 * ((100vw - 768px) / 672)), 24px);
    color: var(--cl-white);
    align-items: center;
  }

.ts-card-author-thumbnail {
      flex-shrink: 0;
      width: clamp(80px, calc(80px + 16.00 * ((100vw - 768px) / 672)), 96px);
      position: relative;
      overflow: hidden;
      border-radius: 50%;
    }

.ts-card-author-thumbnail::before {
        content: '';
        display: block;
        padding-top: 100%;
      }

.ts-card-author-thumbnail img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
           object-fit: cover;
        transition: 0.3s;
      }

.ts-card-author-name {
      font-family: var(--font-second);
      font-size: clamp(18px, calc(18px + 16.00 * ((100vw - 768px) / 672)), 34px);
      font-weight: 600;
      line-height: 1.29;
      margin-bottom: clamp(4px, calc(4px + 4.00 * ((100vw - 768px) / 672)), 8px);
    }

.ts-card-author-position {
      font-weight: 700;
      font-size: clamp(14px, calc(14px + 6.00 * ((100vw - 768px) / 672)), 20px);
      line-height: 1.5;
    }

.wlc-section.wlc-section-team {
  background: none;
    padding-top: clamp(68px, calc(68px + 51.00 * ((100vw - 768px) / 672)), 119px);
    padding-bottom: 47px;
    overflow: hidden;
}

.wlc-section.wlc-section-team .bg-layer-wr{
        transform: translate(-62%, -44%);
        overflow: visible;
        z-index: 0;
    }

.wlc-section.wlc-section-team .cont{
        position: relative;
        z-index: 1;
    }

.wlc-section.wlc-section-team .title-animation {
      text-align: left;
  }

.wlc-section.wlc-section-team .title-animation span:nth-of-type(2){
              margin-left: clamp(25px, calc(25px + 43.00 * ((100vw - 768px) / 672)), 68px);
          }

.ts-section-wrapper {
  position: relative;
  padding-top: 1px;
}

.ts-section-wrapper .section-bg {
    bottom: 0;
    left: 0;
    top: auto;
    height: calc(100% - 270px);
  }

.ts-section-wrapper .section-bg img {
      -o-object-position: left bottom;
         object-position: left bottom;
    }

.roles-section.roles-section-team{
  padding: 0;
  overflow: visible;
}

.roles-section.roles-section-team .roles-section-inner{
    overflow: hidden;
    padding: clamp(50px, calc(50px + 71.00 * ((100vw - 768px) / 672)), 121px) 0;
  }

.roles-section.roles-section-team .bg-layer{
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(39%, -41%);
    width: 13.055555555555555%;
    min-width: 80px;
  }

.roles-section.roles-section-team .roles-section__bg-decor{
        top: clamp(19px, calc(101px + -82.00 * ((100vw - 768px) / 672)), 101px);
    }

@media (max-width: 768px) {
    .journey-section .cont{
      flex-direction: column;
      left: clamp(10px, calc(10px + 13.00 * ((100vw - 320px) / 448)), 23px);
    }
    .journey-section-text{
      padding-bottom: 45px;
      padding-top: 50px;
    }
    .journey-section-thumbnail{
      width: 100%;
      max-width: 100%;
    }
}

@media (max-width: 650px) {
    .ts-section-header {
      flex-direction: column;
      align-items: flex-start;
    }

      .ts-section-header .slider-btn {
        margin: 0;
      }

    .ts-section .bg-layer {
      bottom: auto;
      top: clamp(132px, calc(146px + -14.00 * ((100vw - 375px) / 275)), 146px);
    }

    .ts-section .ts-section-title-duplicate {
      font-size: clamp(40px, calc(40px + 16.00 * ((100vw - 320px) / 330)), 56px);
    }

  .ts-swiper-outer {
    width: 100%;
  }
  .join-team-page .first-section .fig-wrapper{
    transform: translateX(-27.5%);
  }
}

.post-details-page {
  overflow: visible!important;
}

.post-details-page .first-section__wrap {
      height: auto;
      min-height: clamp(420px, calc(420px + 280.00 * ((100vw - 768px) / 672)), 700px);
      padding-bottom: clamp(60px, calc(60px + 12.00 * ((100vw - 768px) / 672)), 72px);
    }

.post-details-page .first-section__title {
      mix-blend-mode: normal;
    }

.post-details-page .first-section__title-container {
        max-width: 46%;
      }

.post-details-page .first-section__title h1 {
        font-size: clamp(30px, calc(30px + 30.00 * ((100vw - 768px) / 672)), 60px);
        line-height: 1.0333;
      }

.post-details-page .first-section .hero-post-info {
      color: var(--cl-white);
      font-size: 16px;
      margin-bottom: clamp(25px, calc(25px + 15.00 * ((100vw - 768px) / 672)), 40px);
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }

.post-details-page .first-section .simple-btn {
      margin-top: clamp(35px, calc(35px + 3.00 * ((100vw - 768px) / 672)), 38px);
    }

.post-details-page .footer__wrap {
    padding-top: clamp(60px, calc(60px + 60.00 * ((100vw - 768px) / 672)), 120px) !important;
  }

.post-details-section {
  position: relative;
  transform: translate(0);
  padding: 80px 0 120px;
}

.post-details-section .section-bg {
    z-index: -1;
  }

.post-details-section .article {
    gap: clamp(35px, calc(35px + 123.00 * ((100vw - 768px) / 672)), 158px);
  }

.post-details-section .article-content p:first-of-type {
      font-size: inherit;
      font-weight: 300;
      margin-bottom: 16px;
    }

.post-details-section .article-aside {
      width: 273px;
    }

.post-hero-thumbnail {
  position: absolute;
  right: 0;
  top: 0;
  width: 770px;
  max-width: 49.3%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
}

.post-hero-thumbnail img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    transition: 0.3s;
  }

.article-author {
    position: sticky;
  top: 110px;
}

.article-author h3 {
    font-size: clamp(20px, calc(20px + 4.00 * ((100vw - 768px) / 672)), 24px);
    font-weight: 700;
    font-family: var(--font-second);
    line-height: 1.333;
    margin-bottom: 24px;
    color: var(--cl-blue);
  }

.article-author-wr {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 34px;
  }

.article-author-text p {
      font-size: clamp(18px, calc(18px + 2.00 * ((100vw - 768px) / 672)), 20px);
      font-weight: 700;
    }

.article-author-text span {
      font-size: clamp(14px, calc(14px + 2.00 * ((100vw - 768px) / 672)), 16px);
      font-weight: 400;
      color: var(--cl-blue);
    }

.article-author-thumbnail {
    flex-shrink: 0;
    width: 64px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
  }

.article-author-thumbnail::before {
      content: '';
      display: block;
      padding-top: 100%;
    }

.article-author-thumbnail img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
      transition: 0.3s;
    }

.article-author-social {
    display: flex;
    flex-wrap: wrap;
  }

.article-author-social li {
      display: grid;
      place-items: center;
      width: 56px;
      height: 32px;
      border-right: 1px solid var(--cl-turquoise);
      border-left: 1px solid var(--cl-turquoise);
      margin-left: -1px;
      line-height: 1;
    }

.article-author-social li a {
        font-size: 20px;
        transition: color 0.3s;
      }

.article-author-social li a:hover {
          color: var(--cl-blue);
        }

.article-author-social li a .icon-email {
          font-size: 90%;
        }

.article-author-social li:first-of-type {
        margin-left: 0;
      }

.news-section {
  padding: clamp(60px, calc(60px + 60.00 * ((100vw - 768px) / 672)), 120px) 0 80px;
  transform: translate(0);
  overflow: hidden;
}

.news-section .section-bg {
    z-index: -1;
  }

.news-section .section-bg .bg-layer {
      -o-object-position: center top;
         object-position: center top;
      mix-blend-mode: overlay;
      opacity: 0.6;
      width: 146.31944444444446%;
      max-width: revert;
    }

.news-section .title-wr {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 25px 35px;
    flex-wrap: wrap;
    padding-bottom: clamp(30px, calc(30px + 20.00 * ((100vw - 768px) / 672)), 50px);
  }

.news-section .title-wr .buttons-wr {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 35px 24px;
      flex-shrink: 0;
      max-width: 100%;
      margin-left: auto;
    }

.news-section .title-wr .buttons-wr .slider-btn {
        margin: 0;
      }

.news-swiper-wr .news-card-title {
    margin-bottom: clamp(36px, calc(36px + 10.00 * ((100vw - 768px) / 672)), 46px);
  }

@media (max-width: 1024px) {
    .news-swiper-wr .news-card-title {
    }

    .news-swiper-wr .swiper {
      overflow: visible;
    }

      .news-swiper-wr .swiper-slide {
        width: 375px;
        max-width: 100%;
      }
}

@media (max-width: 650px) {
  .post-details-page .first-section__wrap {
    padding-top: 80px;
    padding-bottom: 40px;
    min-height: 0;
    height: auto;
    flex-direction: column-reverse;
  }

    .post-details-page .first-section__wrap .first-section__title-container {
      max-width: 100%;
      width: 100%;
    }

      .post-details-page .first-section__wrap .first-section__title-container:not(:last-child) {
        margin-bottom: 0;
      }

  .post-hero-thumbnail {
    position: relative;
    width: calc(100% + 50px);
    left: -25px;
    max-width: revert;
    margin-bottom: 35px;
    order: 1;
  }

    .post-hero-thumbnail::before {
      content: '';
      display: block;
      padding-top: 77%;
    }

    .post-hero-thumbnail img {
      -o-object-position: center 10%;
         object-position: center 10%;
    }

  .news-section .title-wr {
    flex-direction: column;
  }

    .news-section .title-wr .buttons-wr {
      flex-direction: column;
      gap: 20px;
    }
}

.news-page .section-bg {
    z-index: -1;
  }

.news-page .first-section {
    z-index: 0;
    background: none;
    overflow: visible;
  }

.news-page .first-section__bg{
      inset: revert;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: visible;
      z-index: -1;
    }

.news-page .first-section__bg img{
        height: auto;
      }

.news-page .first-section__wrap {
      height: auto;
      min-height: 0;
      padding-top: 211px;
      padding-bottom: clamp(30px, calc(30px + 29.00 * ((100vw - 768px) / 672)), 59px);
    }

.news-page .first-section__title {
      mix-blend-mode: normal;
      margin-bottom: clamp(36px, calc(36px + 10.00 * ((100vw - 768px) / 672)), 46px);
    }

.news-page .first-section__title span:last-of-type{
          margin-left: clamp(20px, calc(20px + 46.00 * ((100vw - 768px) / 672)), 66px);
        }

.news-page .first-section__title h1 {
        color: var(--cl-navy);
        font-size: clamp(32px, calc(32px + 68.00 * ((100vw - 768px) / 672)), 100px);
      }

.news-page .footer__wrap{
    padding-top: clamp(60px, calc(60px + 60.00 * ((100vw - 768px) / 672)), 120px) !important;
  }

.news-page .page-pagination{
    padding-top: clamp(40px, calc(40px + 25.00 * ((100vw - 768px) / 672)), 65px);
  }

.news-form {
  display: flex;
  gap: 20px;
  width: 738px;
  max-width: 100%;
  align-items: center;
}

.news-form .input-wr {
    flex: 1;
  }

.news-form .input-wr input {
      width: 100%;
    }

.news-form .btn {
    padding-left: 27px;
    padding-right: 27px;
    gap: 9px;
  }

.news-form .btn .btn__icon {
      color: var(--cl-blue);
      transform: none !important;
      transition: color 0.3s;
      font-size: 20px;
      margin-right: 5px;
    }

.new-results {
  padding: 40px 0 80px;
  z-index: 1;
  position: relative;
}

.new-results-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: clamp(15px, calc(15px + 14.40 * ((100vw - 768px) / 672)), 29.4px)clamp(15px, calc(15px + 5.00 * ((100vw - 768px) / 672)), 20px);
  }

.news-card {
   display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 0 0 20px 20px;
  cursor: pointer;
}

.news-card-thumbnail-wr {
    position: relative;
  }

.news-card-thumbnail {
    position: relative;
    overflow: hidden;
    border-radius: 20px 20px 0 0;
  }

.news-card-thumbnail::before {
      content: '';
      display: block;
      padding-top: 66.58%;
    }

.news-card-thumbnail img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
      transition: 0.3s;
      max-width: revert;
    }

.news-card-thumbnail-overlays {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 20px 20px 0 0;
    }

.news-card-thumbnail-overlays picture {
        overflow: hidden;
      }

.news-card-thumbnail-overlays picture img {
          opacity: 0;
          transition: opacity 0.3s;
          will-change: opacity;
        }

.news-card-thumbnail-overlays picture:nth-of-type(1) img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            mix-blend-mode: screen;
            border-radius: 20px 20px 0 0;
            overflow: hidden;
          }

.news-card-thumbnail-overlays picture:nth-of-type(2) img {
            position: absolute;
            right: 0;
            top: 0;
            width: 38.49878934624697%;
            mix-blend-mode: color-burn;
          }

.news-card-inner{
    background: var(--cl-white);
    padding: 20px clamp(20px, calc(20px + 12.00 * ((100vw - 768px) / 672)), 32px) clamp(20px, calc(20px + 12.00 * ((100vw - 768px) / 672)), 32px);
    display: flex;
    flex-direction: column;
  }

.news-card time{
    font-size: 16px;
    color: var(--cl-blue);
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    margin-bottom: 11px;
  }

.news-card time span{
      color: #757575;
    }

.news-card-title{
    padding: 0 !important;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: clamp(22px, calc(22px + 8.00 * ((100vw - 768px) / 672)), 30px);
    line-height: 1.3300;
    font-weight: 800;
    margin-bottom: 16px;
  }

.news-card-description{
    padding: 0 !important;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: clamp(28px, calc(28px + 10.00 * ((100vw - 768px) / 672)), 38px);
    font-size: 16px;
    line-height: 1.5;
  }

.news-card-footer{
    margin-top: auto;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    color: var(--cl-blue);
    font-size: 18px;
    font-weight: 500;
    line-height: 1.44;
    padding-right: 5px;
    transition: 0.3s;
  }

.news-card-footer i{
      font-size: 13px;
    }

.news-card-footer:hover{
      color: var(--cl-turquoise);
    }

.news-card:hover .news-card-thumbnail img{
        width: 105%;
      }

.news-card:hover .news-card-thumbnail-overlays picture img{
            opacity: 1;
          }

.news-card:hover .news-card-thumbnail-overlays picture:nth-of-type(1) img {
              opacity: 0.6;
            }

@media (max-width: 1100px) {
    .news-page .cont-second{
      width: 100%;
    }

}

@media (max-width: 992px) {
  .new-results-grid{
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
    .news-page .first-section__title-container{
      max-width: 596px;
    }
}

@media (max-width: 600px) {
  .new-results-grid{
    grid-template-columns: minmax(0, 375px);
    justify-content: center;
  }
  .news-form{
   flex-direction: column;
  }
    .news-form .input-wr{
      width: 100%;
    }
    .news-form .btn{
      width: 100%;
    }
}

:root {
  --pp-main: #707070;
  --pp-main-rgb: 112 112 112;

  --pp-black: #000;
  --pp-black-rgb: 0 0 0;

  --pp-white: #ffffff;
  --pp-white-rgb: 255 255 255;

  --pp-dark-blue: #0e1127;
  --pp-dark-blue-rgb: 14 17 39;

  --pp-blue: #3ab4ff;
  --pp-blue-rgb: 58 180 255;

  --pp-dark-gray: #343434;
  --pp-dark-gray-rgb: 52 52 52;

  --pp-light-gray: #dce1e4;
  --pp-light-gray-rgb: 220 225 228;

  --pp-font-main: var(--font-main);
}

.popup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 8999;
  display: none;
}

.popup.active {
    display: block;
  }

.popup__close-bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: -1;
    transition: all 0.5s ease;
    animation-duration: 0.3s;
    animation-name: opacityPopupBg;
  }

.popup__block {
    --pp-default-translate-x-from: -50%;
    --pp-default-translate-x-to: -50%;

    --pp-default-translate-y-from: -50%;
    --pp-default-translate-y-to: -50%;

    --pp-opacity-from: 0;
    --pp-opacity-to: 1;

    background: var(--pp-white);

    position: absolute;
    top: 50%;
    left: 50%;

    width: 90%;
    max-width: clamp(600px, calc(600px + 250.00 * ((100vw - 768px) / 672)), 850px);
    padding: clamp(30px, calc(30px + 10.00 * ((100vw - 768px) / 672)), 40px) clamp(24px, calc(24px + 16.00 * ((100vw - 768px) / 672)), 40px);

    transform: translateY(var(--pp-default-translate-y-to))
      translateX(var(--pp-default-translate-x-to));
    transition: all 300ms ease-out;
    animation-duration: 0.6s;
  }

.popup__block.without-opacity {
      --pp-opacity-from: 1;
    }

.popup__block.pos-l, .popup__block.pos-r {
      --pp-default-translate-x-from: 0;
      --pp-default-translate-x-to: 0;
    }

.popup__block.pos-t, .popup__block.pos-b {
      --pp-default-translate-y-from: 0;
      --pp-default-translate-y-to: 0;
    }

.popup__block.pos-t {
      top: 0;
      bottom: auto;
      bottom: initial;
    }

.popup__block.pos-r {
      right: 0;
      left: auto;
      left: initial;
    }

.popup__block.pos-b {
      top: auto;
      top: initial;
      bottom: 0;
    }

.popup__block.pos-l {
      right: auto;
      right: initial;
      left: 0;
    }

.popup__block.full-height {
      max-height: 100vh;
      height: 100%;
      right: 0;
      left: auto;
      left: initial;
      --pp-default-translate-x-from: 0;
      --pp-default-translate-x-to: 0;
    }

.popup__block.full-height .popup__content {
        max-height: calc(var(--vh, 1vh) * 72);
      }

.popup__block.slide-l {
      --pp-default-translate-x-from: 100%;
    }

.popup__block.slide-r {
      --pp-default-translate-x-from: -100%;
    }

.popup__block.slide-t {
      --pp-default-translate-y-from: -100%;
    }

.popup__block.slide-b {
      --pp-default-translate-y-from: 100%;
    }

.popup__block.slide-t, .popup__block.slide-r, .popup__block.slide-b, .popup__block.slide-l {
      animation-name: popupSlide;
    }

.popup__title {
    color: var(--pp-dark-blue);
    font-size: clamp(20px, calc(20px + 4.00 * ((100vw - 768px) / 672)), 24px);
    line-height: 1.2;
    font-family: var(--pp-font-main);
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: clamp(24px, calc(24px + 8.00 * ((100vw - 768px) / 672)), 32px);
  }

.popup__btn-close {
    position: absolute;
    right: clamp(15px, calc(15px + 10.00 * ((100vw - 768px) / 672)), 25px);
    top: clamp(15px, calc(15px + 10.00 * ((100vw - 768px) / 672)), 25px);
    transition: all 300ms ease-out;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

.popup__btn-close .close-clip {
      transition: inherit;
      width: clamp(20px, calc(20px + 4.00 * ((100vw - 768px) / 672)), 24px);
      height: clamp(20px, calc(20px + 4.00 * ((100vw - 768px) / 672)), 24px);
      background: var(--pp-dark-blue);
      -webkit-clip-path: polygon(
        20% 0%,
        0% 20%,
        30% 50%,
        0% 80%,
        20% 100%,
        50% 70%,
        80% 100%,
        100% 80%,
        70% 50%,
        100% 20%,
        80% 0%,
        50% 30%
      );
              clip-path: polygon(
        20% 0%,
        0% 20%,
        30% 50%,
        0% 80%,
        20% 100%,
        50% 70%,
        80% 100%,
        100% 80%,
        70% 50%,
        100% 20%,
        80% 0%,
        50% 30%
      );
    }

.popup__btn-close i {
      transition: inherit;
      font-size: clamp(20px, calc(20px + 4.00 * ((100vw - 768px) / 672)), 24px);
      color: var(--pp-dark-blue);
    }

.popup__btn-close:hover .close-clip {
        background: var(--pp-blue);
        transform: scale(1.05);
      }

.popup__btn-close:hover i {
        color: var(--pp-blue);
        transform: scale(1.05);
      }

.popup__content {
    max-height: calc(var(--vh, 1vh) * 66);
  }

.popup__content .simplebar-track.simplebar-vertical {
      width: 4px;
      background: var(--pp-light-gray);
      border-radius: 4px;
      transform: translateX(10px);
    }

.popup__content .simplebar-track.simplebar-vertical .simplebar-scrollbar {
        background: var(--pp-dark-blue);
        border-radius: 4px;
      }

.popup__content .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
          content: none;
        }

.popup__content .popup-form__container {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-column-gap: 15px;
        grid-row-gap: 15px;
      }

@media not all and (min-width: 640px) {

.popup__content .popup-form__container {
          grid-template-columns: repeat(1, minmax(0, 1fr))
      }
  }

.popup__content .popup-form__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 18px;
        padding-top: clamp(20px, calc(20px + 4.00 * ((100vw - 768px) / 672)), 24px);
        flex-wrap: wrap;
      }

.popup__content .popup-form__bottom .popup-btn {
          margin-left: auto;
        }

.popup__content .popup-btn {
      font-size: clamp(14px, calc(14px + 2.00 * ((100vw - 768px) / 672)), 16px);
      font-weight: 700;
      font-family: var(--pp-font-main);
      line-height: 1.2;
      text-transform: uppercase;
      background-color: var(--pp-dark-blue);
      color: var(--pp-white);
      border: none;
      outline: none;
      border-radius: 4px;
      padding: 12px 18px;
      cursor: pointer;
      transition: 0.3s ease-in-out all;
      position: relative;
      overflow: hidden;
    }

.popup__content .popup-btn::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: skew(45deg);
        pointer-events: none;
      }

.popup__content .popup-btn:hover::after {
          background-image: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 40%,
            rgba(255, 255, 255, 0.7) 100%
          );
          animation: gradientPopupBtn 0.3s linear forwards;
        }

.popup__content .popup-btn:active {
        transform: scale(0.9);
        opacity: 0.9;
      }

@keyframes popupSlide {
  from {
    transform: translateY(var(--pp-default-translate-y-from))
      translateX(var(--pp-default-translate-x-from));
    opacity: var(--pp-opacity-from);
  }

  to {
    transform: translateY(var(--pp-default-translate-y-to))
      translateX(var(--pp-default-translate-x-to));
    opacity: var(--pp-opacity-to);
  }
}

@keyframes opacityPopupBg {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes gradientPopupBtn {
  0% {
    transform: translateX(-100%) skew(45deg);
  }

  100% {
    transform: translateX(100%) skew(45deg);
  }
}

.btn {
  display: inline-flex;
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
  justify-content: center;
  align-items: center;
  padding: 12px 25px;
  height: 54px;
  transition: all .3s ease;
  background: var(--cl-white);
  border: 2px solid var(--cl-lilac);
  overflow: hidden;
  position: relative;
  border-radius: 60px;
  grid-column-gap: 16px;
  flex-shrink: 0;
  box-shadow: 2px 4px 8px 0 rgba(0,0,0,0);

}

.btn__text {
    width: 100%;
    font-family: var(--font-main);
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: var(--cl-navy);
    position: relative;
    z-index: 2;
    transition: all .3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
  }

.btn__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: var(--cl-navy);
    transition: transform .3s ease;
    transform-origin: left;
  }

.btn:hover {
    border-color: var(--cl-turquoise);
    background-color: var(--cl-turquoise);

    transform: translateY(-2px);
    box-shadow: 2px 4px 8px 0 rgba(0,0,0,0.2);

  }

.btn:hover .btn__text{
      color: var(--cl-white);
    }

.btn:hover .btn__icon {
      transform: translateX(50%);
      color: var(--cl-white);
    }

.btn:active {
    transform: scale(0.9);
    opacity: .8;
  }

.btn.btn-navy {
    background-color: var(--cl-navy);
    border-color: var(--cl-navy);
  }

.btn.btn-navy .btn__text {
      color: var(--cl-white);
    }

.btn.btn-navy:hover {
      border-color: var(--cl-navy);
      background-color: #0c2a57;
    }

.btn.btn-navy:hover .btn__text {
        color: var(--cl-turquoise);
      }

.btn.btn-transparent{
    border: 1px solid var(--cl-white);
    background: transparent;
    color: var(--cl-white);
  }

.btn.btn-transparent .btn__text, .btn.btn-transparent .btn__icon{
     color: inherit;
   }

.btn.btn-transparent:hover{
      background: var(--cl-turquoise);
    }

.btn.btn-reverse{
    flex-direction: row-reverse;
  }

.btn.btn-reverse:hover .btn__icon{
        transform: translateX(-50%);
      }

.btn.btn-upload .btn__icon{
      transform: none;
    }

.btn.btn-upload:not(:hover) .btn__icon{
        color: var(--cl-blue);
      }

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  grid-row-gap: clamp(8px, calc(8px + 2.00 * ((100vw - 768px) / 672)), 10px);
  grid-column-gap: clamp(8px, calc(8px + 9.00 * ((100vw - 768px) / 672)), 17px);
}

.slider-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: clamp(56px, calc(56px + 6.00 * ((100vw - 768px) / 672)), 62px);
  height: clamp(56px, calc(56px + 6.00 * ((100vw - 768px) / 672)), 62px);
  font-size: 22px;
  /*font-size: 32px;*/
  color: var(--cl-white);
  border: 1px solid var(--cl-white);
  border-radius: 50%;
  transition: border-color .3s ease, color .3s ease, opacity .3s ease, background-color .3s ease;
  background-color: transparent;
  cursor: pointer;
  margin-top: 32px;
}

.slider-btn.swiper-button-disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

.slider-btn:hover:not(.swiper-button-disabled) {
      border-color: var(--cl-navy);
      background-color: var(--cl-lilac);
      color: var(--cl-navy);
    }

.slider-btn.swiper-button-lock {
    display: none;
  }

/*&.slider-btn-second {
    color: var(--cl-beige);


    &:hover {
      &:not(.swiper-button-disabled) {
        color: var(--cl-black);
      }
    }
  }*/

.slider-btn.slider-btn-navy {
    border-color: var(--cl-navy);
    color: var(--cl-navy);
    background: transparent;
  }

.slider-btn.slider-btn-navy:hover {
      background: transparent;
    }

.slider-btn.slider-btn-navy:hover:not(.swiper-button-disabled) {
        border-color: var(--cl-purple);
        color: var(--cl-purple);
      }

.slider-buttons {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-gap: 12px;
}

.link-btn {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  grid-column-gap: 12px;
  position: relative;
}

.link-btn:before {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 0;
    height: 1px;
    background: var(--cl-black);
    content: "";
  }

.link-btn__text {
    font-size: clamp(16px, calc(16px + 4.00 * ((100vw - 768px) / 672)), 20px);
    line-height: 33px;
    font-weight: 600;
  }

.link-btn__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12.65px;
    color: var(--cl-black);
    transition: transform .3s ease;
    margin-top: clamp(1px, calc(2px + -1.00 * ((100vw - 768px) / 672)), 2px);
  }

.link-btn:hover .link-btn__icon {
      transform: translateX(100%);
    }

.buttons-links {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  grid-row-gap: clamp(8px, calc(8px + 2.00 * ((100vw - 768px) / 672)), 10px);
  grid-column-gap: clamp(8px, calc(8px + 9.00 * ((100vw - 768px) / 672)), 17px);
}

.social-link {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
  color: var(--cl-turquoise);
  margin: clamp(0px, calc(0px + 3.75 * ((100vw - 768px) / 672)), 3.75px);
  transition: color .3s, transform .3s;
  will-change: color, transform;
}

.social-link:hover {
    color: var(--cl-white);
    transform: scale(1.2);
  }

.socials {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}

.simple-btn{
  color: var(--cl-white);
  display: inline-flex;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  gap: 22px;
  transition: color 0.3s;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.44;
  text-shadow: 0 0 10px rgba(0,0,0,0);
  padding-left: 4px;
}

.simple-btn i{
    font-size: 15px;
  }

.simple-btn:hover{
    color: var(--cl-turquoise);
    text-shadow: 0 0 10px rgba(0,0,0,0.2);
  }

.filters-group-toggle{
  display: flex;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  gap: 11px;
  font-size: clamp(16px, calc(16px + 2.00 * ((100vw - 768px) / 672)), 18px);
  color: var(--cl-blue);
  font-weight: 500;
  line-height: 1.44;
  cursor: pointer;
  transition: 0.3s;
}

.filters-group-toggle-text span:nth-of-type(2){
        display: none;
      }

.filters-group-toggle.active{
    color: var(--cl-navy);
  }

.filters-group-toggle.active .filters-group-toggle-text span{
        display: none;
      }

.filters-group-toggle.active .filters-group-toggle-text span:nth-of-type(2){
          display: inline-block;
        }

.filters-group-toggle.active .plus-btn:before{
        background: var(--cl-navy);
      }

.filters-group-toggle.active .plus-btn::after{
        opacity: 0;
      }

.filters-group-toggle:hover{
    color: var(--cl-turquoise);
  }

.filters-group-toggle:hover .plus-btn{

      border-color: var(--cl-turquoise);
      background: var(--cl-turquoise);

    }

.filters-group-toggle:hover .plus-btn::before, .filters-group-toggle:hover .plus-btn::after{
        background: var(--cl-white);
      }

.plus-btn{
  position: relative;
   display: block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid rgba(6,19,68,0.15);
  background: var(--cl-white);
  cursor: pointer;
  transition: 0.3s;
}

.plus-btn::before, .plus-btn::after{
    content: '';
    display:block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 15px;
    height: 3px;
    border-radius: 5px;
    background: var(--cl-blue);
    transition: 0.3s;
  }

.plus-btn::after{
    width: 3px;
    height: 15px;
  }

.plus-btn.active::after{
      opacity: 0;
    }

.plus-btn:hover{
    border-color: var(--cl-turquoise);
    background: var(--cl-turquoise);
  }

.plus-btn:hover::before, .plus-btn:hover::after{
      background: var(--cl-white);
    }

input, textarea {
  border-radius: 0;
}

.meta-input label, .meta-upload-file label {
    font-family: var(--font-main);
    font-size: clamp(16px, calc(16px + 2.00 * ((100vw - 768px) / 672)), 18px);
    line-height: normal;
    font-weight: 400;
    color: var(--cl-white);
    padding-bottom: clamp(11px, calc(11px + 4.00 * ((100vw - 768px) / 672)), 15px);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

.meta-input label span, .meta-upload-file label span {
      margin-left: 4px;
      display: inline-block;
      color: var(--cl-mint);
    }

.meta-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  /*&__error {
      color: var(--cl-red);
    }*/
}

.meta-input input, .meta-input textarea {
    width: 100%;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: var(--font-second);
    color: var(--cl-black);
    border: 1px solid var(--cl-mint);
    border-radius: 0;
    outline: none;
    transition: box-shadow 0.25s ease, border-color 0.3s ease,
      caret-color 0.3s ease, color 0.3s ease, opacity 0.3s ease;
    padding: clamp(16px, calc(16px + 4.00 * ((100vw - 768px) / 672)), 20px) 20px;
    height: clamp(56px, calc(56px + 8.00 * ((100vw - 768px) / 672)), 64px);
    background: var(--cl-beige);

    /*&.error {
          border-color: var(--cl-red);
          box-shadow: 2px 2px 5px 0px rgba(126, 207, 174, 0.3);
        }*/
  }

.meta-input input:-webkit-autofill, .meta-input input:-webkit-autofill:hover, .meta-input input:-webkit-autofill:focus, .meta-input input:-webkit-autofill:active, .meta-input textarea:-webkit-autofill, .meta-input textarea:-webkit-autofill:hover, .meta-input textarea:-webkit-autofill:focus, .meta-input textarea:-webkit-autofill:active {
		-webkit-transition: background-color 5000s ease-in-out 0s;
		transition: background-color 5000s ease-in-out 0s;
		-webkit-text-fill-color: var(--cl-black) !important;
	}

.meta-input input::-moz-placeholder, .meta-input textarea::-moz-placeholder {
      -moz-transition: all 0.3s ease;
      transition: all 0.3s ease;
      color: var(--cl-black);
      opacity: 0.5;
    }

.meta-input input::placeholder, .meta-input textarea::placeholder {
      transition: all 0.3s ease;
      color: var(--cl-black);
      opacity: 0.5;
    }

.meta-input input:focus, .meta-input textarea:focus {
      border-color: var(--cl-leaf);
      caret-color: var(--cl-black);
      box-shadow: 2px 2px 5px 0px rgba(126, 207, 174, 0.3);
    }

.meta-input textarea {
    padding-top: 11px;
    min-height: clamp(96px, calc(96px + 4.00 * ((100vw - 768px) / 672)), 100px);
    max-height: clamp(96px, calc(96px + 4.00 * ((100vw - 768px) / 672)), 100px);
    overflow-y: auto;
    resize: none;
    /*clip-path: inset(-2px -2px -2px -2px round 17px);*/

    scrollbar-width: thin;
    scrollbar-color: var(--cl-leaf) transparent;
  }

.meta-input textarea::-webkit-scrollbar {
      width: 3px;
    }

.meta-input textarea::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 2px;
    }

.meta-input textarea::-webkit-scrollbar-thumb {
      background: var(--cl-blue);
      border-radius: 2px;
    }

.meta-input__help, .meta-input__error {
    margin-top: 5px;
    font-family: var(--font-main);
    font-size: clamp(12px, calc(12px + 2.00 * ((100vw - 768px) / 672)), 14px);
    line-height: 1.2;
    font-weight: 600;
  }

.meta-input__help {
    color: var(--cl-white);
  }

.is-safari .meta-input textarea::-webkit-scrollbar {
        width: auto;
        width: initial;
      }

.is-safari .meta-input textarea::-webkit-scrollbar-track {
        background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
        background: initial;
        border-radius: 0;
        border-radius: initial;
      }

.is-safari .meta-input textarea::-webkit-scrollbar-thumb {
        background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
        background: initial;
        border-radius: 0;
        border-radius: initial;
      }

label.checkbox-wr, .checkbox-wr {
  --checkbox-icon-size: clamp(19px, calc(19px + 5.00 * ((100vw - 768px) / 672)), 24px);
  --checkbox-mark-color: var(--cl-lilac, #e1e8ff);
  display: flex;
  gap: 12px;
  cursor: pointer;
  font-size: clamp(16px, calc(16px + 4.00 * ((100vw - 768px) / 672)), 20px);
}

label.checkbox-wr input, .checkbox-wr input {
    display: none;
  }

label.checkbox-wr input:checked + .checkbox-icon::before, .checkbox-wr input:checked + .checkbox-icon::before {
        opacity: 1;
      }

label.checkbox-wr .checkbox-icon, .checkbox-wr .checkbox-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: var(--checkbox-icon-size);
    height: var(--checkbox-icon-size);
    border: 2px solid var(--cl-navy);
  }

label.checkbox-wr .checkbox-icon::before, .checkbox-wr .checkbox-icon::before {
      content: '';
      display: block;
      position: absolute;
      left: -2px;
      top: -2px;
      width: var(--checkbox-icon-size);
      height: var(--checkbox-icon-size);
      background: var(--checkbox-mark-color);
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V22C0 22.5304 0.210714 23.0391 0.585786 23.4142C0.960859 23.7893 1.46957 24 2 24H22C22.5304 24 23.0391 23.7893 23.4142 23.4142C23.7893 23.0391 24 22.5304 24 22V2C24 1.46957 23.7893 0.960859 23.4142 0.585786C23.0391 0.210714 22.5304 0 22 0ZM17.7075 9.7075L10.7075 16.7075C10.6146 16.8005 10.5043 16.8742 10.3829 16.9246C10.2615 16.9749 10.1314 17.0008 10 17.0008C9.86858 17.0008 9.73846 16.9749 9.61706 16.9246C9.49566 16.8742 9.38537 16.8005 9.2925 16.7075L6.2925 13.7075C6.10486 13.5199 5.99944 13.2654 5.99944 13C5.99944 12.7346 6.10486 12.4801 6.2925 12.2925C6.48014 12.1049 6.73464 11.9994 7 11.9994C7.26536 11.9994 7.51986 12.1049 7.7075 12.2925L10 14.5863L16.2925 8.2925C16.3854 8.19959 16.4957 8.12589 16.6171 8.07561C16.7385 8.02532 16.8686 7.99944 17 7.99944C17.1314 7.99944 17.2615 8.02532 17.3829 8.07561C17.5043 8.12589 17.6146 8.19959 17.7075 8.2925C17.8004 8.38541 17.8741 8.49571 17.9244 8.6171C17.9747 8.7385 18.0006 8.86861 18.0006 9C18.0006 9.13139 17.9747 9.2615 17.9244 9.3829C17.8741 9.50429 17.8004 9.61459 17.7075 9.7075Z' fill='%235500EC'/%3E%3C/svg%3E%0A");
      background-position: center;
      opacity: 0;
      transition: opacity 0.3s;
    }

label.checkbox-wr a, .checkbox-wr a {
    display: inline;
    transition: 0.3s;
  }

label.checkbox-wr a:hover, .checkbox-wr a:hover {
      color: var(--cl-turquoise);
    }

label.checkbox-wr:hover, .checkbox-wr:hover {
    color: var(--cl-blue);
  }

/*range styles*/

.double-range {
  padding-right: 0;
  margin-bottom: 16px;
  height: 2px;
  border-radius: 12px;
  border: none;
  box-shadow: none;
  background: lightgrey;
}

.double-range .noUi-connect {
    background: #8e96b4;
    margin-right: -4px;
  }

.double-range .noUi-connects {
    background: var(--cl-navy);
  }

.double-range .noUi-handle {
    background: var(--cl-turquoise);
    width: 24px;
    height: 24px;
    border: 3px solid var(--cl-navy);
    cursor: pointer;
    border-radius: 50%;
    box-shadow: none;
    top: 0;
    transform: translateY(-50%);
  }

.double-range .noUi-handle.noUi-handle-lower {
      transform: translate(29%, -50%);
    }

.double-range .noUi-handle.noUi-handle-upper {
      transform: translate(-50%, -50%);
    }

.double-range .noUi-handle:before, .double-range .noUi-handle:after {
      content: none;
    }

.double-range-container {
    width: 100%;
    max-width: 250px;
  }

.double-range-tooltip {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 28px;
    gap: 8px;
  }

.double-range-tooltip i {
      display: block;
      flex: 1;
      height: 1px;
      background: var(--input-border-color);
    }

.double-range-info {
  min-height: 48px;
  border: 1px solid var(--input-border-color);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  padding: 5px;
  text-align: center;
  align-items: center;
  background: var(--cl-white);
  min-width: 105.5px;
  line-height: 1;
  font-size: 16px;
  font-weight: 500;
}

.double-range-info-min {
  }

/*and of range styles*/

.input-wr input {
    background: no-repeat;
    height: clamp(48px, calc(48px + 8.00 * ((100vw - 768px) / 672)), 56px);
    border-bottom: 1px solid var(--cl-navy);
    font-size: 16px;
    font-weight: 500;
  }

.input-wr input::-moz-placeholder {
      color: inherit;
      opacity: 1;
    }

.input-wr input::placeholder {
      color: inherit;
      opacity: 1;
    }

/* ----------------------- Common desktop header ----------------------- */

.header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 998;
  padding: clamp(16px, calc(16px + 14.00 * ((100vw - 768px) / 672)), 30px) 0;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.header:before {
    position: absolute;
    inset: 0;
    content: '';
    z-index: -1;
    background-color: transparent;
    opacity: 0;
    transition: all 0.25s ease;
  }

.header.fixed {
    position: fixed;
  }

/*&:not(.scrolled) {
    @mixin sm {
      margin-top: calc(ac(40px, 32px) + ac(52px, 40px));
    }

    @mixin max-sm {
      margin-top: ac(40px, 32px);
    }
  }*/

.header.scrolled:not(.active) {
      -webkit-backdrop-filter: blur(16px);
      backdrop-filter: blur(16px);
      padding: clamp(14px, calc(14px + 8.00 * ((100vw - 768px) / 672)), 22px) 0;

      /*.menu__link {
        color: var(--cl-white);

        &:hover {
          color: var(--cl-pink);
        }
      }

      .burger,
      .menu-toggle {
        svg path {
          &:not(.active) {
            stroke: var(--cl-white);

            &:hover {
              stroke: var(--cl-pink);
            }
          }
        }
      }*/
    }

.header.scrolled:not(.active):before {
        opacity: 0.6;
        background-color: var(--cl-navy);
      }

.header.scrolled:not(.active) .logo {

      }

/*&.white-type {
    &:not(.scrolled) {
      &:not(.active) {
        .logo {
          .color-change {
            path {
              fill: var(--cl-cream);
            }
          }
        }

        .menu__link {
          color: var(--cl-white);

          &:hover {
            color: var(--cl-pink);
          }
        }

        .burger,
        .menu-toggle {
          svg path {
            &:not(.active) {
              stroke: var(--cl-white);

              &:hover {
                stroke: var(--cl-pink);
              }
            }
          }
        }
      }
    }
  }*/

.header .logo {
    height: 100%;
    width: clamp(156px, calc(156px + 26.00 * ((100vw - 768px) / 672)), 182px);
    flex-shrink: 0;
    transition: width 0.25s ease;
    z-index: 100;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

.header .logo img, .header .logo svg {
      width: 100%;
      height: 100%;
      -o-object-fit: contain;
         object-fit: contain;
      -o-object-position: left;
         object-position: left;
      transition: transform 0.25s ease;
    }

.header .logo img path, .header .logo svg path {
        transition: all 0.3s ease;
        fill: var(--cl-white);
      }

.header .logo:hover img, .header .logo:hover svg {
        transform: scale(1.06);
      }

.header .logo:hover img path, .header .logo:hover svg path {
          fill: var(--cl-lilac)
        }

@media not all and (min-width: 360px) {

.header .logo{
      width: 180px;
  }
  }

.header__wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

.header .burger {
    display: none;
  }

.header__navbar {
    display: flex;
    flex-grow: 1;
  }

.header__navbar .menu-list {
      display: flex;
      flex-grow: 1;
    }

.header__navbar__decor {
      display: none;
    }

@media (min-width: 1281px) {

.header__navbar{
      max-width: calc(100% - 200px);
  }
  }

@media (min-width: 1400px) {

.header__navbar{
      margin-bottom: -2px;
  }
	}

.header__socials {
    display: none;
  }

.header__navbar-decor {
    display: none;
  }

.header .menu {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }

/*transition: opacity 0.2s ease;
    opacity: 0;*/

@media (min-width: 1281px) {

.header .menu {
      width: auto;
      flex: 1;
      align-items: center;
  }

      .header .menu .simplebar-content-wrapper, .header .menu .simplebar-wrapper, .header .menu .simplebar-mask {
        overflow: visible !important;
      }

      .header .menu .simplebar-content {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        grid-column-gap: clamp(14px, calc(14px + 6.00 * ((100vw - 768px) / 672)), 20px);
      }

        .header .menu .simplebar-content .menu-list {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: clamp(20px, calc(20px + 12.00 * ((100vw - 768px) / 672)), 32px);
        }

        .header .menu .simplebar-content:after {
          display: none;
        }
      .header .menu .simplebar-track.simplebar-horizontal {
        display: none;
      }
        .header .menu .menu__list {
          /*padding-left: 30px;
          padding-right: 30px;*/
          display: flex;
          align-items: center;
          justify-content: flex-end;
          /*grid-column-gap: ac(53px, 40px);*/
          background: var(--cl-navy);
          border-radius: 34px;
          overflow: hidden;
          position: relative;
        }

          .header .menu .menu__list:after {
            position: absolute;
            inset: 0;
            z-index: 5;
            border-radius: 34px;
            border: 2px solid var(--cl-black);
            pointer-events: none;
            content: "";
          }

          .header .menu .menu__list .flying-item {
            position: absolute;
            top: 0;
            bottom: 0;
            border-radius: 34px;
            background: rgba(27, 212, 237, 0.1);
            left: 0;
            transition: width .3s ease, margin-left .6s ease, opacity .3s ease;
            pointer-events: none;
            opacity: 0;
          }
  }

.header .menu:not([data-simplebar='init']) {
      display: none;
    }

.header .menu__item {
    position: relative;
  }

.header .menu__item .menu__link {
      padding: 0 clamp(20px, calc(20px + 13.00 * ((100vw - 768px) / 672)), 33px);
      height: 52px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

.header .menu__item.menu-dropdown {
      padding-right: 12px;
      position: relative;

      /*.menu-dropdown {
        &__arrow {
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--cl-beige);
          cursor: pointer;
          transition: all .3s ease;

          @mixin mobile-menu-end-point {
            position: absolute;
            top: 55%;
            transform: translateY(-50%) scale(1, 1);
            right: 0;
            font-size: 7px;
          }

          @mixin mobile-menu-start-point {
            transform: scale(1, 1);
            font-size: 12px;
            margin-left: 8px;

            &.active {
              transform: scale(1, -1);
            }
          }
        }

        &__list {
          transition: max-height .3s ease, opacity .3s ease, margin-top .3s ease;
          opacity: 0;
          max-height: 0;
          overflow: hidden;
          width: auto;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          @mixin mobile-menu-end-point {
            padding-left: 10px;
            padding-right: 10px;
            position: absolute;
            bottom: -2px;
            transform: translateY(100%);
            background-color: rgba(28, 28, 28, 0.8);
            z-index: 999;

            .menu__link {
              &:first-child {
                margin-top: 5px !important;
              }

              &:last-child {
                margin-bottom: 5px !important;
              }
            }
          }

          .menu__link {
            white-space: nowrap;
          }

          @mixin max-sm {
            align-items: center;
          }

          @mixin mobile-menu-start-point {
            &.active {
              max-height: 300px;
              opacity: 1;
              margin-top: 25px;
              grid-row-gap: 25px;
            }
          }
        }
      }*/

      /*&:hover {
        !*.menu-dropdown__arrow {
          color: var(--cl-mint);
        }

        @mixin mobile-menu-end-point {

          .menu-dropdown__arrow {
            transform: translateY(-50%) scale(1, -1);
          }

          .menu-dropdown__list {
            max-height: 300px;
            opacity: 1;
          }
        }*!
      }*/
    }

@media not all and (min-width: 640px) {

.header .menu__item.menu-dropdown {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start
    }
  }

@media(max-width: 1280px) {

.header .menu__item.menu-dropdown {
        padding-right: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
    }

        @media not all and (min-width: 640px) {

.header .menu__item.menu-dropdown {
          justify-content: center
    }
  }

        .header .menu__item.menu-dropdown .menu__link {
          white-space: nowrap;
        }

        .header .menu__item.menu-dropdown .menu-dropdown__list {
          width: 100% !important;
        }
  }

.header .menu__link {
    position: relative;
    font-size: clamp(14px, calc(14px + 2.00 * ((100vw - 768px) / 672)), 16px);
    line-height: normal;
    font-weight: 500;
    color: var(--cl-white);
    transition: all 0.3s ease;
    cursor: pointer;
    font-family: var(--font-main);

    /*&:before {
      right: 0;
      left: auto;
      bottom: 0;
      width: 0;
      height: 1px;
      background: var(--cl-mint);
      opacity: 0;
      transition: width .3s ease, opacity .3s ease;
      content: "";
      position: absolute;
    }

    &:hover {
      color: var(--cl-mint);

      &:before {
        right: auto;
        left: 0;
        width: 100%;
        opacity: 1;
      }
    }*/
  }

.header__buttons.buttons {
      padding-left: clamp(16px, calc(16px + 4.00 * ((100vw - 768px) / 672)), 20px);
    }

@media not all and (min-width: 640px) {

.header__buttons.buttons {
        margin-top: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
  }

.header__buttons.buttons .btn {
        height: 52px;
        border-color: rgba(225, 232, 255, 0.4);
        min-width: clamp(140px, calc(140px + 14.00 * ((100vw - 768px) / 672)), 154px);
      }

.header__buttons.buttons .btn__text {
          font-size: clamp(16px, calc(18px + -2.00 * ((100vw - 768px) / 672)), 18px);
          white-space: nowrap;
        }

@media(max-width: 1280px) {

.header__buttons.buttons{
        display: none;
  }
  }

@media not all and (min-width: 640px) {

.header__buttons.buttons{
        display: flex;
  }
  }

@media not all and (min-width: 551px) {
        .header__buttons.buttons .btn {
          width: 100%;
          height: 54px;
        }
	}

.header__buttons--pad {
      display: none;
    }

@media(max-width: 1280px) {
        @media (min-width: 640px) {

.header__buttons--pad {
          display: flex !important;
          padding-right: 20px
    }

          .header__buttons--pad .btn {
            height: clamp(48px, calc(48px + 4.00 * ((100vw - 768px) / 672)), 52px);
          }
  }
  }

@media not all and (min-width: 640px) {

.header__buttons--pad {
        display: none !important
    }
  }

/* end of Common desktop header */

/* ----------------------- Common desktop header Mobile HEADER ----------------------- */

@media(max-width: 1280px) {
  .header:not(.header-mobile) {

    /*&.active {
      padding: 14px *;
      .logo {
        width: 215px;
      }
    }*/
  }
    .header:not(.header-mobile) .burger, .header:not(.header-mobile) .menu-toggle {
      display: flex;
    }

    .header:not(.header-mobile) .logo {
      border: none;
    }

    .header:not(.header-mobile) .header__navbar-cont {
      display: flex;
      flex-direction: column;

      height: 100%;
      margin: 0 auto;
      max-width: 1280px;
      width: 88.88888888888889%;
    }

      @media not all and (min-width: 1024px) {

    .header:not(.header-mobile) .header__navbar-cont {
        width: 89.33%
    }
  }

    .header:not(.header-mobile) .header__navbar {
      position: fixed;
      right: 0;
      top: 0;
      width: 40%;
      min-width: 320px;
      padding-top: clamp(107px, calc(107px + 73.00 * ((100vw - 768px) / 672)), 180px);
      background: linear-gradient(103.02deg, #061344 -1.78%, #8900EA 119.69%), #061344;
      padding-right: 40px;
      padding-left: 40px;
      padding-bottom: 40px;
      transition: transform 0.3s ease-out;
      overflow: hidden;
      flex-direction: column;
      height: auto;
      border-bottom-left-radius: 50px;
    }

      @media screen and (max-height: 740px) {

    .header:not(.header-mobile) .header__navbar {
        padding-top: 140px
    }
      }

      @media not all and (min-width: 768px) {

      .header:not(.header-mobile) .header__navbar{
        padding-right: calc((100vw - 87.20vw) / 2);
        padding-left: calc((100vw - 87.20vw) / 2);
    }
  }

      @media not all and (min-width: 640px) {

      .header:not(.header-mobile) .header__navbar{
        width: 100%;
        height: calc(var(--dvh, 1vh) * 100);
        border-bottom-left-radius: 0;
    }
  }

      .header:not(.header-mobile) .header__navbar.nav-slide-right {
        right: 0;
        left: auto;
        transform: translateX(100%);
      }

        .header:not(.header-mobile) .header__navbar.nav-slide-right.active {
          transform: translateX(0);
        }

      .header:not(.header-mobile) .header__navbar .header__socials {
        display: flex;
        justify-content: center;
        width: 100%;
        position: relative;
        z-index: 1;
        margin-bottom: 40px;
      }

        @media not all and (min-width: 640px) {

      .header:not(.header-mobile) .header__navbar .header__socials {
          justify-content: flex-start;
          padding-left: 4px
      }
  }

      .header:not(.header-mobile) .header__navbar .menu {
        margin-top: auto;
        max-height: 100%;
        margin-bottom: 0;
        flex-direction: column;
        justify-content: flex-start;
        gap: 0;

        align-items: flex-start;
        align-self: center;
        position: relative;
        z-index: 2;

        overflow-x: hidden;
        overflow-y: auto;

        /*&__item.hidden {
          display: block;

          &.first-hidden {
            margin-top: 28px;

            @media screen and (max-height: 730px) {
              margin-top: 10px;
            }

            @media screen and (max-height: 640px) {
              margin-top: 0;
            }
          }

          .menu__link {
            position: relative;

            &:before {
              left: 0;
              bottom: 0;
              right: 0;
              height: 1px;
              width: 100%;
              opacity: 1;
              background: var(--cl-leaf);
              content: "";
              position: absolute;
              display: block;
            }
          }
        }*/
      }

        .header:not(.header-mobile) .header__navbar .menu .simplebar-content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          width: 100%;
        }

          .header:not(.header-mobile) .header__navbar .menu .simplebar-content .menu-list {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
          }

        .header:not(.header-mobile) .header__navbar .menu .simplebar-track.simplebar-vertical {
          width: 6px;
          background: var(--cl-navy);
          border-radius: 0;
          transform: none;
        }

          .header:not(.header-mobile) .header__navbar .menu .simplebar-track.simplebar-vertical .simplebar-scrollbar {
            background: var(--cl-blue);
            border-radius: 0;
          }

            .header:not(.header-mobile) .header__navbar .menu .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
              content: none;
            }

        .header:not(.header-mobile) .header__navbar .menu__list {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          border-radius: 36px;
          overflow: hidden;
          background: var(--cl-navy);
        }

        .header:not(.header-mobile) .header__navbar .menu__item {
          display: flex;
          justify-content: stretch;
          align-items: stretch;
          width: 100%;
          background: transparent;
        }
            .header:not(.header-mobile) .header__navbar .menu__item.active .menu__link {
              background-color: #082655;
            }

        .header:not(.header-mobile) .header__navbar .menu__link {
          font-family: var(--font-main);
          font-size: 20px;
          line-height: normal;
          text-align: center;
          border-radius: 36px;
          min-height: 72px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: background-color .3s ease, color .3s ease;
          text-transform: capitalize;
        }
            .header:not(.header-mobile) .header__navbar .menu__link:hover:not(.active) {
              color: var(--cl-lilac);
            }

      .header:not(.header-mobile) .header__navbar .header__buttons {
        padding-left: 0;
      }

    .header:not(.header-mobile) .header__navbar-decor {
      display: flex;
      position: relative;
      overflow: hidden;
      /* fix for hover scale on image on Safari 15.1 */
      -webkit-mask-image: radial-gradient(white, black);
              mask-image: radial-gradient(white, black);
    }
      .header:not(.header-mobile) .header__navbar-decor::before {
		content: "";
		display: block;
		padding-bottom: max(min(93.94569513662141%, 9999px), 0px);
	}
      .header:not(.header-mobile) .header__navbar-decor img, .header:not(.header-mobile) .header__navbar-decor svg, .header:not(.header-mobile) .header__navbar-decor video, .header:not(.header-mobile) .header__navbar-decor iframe {
		width: 100%;
		height: 100%;
		position: absolute;
		-o-object-fit: cover;
		   object-fit: cover;
		top: 0;
		left: 0;
	}
      .header:not(.header-mobile) .header__navbar-decor[data-parallax-el] {
		overflow: visible;
		-webkit-mask-image: none;
		        mask-image: none;
	}

		.header:not(.header-mobile) .header__navbar-decor[data-parallax-el] img, .header:not(.header-mobile) .header__navbar-decor[data-parallax-el] svg {
			position: absolute !important;
		}
      .header:not(.header-mobile) .header__navbar-decor{
      position: absolute;
      top: -44px;
      right: 7px;
      width: 155.17%;
      mix-blend-mode: soft-light;
  }
  }

/* ----------------------- HEADER COMPONENTS ----------------------- */

.burger, .menu-toggle {
  background: var(--cl-white);
  position: relative;
  z-index: 100;
  -webkit-clip-path: inset(0 0 0 0 round 50%);
          clip-path: inset(0 0 0 0 round 50%);
  overflow: hidden;
  transition: background-color 0.3s ease;
  width: clamp(48px, calc(48px + 4.00 * ((100vw - 768px) / 672)), 52px);
  height: clamp(48px, calc(48px + 4.00 * ((100vw - 768px) / 672)), 52px);
  display: flex;
  justify-content: center;
  align-items: center;
}

/*&:before {
    z-index: 1;
    inset: 0;
    content: '';
    position: absolute;
    background: var(--gradient);
    transition: opacity 0.3s ease;
    opacity: 0;
  }*/

.burger .burger-icon, .menu-toggle .burger-icon {
    cursor: pointer;
    transition: transform 400ms, background-color 0.3s ease;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    width: 43px !important;
    height: 43px !important;
    position: relative;
    z-index: 2;
    transform: scaleX(1.2);
  }

/*&:before {
      opacity: 1;
    }*/

.burger.active .burger-icon, .menu-toggle.active .burger-icon {
      transform: rotate(45deg) scale(1.4);
    }

.burger.active .top, .menu-toggle.active .top {
      stroke-dashoffset: -68px;
    }

.burger.active .bottom, .menu-toggle.active .bottom {
      stroke-dashoffset: -68px;
    }

.burger.active .line, .menu-toggle.active .line {
      stroke: var(--cl-navy);
    }

.burger .line, .menu-toggle .line {
    fill: none;
    transition: stroke 0.3s ease, stroke-dasharray 400ms,
    stroke-dashoffset 400ms, opacity 0.3s ease;
    stroke: var(--cl-navy);
    stroke-width: 3.5;
    stroke-linecap: round;
  }

.burger .top, .menu-toggle .top {
    stroke-dasharray: 40 121;
  }

.burger .bottom, .menu-toggle .bottom {
    stroke-dasharray: 40 121;
  }

.burger:hover .line, .menu-toggle:hover .line {
      stroke: var(--cl-purple);
    }

.burger.active:hover .line, .menu-toggle.active:hover .line {
        stroke: var(--cl-purple);
      }

@media not all and (min-width: 360px) {

.burger, .menu-toggle{
    transform: scale(1.01);
  }
  }

.header-close-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 77;
  background: rgba(0, 0, 0, 0.4);
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
}

.header-close-wrapper.active {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
    cursor: pointer;
  }

/* end of HEADER COMPONENTS */

.footer {
  position: relative;
  background: linear-gradient(103.02deg, var(--cl-navy) -1.78%, var(--cl-purple) 119.69%);
  overflow: hidden;
}

@media (min-width: 1280px) {

.footer .cont-second {
      width: 92.00%;
      max-width: 1288px
  }
  }

.footer__wrap {
    padding-top: 227px;
    padding-bottom: clamp(37px, calc(40px + -3.00 * ((100vw - 768px) / 672)), 40px);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    z-index: 1;
    max-width: 1278px;
    margin-left: auto;
    margin-right: auto;
  }

.footer__bg-decor {
    position: relative;
    overflow: hidden;
    /* fix for hover scale on image on Safari 15.1 */
    -webkit-mask-image: radial-gradient(white, black);
            mask-image: radial-gradient(white, black);
  }

.footer__bg-decor::before {
		content: "";
		display: block;
		padding-bottom: max(min(85.30569789034249%, 9999px), 0px);
	}

.footer__bg-decor img, .footer__bg-decor svg, .footer__bg-decor video, .footer__bg-decor iframe {
		width: 100%;
		height: 100%;
		position: absolute;
		-o-object-fit: cover;
		   object-fit: cover;
		top: 0;
		left: 0;
	}

.footer__bg-decor[data-parallax-el] {
		overflow: visible;
		-webkit-mask-image: none;
		        mask-image: none;
	}

.footer__bg-decor[data-parallax-el] img, .footer__bg-decor[data-parallax-el] svg {
			position: absolute !important;
		}

.footer__bg-decor{
    position: absolute;
    width: clamp(1038px, calc(1038px + 394.00 * ((100vw - 768px) / 672)), 1432px);
    top: clamp(-102px, calc(189px + -291.00 * ((100vw - 768px) / 672)), 189px);
    right: clamp(-488px, calc(-488px + 526.00 * ((100vw - 768px) / 672)), 38px);
    z-index: -1;
}

.footer__container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

.footer__content {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

.footer__content:not(:last-child) {
      margin-bottom: clamp(23px, calc(23px + 19.00 * ((100vw - 768px) / 672)), 42px);
    }

.footer__logo {
    position: relative;
    overflow: hidden;
    /* fix for hover scale on image on Safari 15.1 */
    -webkit-mask-image: radial-gradient(white, black);
            mask-image: radial-gradient(white, black);
  }

.footer__logo::before {
		content: "";
		display: block;
		padding-bottom: max(min(23.04147465437788%, 9999px), 0px);
	}

.footer__logo img, .footer__logo svg, .footer__logo video, .footer__logo iframe {
		width: 100%;
		height: 100%;
		position: absolute;
		-o-object-fit: cover;
		   object-fit: cover;
		top: 0;
		left: 0;
	}

.footer__logo[data-parallax-el] {
		overflow: visible;
		-webkit-mask-image: none;
		        mask-image: none;
	}

.footer__logo[data-parallax-el] img, .footer__logo[data-parallax-el] svg {
			position: absolute !important;
		}

.footer__logo{
    width: 217px;
}

.footer__logo:not(:last-child) {
      margin-bottom: clamp(27px, calc(27px + 2.00 * ((100vw - 768px) / 672)), 29px);
    }

.footer__contacts {
    width: 54%;
    max-width: 634px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: clamp(8px, calc(24px + -16.00 * ((100vw - 768px) / 672)), 24px);
    margin-right: clamp(0px, calc(0px + 4.00 * ((100vw - 768px) / 672)), 4px);
  }

.footer__contact-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 38.49%;
  }

.footer__contact-item.with-location {
      width: 56.78%;
    }

.footer__contact-item .footer__link:not(:last-child) {
        margin-bottom: clamp(7px, calc(7px + 1.00 * ((100vw - 768px) / 672)), 8px);
      }

.footer__contact-title {
    font-family: var(--font-second);
    font-weight: 700;
    font-size: clamp(20px, calc(20px + 4.00 * ((100vw - 768px) / 672)), 24px);
    line-height: normal;
    color: var(--cl-white);
  }

.footer__contact-title:not(:last-child) {
      margin-bottom: clamp(8px, calc(8px + 9.00 * ((100vw - 768px) / 672)), 17px);
    }

.footer__link {
    position: relative;
    font-family: var(--font-main);
    font-size: clamp(16px, calc(16px + 2.00 * ((100vw - 768px) / 672)), 18px);
    font-weight: 400;
    line-height: normal;
    transition: color .3s ease;
    color: var(--cl-white);
  }

.footer__link:before {
      right: 0;
      left: auto;
      bottom: 0;
      width: 0;
      height: 1px;
      background: var(--cl-white);
      opacity: 0;
      transition: width .3s ease, opacity .3s ease;
      content: "";
      position: absolute;
    }

.footer__link:hover {
      color: var(--cl-turquoise);
    }

.footer__link:hover:before {
        right: auto;
        left: 0;
        width: 100%;
        opacity: 1;
        color: var(--cl-turquoise);
      }

.footer__link.with-location:before {
        content: none;
      }

.footer__socials:not(:last-child) {
      margin-bottom: 21px;
    }

.footer__nav {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: clamp(24px, calc(24px + 15.00 * ((100vw - 768px) / 672)), 39px);
    grid-row-gap: 16px;
    flex-wrap: wrap;
  }

@media not all and (min-width: 1200px) {

.footer__nav {
      grid-column-gap: 35px
  }
	}

@media not all and (min-width: 1020px) {

.footer__nav {
      justify-content: center
  }
	}

@media not all and (min-width: 640px) {

.footer__nav {
      grid-row-gap: 16px
  }
  }

@media not all and (min-width: 390px) {

.footer__nav {
      max-width: 290px;
      margin-left: auto;
      margin-right: auto
  }
	}

.footer__nav-link {
    font-family: var(--font-second);
    position: relative;
    z-index: 1;
    font-size: clamp(16px, calc(16px + 2.00 * ((100vw - 768px) / 672)), 18px);
    line-height: 18px;
    font-weight: 500;
    transition: color .3s ease;
    color: var(--cl-white);
  }

@media not all and (min-width: 640px) {
      .footer__nav-link i {
        display: none;
      }
  }

.footer__nav-link i {
      position: absolute;
      right: 0;
      opacity: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: opacity .1s ease, transform .3s ease, color .3s ease;
      font-size: 12px;
      z-index: -1;
      color: var(--cl-white);
    }

.footer__nav-link:hover {
      color: var(--cl-turquoise);
    }

.footer__nav-link:hover i {
        transition: opacity .3s ease, transform .3s ease, color .3s ease;
        transform: translateX(calc(100% + 3px)) translateY(-50%);
        opacity: 1;
        color: var(--cl-turquoise);
      }

.footer__bottom {
    border-top: 1px solid rgba(225, 232, 255, 0.3);
    padding-top: clamp(24px, calc(24px + 16.00 * ((100vw - 768px) / 672)), 40px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    grid-row-gap: 24px;
    grid-column-gap: clamp(16px, calc(16px + 4.00 * ((100vw - 768px) / 672)), 20px);
  }

.footer__bottom-info {
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
  }

.footer__developer {
    margin-top: clamp(22px, calc(22px + 2.00 * ((100vw - 768px) / 672)), 24px);
    width: 150px;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

@media not all and (min-width: 1050px) {
      .footer .footer__bottom {
        grid-column-gap: 20px;
      }

      .footer .footer__nav {

      }
	}

@media not all and (min-width: 951px) {
      .footer .footer__contacts {
        margin-top: 0;
        width: 60%;
      }
        .footer .footer__contact-item:not(:last-child) {
          margin-bottom: clamp(17px, calc(17px + 3.00 * ((100vw - 768px) / 672)), 20px);
        }
	}

@media not all and (min-width: 801px) {
      .footer .footer__contacts {
        flex-direction: column;
        padding-bottom: 24px;
        width: 50%;
      }

      .footer .footer__contact-item {
        width: 100%;
      }

      .footer .footer__contact-list {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
      }
	}

@media not all and (min-width: 768px) {
      .footer .footer__container {
        flex-direction: column;
        align-items: center;
      }

      .footer .footer__content {
        width: 100%;
        align-items: center;
      }

      .footer .footer__contacts {
        width: 100%;
        align-items: center;
      }

      .footer .footer__contact-item {
        align-items: center;
      }

      .footer .footer__link {
        text-align: center;
      }
        .footer .footer__link.with-location {
          width: 100%;
          max-width: 340px;
        }

      .footer .footer__bottom {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        grid-column-gap: 16px;
      }

      .footer .footer__nav {
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
      }

      .footer .footer__developer {
        margin-left: auto;
        margin-right: auto;
      }
  }

@media not all and (min-width: 640px) {
      .footer .footer__wrap {
        padding-top: 277px;
      }
  }

@media not all and (min-width: 551px) {
      .footer .footer__nav {
        max-width: 300px;
        grid-column-gap: 0;
      }

      .footer .footer__nav-link {
        margin-left: auto;
        margin-right: auto;
      }

        .footer .footer__nav-link:nth-child(1) {
          transform: translateX(-5px);
        }

        .footer .footer__nav-link:nth-child(2) {
          transform: translateX(5px);
        }

        .footer .footer__nav-link:nth-child(3) {
          transform: translateX(-10px);
        }
	}

@media (max-width: 370px) {
  .footer__nav-link{
    font-size: 14px;
  }
  .footer .footer__nav-link:nth-child(3){
    transform: none;
  }
}

.meta-select {
    --font-main: var(--font-main, 'sans-serif');
    --fz: clamp(16px, calc(18px + -2.00 * ((100vw - 768px) / 672)), 18px);
    --border-color: transparent;
    --line-height: 1.5;

    --list-border-color: var(--input-border-color);
    --text-color: var(--cl-blue);
    --hover-item-color: var(--cl-turquoise);
    --hover-item-bg: transparent;
    --font-weight: 500;

    --select-border-radius: 8px;
    --select-padding: 22px 43px 22px 23px;

    --selct-bg-color: var(--cl-white, #fff);

    --dropdown-bg-color: var(--cl-white, #fff);
    --dropdown-border-color: var(--input-border-color);
    --dropdown-item-padding: 22px 43px 22px 23px;
    --chevron-color: var(--cl-navy );

    --list-item-selected-color: var(--cl-navy);

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.meta-select.meta-select-sort{
        --select-padding: 12px 24px 12px 0;
        --dropdown-item-padding: 12px 24px 12px 16px;
        --text-color: var(--cl-blue);

        border: 1px solid var(--input-border-color);
        border-radius: 8px;
        background: var(--cl-white);
        padding-left: 16px;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;
        width: -moz-fit-content;
        width: fit-content;
        gap: 4px;
        position: relative;
    }

.meta-select.meta-select-sort .primary-label{
            flex-shrink: 0;
            opacity: 0.7;
            width: -moz-fit-content;
            width: fit-content;
            font-weight: 500;
        }

.meta-select.meta-select-sort .choices{
            position: static!important;
            height: auto;
        }

.meta-select.meta-select-sort .choices__inner{
               width: 214px;
               position: relative;
               min-height: 46px;
           }

.meta-select.meta-select-sort .choices__list--dropdown{
                width: 100%;
                left: 0!important;
            }

.meta-select.meta-select-sort .choices[data-type*="select-one"]:after{
                right: 16px!important;
            }

.meta-select label {
        font-family: var(--font-main);
        font-size: clamp(14px, calc(14px + 2.00 * ((100vw - 768px) / 672)), 16px);
        line-height: 1.2;
        font-weight: 700;
        color: var(--black);
        cursor: pointer;
        width: 100%;
    }

.meta-select select {
        opacity: 0;
        height: clamp(48px, calc(48px + 2.00 * ((100vw - 768px) / 672)), 50px);
    }

.meta-select .choices {
        width: 100%;
        margin-bottom: 0;
        overflow: visible;
        border-radius: 5px;

        height: 100%;
    }

.meta-select .choices__inner {

            height: 100%;
            border: 1px solid var(--border-color);
            padding: 0 !important;
            border-radius: var(--select-border-radius);
            outline: none;
            transition: box-shadow 0.3s ease, border-color 0.3s ease,
            border-radius 0.3s ease, caret-color 0.3s ease, color 0.3s ease;
            background: transparent;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            cursor: pointer;
            font-size: clamp(14px, calc(14px + 2.00 * ((100vw - 768px) / 672)), 16px);
            line-height: var(--line-height);
            font-weight: 500;
            font-family: var(--font-main);
            color: var(--black);
            box-shadow: none;
        }

.meta-select .choices__inner.error {
                border-color: var(--cl-red);
                box-shadow: 2px 2px 5px 0px rgba(var(--cl-red-rgb) / 0.3);
            }

.meta-select .choices__list--single{
            color: var(--text-color);
        }

.meta-select .choices.is-open:not(.is-flipped) .choices__inner {
                    border-radius: var(--select-border-radius);
                }

.meta-select .choices.is-open:not(.is-flipped) .choices__list--dropdown, .meta-select .choices.is-open:not(.is-flipped) .choices__list[aria-expanded] {
                    border-top: none;
                    margin-top: 0;
                    border-radius: var(--select-border-radius);
                }

.meta-select .choices.is-open.is-flipped .choices__inner {
                    border-radius: 0 0 6px 6px;
                }

.meta-select .choices.is-open.is-flipped .choices__list--dropdown, .meta-select .choices.is-open.is-flipped .choices__list[aria-expanded] {
                    margin-bottom: 0;
                    border-bottom: none;
                    border-radius: 6px 6px 0 0;
                    border-color: var(--dropdown-border-color);
                }

.meta-select .choices.is-open .choices__list--dropdown{
                border-color: var(--dropdown-border-color);
            }

.meta-select .choices__item {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

.meta-select .choices__placeholder {
            color: var(--navy);
            opacity: 1;
        }

.meta-select .choices__input {
            display: none !important;
        }

.meta-select .choices__list {
            padding: 0;
        }

.meta-select .choices__list .choices__item {
                font-size: var(--fz);
                font-family: var(--font-main);
                font-weight: var(--font-weight);
                line-height: 1.04;
                padding: var(--select-padding);
                transition: 0.3s color;
            }

.meta-select .choices__list .choices__item.is-selected {
                    color: var(--list-item-selected-color);
                }

.meta-select .choices__list--multiple {
                color: var(--navy);
            }

.meta-select .choices__list--multiple .choices__item--selectable {
                    display: none;
                }

.meta-select .choices__list--multiple + .choices__input {
                    display: none;
                }

.meta-select .choices__list--dropdown {
                border: 1px solid var(--dropdown-border-color);
                background-color: var(--dropdown-bg-color);
            }

.meta-select .choices__list--dropdown .choices__item {
                    color: var(--text-color);
                    border-bottom: 1px solid var(--list-border-color);
                    padding: var(--dropdown-item-padding);
                }

.meta-select .choices__list--dropdown .choices__item:first-of-type {
                        display: none;
                    }

.meta-select .choices__list--single {
                background: var(--selct-bg-color);
            }

.meta-select .choices[data-type*='select-one'], .meta-select .choices[data-type*='select-multiple'] {
            cursor: pointer;
        }

.meta-select .choices[data-type*='select-one']:after, .meta-select .choices[data-type*='select-multiple']:after {
                border: none;
                content: '';
                display: block;
                width: 14.4px;
                height: 8px;
                margin-top: -4px;
                pointer-events: none;
                position: absolute;
                right: 18px;
                top: 50%;
                transition: all 0.15s ease-in-out;
                background-image: url("data:image/svg+xml,%3Csvg width='15' height='8' viewBox='0 0 15 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.234315 0.234315C0.546734 -0.0781049 1.05327 -0.0781049 1.36569 0.234315L7.2 6.06863L13.0343 0.234315C13.3467 -0.0781049 13.8533 -0.0781049 14.1657 0.234315C14.4781 0.546734 14.4781 1.05327 14.1657 1.36569L7.76569 7.76569C7.45327 8.07811 6.94673 8.07811 6.63431 7.76569L0.234315 1.36569C-0.0781048 1.05327 -0.0781048 0.546734 0.234315 0.234315Z' fill='%23061344'/%3E%3C/svg%3E%0A");
            }

.meta-select .choices.is-open:after {
                transform: rotateX(180deg);
            }

.meta-select .choices__list--dropdown .choices__item--selectable.is-highlighted, .meta-select .choices__list[aria-expanded] .choices__item--selectable.is-highlighted {
            background-color: var(--hover-item-bg);
        }

.meta-select .choices__list--dropdown .choices__item--selectable.is-highlighted:not(.is-selected), .meta-select .choices__list[aria-expanded] .choices__item--selectable.is-highlighted:not(.is-selected) {
                color: var(--hover-item-color);
            }

.meta-select .choices__list--dropdown, .meta-select .choices .choices__list[aria-expanded] {

            z-index: 20;
            border-radius: 6px;

        }

.meta-select .choices[data-type*='select-one'] .choices__input {
            margin: 2px;
            max-width: calc(100% - 4px);
            border: 2px solid var(--cl-grey);
            border-radius: 8px;
            background: var(--transparent);
            color: var(--cl-dark-grey);
            font-size: clamp(14px, calc(14px + 2.00 * ((100vw - 768px) / 672)), 16px);
            line-height: 1.2;
            font-weight: 500;
            font-family: var(--font-main);
        }

.meta-select .choices[data-type*='select-multiple'] .choices__inner {
            cursor: pointer;
        }

.meta-select__list {
        display: none;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        max-width: 100%;
        grid-column-gap: 10px;
        grid-row-gap: 8px;
    }

.meta-select__list.is-visible {
            margin-top: 10px;
            display: flex;
        }

.meta-select__list-item {
        position: relative;
        font-size: clamp(14px, calc(14px + 2.00 * ((100vw - 768px) / 672)), 16px);
        line-height: 1.2;
        font-weight: 500;
        font-family: var(--font-main);
        padding-right: 14px;
        cursor: default;
        color: var(--navy);
    }

.meta-select__list-item:after {
            content: '';
            cursor: pointer;
            display: block;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: 10px;
            height: 10px;
            transition: background-color 0.25s ease, opacity 0.25s ease,
            transform 0.25s ease;
            opacity: 0.5;
            background: var(--navy);
            -webkit-clip-path: polygon(
                    20% 0%,
                    0% 20%,
                    30% 50%,
                    0% 80%,
                    20% 100%,
                    50% 70%,
                    80% 100%,
                    100% 80%,
                    70% 50%,
                    100% 20%,
                    80% 0%,
                    50% 30%
            );
                    clip-path: polygon(
                    20% 0%,
                    0% 20%,
                    30% 50%,
                    0% 80%,
                    20% 100%,
                    50% 70%,
                    80% 100%,
                    100% 80%,
                    70% 50%,
                    100% 20%,
                    80% 0%,
                    50% 30%
            );
        }

.meta-select__list-item:hover:after {
                opacity: 1;
                transform: translateY(-50%) scale(1.15);
            }

.meta-select__help, .meta-select__error {
        margin-top: 5px;
        font-family: var(--font-main);
        font-size: clamp(12px, calc(12px + 2.00 * ((100vw - 768px) / 672)), 14px);
        line-height: 1.2;
        font-weight: 600;
    }

.meta-select__help {
        color: var(--cl-dark-grey);
    }

.meta-select__error {
        color: var(--cl-red);
    }

@media (max-width: 650px) {
    .meta-select.meta-select-sort{
        width: 100%;
    }

        .meta-select.meta-select-sort .choices{
            flex: 1;
        }
}

/*enf of meta select*/

.job-card {
  border: 1px solid rgba(6, 19, 68, 0.2);
  border-radius: 16px;
  padding: clamp(23px, calc(23px + 6.00 * ((100vw - 768px) / 672)), 29px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: var(--cl-white);
  height: auto;
  transition: border-color .3s ease;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
  perspective: 1000px;
  transform-style: preserve-3d;
}

.job-card__list {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    transform: translateZ(0);
    transition: transform .4s ease;
  }

.job-card__list li {
      font-size: clamp(16px, calc(16px + 4.00 * ((100vw - 768px) / 672)), 20px);
      line-height: normal;
      font-weight: 700;
    }

.job-card__list li:not(:last-child) {
        margin-bottom: clamp(2px, calc(2px + 5.00 * ((100vw - 768px) / 672)), 7px);
      }

.job-card__list:not(:last-child) {
      margin-bottom: clamp(16px, calc(16px + 8.00 * ((100vw - 768px) / 672)), 24px);
    }

.job-card__title {
    font-size: clamp(24px, calc(24px + 6.00 * ((100vw - 768px) / 672)), 30px);
    line-height: normal;
    font-weight: 800;
    transition: color .3s ease, transform .4s ease;
    color: var(--cl-navy);
    transform: translateZ(0);
    padding: 0 !important;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

.job-card__title:not(:last-child) {
      margin-bottom: clamp(20px, calc(20px + 10.00 * ((100vw - 768px) / 672)), 30px);
    }

@media not all and (min-width: 640px) {

.job-card__title{
      line-height: 34px;
  }
  }

.job-card__btn {
    height: clamp(54px, calc(54px + 1.00 * ((100vw - 768px) / 672)), 55px);
    padding: 13px 31px;
    transform: translateZ(0);
    transition: transform .4s ease, background-color .3s ease;
  }

.job-card:hover {
    border-color: var(--cl-white);
  }

.job-card:hover .job-card__title {
      color: var(--cl-blue);
      transform: translateZ(20px);
    }

.job-card:hover .job-card__list {
      transform: translateZ(30px);
    }

.job-card:hover .job-card__btn {
      transform: translateZ(40px);
    }


.testimonial-card {
  position: relative;
  padding: clamp(31px, calc(31px + 29.00 * ((100vw - 768px) / 672)), 60px) clamp(24px, calc(24px + 16.00 * ((100vw - 768px) / 672)), 40px) clamp(24px, calc(24px + 16.00 * ((100vw - 768px) / 672)), 40px);
  border-radius: 20px;
  background: var(--cl-turquoise);
  transition: background-color .3s ease;
  overflow: hidden;
  cursor: default;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  height: auto;
}

.testimonial-card__decor {
    position: relative;
    overflow: hidden;
    /* fix for hover scale on image on Safari 15.1 */
    -webkit-mask-image: radial-gradient(white, black);
            mask-image: radial-gradient(white, black);
  }

.testimonial-card__decor::before {
		content: "";
		display: block;
		padding-bottom: max(min(93.88066499658392%, 9999px), 0px);
	}

.testimonial-card__decor img, .testimonial-card__decor svg, .testimonial-card__decor video, .testimonial-card__decor iframe {
		width: 100%;
		height: 100%;
		position: absolute;
		-o-object-fit: cover;
		   object-fit: cover;
		top: 0;
		left: 0;
	}

.testimonial-card__decor[data-parallax-el] {
		overflow: visible;
		-webkit-mask-image: none;
		        mask-image: none;
	}

.testimonial-card__decor[data-parallax-el] img, .testimonial-card__decor[data-parallax-el] svg {
			position: absolute !important;
		}

.testimonial-card__decor{
    position: absolute;
    width: 100%;
    min-width: 878px;
    left: clamp(-191px, calc(-180px + -11.00 * ((100vw - 768px) / 672)), -180px);
    top: 107px;
    opacity: .4;
    mix-blend-mode: overlay;
    transition: opacity .3s ease;
}

.testimonial-card__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 1;
    width: 100%;
  }

.testimonial-card__text {
    width: calc(100% + 10px);
    padding-right: 10px;
    max-height: clamp(202px, calc(202px + 40.00 * ((100vw - 768px) / 672)), 242px);
  }

.testimonial-card__text p, .testimonial-card__text li {
      color: var(--cl-navy);
      font-size: clamp(16px, calc(16px + 4.00 * ((100vw - 768px) / 672)), 20px);
      font-weight: 400;
      line-height: normal;
      padding-bottom: 1px;
    }

@media not all and (min-width: 640px) {

.testimonial-card__text p, .testimonial-card__text li {
        line-height: 1.375
    }
  }

.testimonial-card__text .simplebar-track.simplebar-vertical {
      background: var(--cl-lilac);
      transition: all .3s ease;
    }

.testimonial-card__text .simplebar-track.simplebar-vertical .simplebar-scrollbar {
        background: var(--cl-navy);
      }

.testimonial-card__text:not(:last-child) {
      margin-bottom: clamp(24px, calc(24px + 26.00 * ((100vw - 768px) / 672)), 50px);
    }

.testimonial-card__author-content {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    grid-column-gap: clamp(16px, calc(16px + 4.00 * ((100vw - 768px) / 672)), 20px);
  }

.testimonial-card__author-image {
    position: relative;
    overflow: hidden;
    /* fix for hover scale on image on Safari 15.1 */
    -webkit-mask-image: radial-gradient(white, black);
            mask-image: radial-gradient(white, black);
  }

.testimonial-card__author-image::before {
		content: "";
		display: block;
		padding-bottom: max(min(100%, 9999px), 0px);
	}

.testimonial-card__author-image img, .testimonial-card__author-image svg, .testimonial-card__author-image video, .testimonial-card__author-image iframe {
		width: 100%;
		height: 100%;
		position: absolute;
		-o-object-fit: cover;
		   object-fit: cover;
		top: 0;
		left: 0;
	}

.testimonial-card__author-image[data-parallax-el] {
		overflow: visible;
		-webkit-mask-image: none;
		        mask-image: none;
	}

.testimonial-card__author-image[data-parallax-el] img, .testimonial-card__author-image[data-parallax-el] svg {
			position: absolute !important;
		}

.testimonial-card__author-image{
    border-radius: 50%;
    overflow: hidden;
    width: clamp(72px, calc(72px + 34.00 * ((100vw - 768px) / 672)), 106px);
    min-width: clamp(72px, calc(72px + 34.00 * ((100vw - 768px) / 672)), 106px);
}

.testimonial-card__author-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }

.testimonial-card__author-title {
    font-family: var(--font-main);
    font-size: clamp(18px, calc(18px + 16.00 * ((100vw - 768px) / 672)), 34px);
    font-weight: 800;
    line-height: normal;
    color: var(--cl-navy) !important;
  }

.testimonial-card__author-subtitle {
    font-family: var(--font-main);
    font-size: clamp(16px, calc(16px + 2.00 * ((100vw - 768px) / 672)), 18px);
    font-weight: 400;
    line-height: normal;
    color: var(--cl-navy) !important;
  }

.testimonial-card:hover {
    background-color: var(--cl-lilac);
  }

.testimonial-card:hover .testimonial-card__text .simplebar-track.simplebar-vertical {
        background-color: var(--cl-turquoise);
      }

.first-section {
  position: relative;
  z-index: 10;
  overflow: hidden;
  background: #000;
}

.first-section__bg {
    position: absolute;
    inset: 0;
    /*left: 50%;
    transform: translateX(-55%);*/
    z-index: -1;
  }

.first-section__bg img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
    }

@media (min-width: 640px) {

.first-section__bg--mob {
        display: none
    }
  }

@media not all and (min-width: 640px) {

.first-section__bg--desk {
        display: none
    }
  }

@media not all and (min-width: 640px) {

.first-section__bg{
      left: 0;
      right: 0;
      min-width: 100%;
      transform: translateX(0);
  }
  }

.first-section__wrap {
    min-height: 700px;
    height: calc(var(--vh, 1vh) * 100);
    padding-top: 140px;
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

@media not all and (min-width: 551px) {

.first-section__wrap {
      justify-content: flex-end;
      padding-bottom: 40px;
      min-height: 600px
  }
	}

.first-section__title-container {
    width: 100%;
    position: relative;
  }

.first-section__title-container:not(:last-child) {
      margin-bottom: clamp(30px, calc(32px + -2.00 * ((100vw - 768px) / 672)), 32px);
    }

@media not all and (min-width: 551px) {

.first-section__title-container{
      order: 1;
  }
	}

.first-section__title {
    width: 100%;
    mix-blend-mode: overlay;
  }

.first-section__title h1 {
      color: var(--cl-white);
    }

.first-section__title--duplicate {
      font-size: clamp(42px, calc(42px + 98.00 * ((100vw - 768px) / 672)), 140px);
      font-weight: 800;
      line-height: normal;
      font-family: var(--font-second);
      color: var(--cl-white);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }

@media (min-width: 1280px) {

.first-section__title--duplicate {
        opacity: 0.1 !important
    }
  }

@media not all and (min-width: 374px) {

.first-section__title--duplicate {
        font-size: 40px
    }
	}

@media not all and (min-width: 330px) {

.first-section__title--duplicate {
        font-size: 38px
    }
	}

@media not all and (min-width: 360px) {
        .first-section__title .first-section__title {
          mix-blend-mode: soft-light;
        }

          .first-section__title .first-section__title--duplicate {
            mix-blend-mode: overlay;
          }
  }

@media not all and (min-width: 640px) {
        .first-section__text.small-size p, .first-section__text.small-size li {
          line-height: 24px;
        }
  }

@media not all and (min-width: 551px) {

.first-section__buttons {
		width: 100%;
  }

		.first-section__buttons .btn {
			width: 100%;
		}

			.first-section__buttons .btn__text {
				text-align: left;
				justify-content: flex-start;
			}
	}

.first-section__buttons:not(:last-child) {
      margin-bottom: clamp(32px, calc(32px + 19.00 * ((100vw - 768px) / 672)), 51px);
    }

@media not all and (min-width: 551px) {

.first-section__buttons{
      order: 3;
      width: 100%;
  }

      .first-section__buttons:not(:last-child) {
        margin-bottom: 0;
      }
	}

.first-section__content {
    width: 100%;
    max-width: clamp(400px, calc(400px + 122.00 * ((100vw - 768px) / 672)), 522px);
  }

@media not all and (min-width: 551px) {

.first-section__content {
      max-width: 100%;
      order: 2;
      margin-bottom: clamp(32px, calc(32px + 8.00 * ((100vw - 768px) / 672)), 40px)
  }
	}

.first-section__subtitle {
    font-family: var(--font-second);
    /*font-style: italic;*/
    font-weight: 600;
    font-size: clamp(24px, calc(24px + 10.00 * ((100vw - 768px) / 672)), 34px);
    line-height: clamp(25px, calc(25px + 10.00 * ((100vw - 768px) / 672)), 35px);
    margin-left: auto;
    text-align: right;
  }

.first-section__subtitle:not(:last-child) {
      margin-bottom: clamp(16px, calc(16px + 4.00 * ((100vw - 768px) / 672)), 20px);
    }

@media not all and (min-width: 551px) {

.first-section__subtitle{
      text-align: left;
      margin-left: 0;
  }
	}

.first-section__cubes {
    position: relative;
    /*z-index: -1;*/

/*    @mixin max-sm {
      opacity: .7;
    }*/
  }

.first-section__cubes::before {
		content: "";
		display: block;
		padding-bottom: max(min(93.96113989637306%, 9999px), 0px);
	}

.first-section__cubes{
    position: absolute;
    right: clamp(8px, calc(8px + 13.00 * ((100vw - 768px) / 672)), 21px);
    bottom: clamp(-21px, calc(274px + -295.00 * ((100vw - 768px) / 672)), 274px);
    width: 60.22%;
    min-width: 581px;
    max-width: 772px;
    mix-blend-mode: soft-light;
}

.first-section__cube:nth-child(1) {
      position: relative;
      overflow: hidden;
      /* fix for hover scale on image on Safari 15.1 */
      -webkit-mask-image: radial-gradient(white, black);
              mask-image: radial-gradient(white, black);
    }

.first-section__cube:nth-child(1)::before {
		content: "";
		display: block;
		padding-bottom: max(min(105.2588438308887%, 9999px), 0px);
	}

.first-section__cube:nth-child(1) img, .first-section__cube:nth-child(1) svg, .first-section__cube:nth-child(1) video, .first-section__cube:nth-child(1) iframe {
		width: 100%;
		height: 100%;
		position: absolute;
		-o-object-fit: cover;
		   object-fit: cover;
		top: 0;
		left: 0;
	}

.first-section__cube:nth-child(1)[data-parallax-el] {
		overflow: visible;
		-webkit-mask-image: none;
		        mask-image: none;
	}

.first-section__cube:nth-child(1)[data-parallax-el] img, .first-section__cube:nth-child(1)[data-parallax-el] svg {
			position: absolute !important;
		}

.first-section__cube:nth-child(1){
      width: 60.05%;
      position: absolute;
      top: 0;
      left: 0;
}

.first-section__cube:nth-child(2) {
      position: relative;
      overflow: hidden;
      /* fix for hover scale on image on Safari 15.1 */
      -webkit-mask-image: radial-gradient(white, black);
              mask-image: radial-gradient(white, black);
    }

.first-section__cube:nth-child(2)::before {
		content: "";
		display: block;
		padding-bottom: max(min(112.17662442164554%, 9999px), 0px);
	}

.first-section__cube:nth-child(2) img, .first-section__cube:nth-child(2) svg, .first-section__cube:nth-child(2) video, .first-section__cube:nth-child(2) iframe {
		width: 100%;
		height: 100%;
		position: absolute;
		-o-object-fit: cover;
		   object-fit: cover;
		top: 0;
		left: 0;
	}

.first-section__cube:nth-child(2)[data-parallax-el] {
		overflow: visible;
		-webkit-mask-image: none;
		        mask-image: none;
	}

.first-section__cube:nth-child(2)[data-parallax-el] img, .first-section__cube:nth-child(2)[data-parallax-el] svg {
			position: absolute !important;
		}

.first-section__cube:nth-child(2){
      width: 64.39%;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
}

.bringing-section {
  padding-top: clamp(128px, calc(128px + 135.00 * ((100vw - 768px) / 672)), 263px);
  padding-bottom: clamp(0px, calc(0px + 71.00 * ((100vw - 768px) / 672)), 71px);
  position: relative;
  z-index: 2;
  max-width: 1440px;
  margin: clamp(-262px, calc(-128px + -134.00 * ((100vw - 768px) / 672)), -128px) auto clamp(-71px, calc(0px + -71.00 * ((100vw - 768px) / 672)), 0px);
}

.bringing-section__bg {
    position: relative;
    overflow: hidden;
    /* fix for hover scale on image on Safari 15.1 */
    -webkit-mask-image: radial-gradient(white, black);
            mask-image: radial-gradient(white, black);
  }

.bringing-section__bg::before {
		content: "";
		display: block;
		padding-bottom: max(min(89.68511766655618%, 9999px), 0px);
	}

.bringing-section__bg img, .bringing-section__bg svg, .bringing-section__bg video, .bringing-section__bg iframe {
		width: 100%;
		height: 100%;
		position: absolute;
		-o-object-fit: cover;
		   object-fit: cover;
		top: 0;
		left: 0;
	}

.bringing-section__bg[data-parallax-el] {
		overflow: visible;
		-webkit-mask-image: none;
		        mask-image: none;
	}

.bringing-section__bg[data-parallax-el] img, .bringing-section__bg[data-parallax-el] svg {
			position: absolute !important;
		}

.bringing-section__bg{
    position: absolute;
    width: 104.76%;
    min-width: 712px;
    top: 0;
    left: clamp(-84px, calc(-84px + 82.00 * ((100vw - 768px) / 672)), -2px);
    z-index: 1;
}

.bringing-section__wrap {
    padding-top: clamp(56px, calc(56px + 91.00 * ((100vw - 768px) / 672)), 147px);
    padding-bottom: clamp(64px, calc(64px + 71.00 * ((100vw - 768px) / 672)), 135px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 2;
  }

.bringing-section__title {
    width: 100%;
    max-width: clamp(340px, calc(340px + 818.00 * ((100vw - 768px) / 672)), 1158px);
  }

.bringing-section__title h2 {
      font-size: clamp(42px, calc(42px + 88.00 * ((100vw - 768px) / 672)), 130px);
      color: var(--cl-blue);

      /*br {
        + span {
          margin-left: ac(72px, 32px);
        }
      }*/
    }

.bringing-section__title h2 span:first-child {
        margin-left: clamp(76px, calc(76px + 162.00 * ((100vw - 768px) / 672)), 238px);
      }

@media (min-width: 1280px) {

.bringing-section__title{
      opacity: .8 !important;
  }
  }

@media not all and (min-width: 1100px) {

.bringing-section__title{
      max-width: clamp(350px, calc(350px + 850.00 * ((100vw - 768px) / 672)), 1200px);
  }
	}

@media not all and (min-width: 768px) {

.bringing-section__title{
      max-width: 340px;
  }
  }

.bringing-section__title:not(:last-child) {
      margin-bottom: clamp(29px, calc(32px + -3.00 * ((100vw - 768px) / 672)), 32px);
    }

.bringing-section__content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

.bringing-section__text {
    width: 49.14%;
  }

.bringing-section__text p, .bringing-section__text li {
      font-weight: 300;
    }

@media not all and (min-width: 1025px) {

.bringing-section__text{
      width: 52%;
  }
	}

.bringing-section__buttons {
    width: 44%;
    justify-content: flex-end;
    padding-right: clamp(0px, calc(0px + 111.00 * ((100vw - 768px) / 672)), 111px);
  }

@media not all and (min-width: 551px) {

.bringing-section__buttons {
		width: 100%;
  }

		.bringing-section__buttons .btn {
			width: 100%;
		}

			.bringing-section__buttons .btn__text {
				text-align: left;
				justify-content: flex-start;
			}
	}

.bringing-section__cubes {
    position: relative;
    /*mix-blend-mode: soft-light;*/
  }

.bringing-section__cubes::before {
		content: "";
		display: block;
		padding-bottom: max(min(93.89903329752954%, 9999px), 0px);
	}

.bringing-section__cubes{
    position: absolute;
    bottom: clamp(-30px, calc(140px + -170.00 * ((100vw - 768px) / 672)), 140px);
    right: clamp(-285px, calc(-285px + 125.00 * ((100vw - 768px) / 672)), -160px);
    width: 91.97%;
    max-width: 1024px;
    min-width: 483px;
    z-index: -1;
    opacity: .4;
}

.bringing-section__cube:nth-child(1) {
      position: relative;
      overflow: hidden;
      /* fix for hover scale on image on Safari 15.1 */
      -webkit-mask-image: radial-gradient(white, black);
              mask-image: radial-gradient(white, black);
    }

.bringing-section__cube:nth-child(1)::before {
		content: "";
		display: block;
		padding-bottom: max(min(105.13482595195295%, 9999px), 0px);
	}

.bringing-section__cube:nth-child(1) img, .bringing-section__cube:nth-child(1) svg, .bringing-section__cube:nth-child(1) video, .bringing-section__cube:nth-child(1) iframe {
		width: 100%;
		height: 100%;
		position: absolute;
		-o-object-fit: cover;
		   object-fit: cover;
		top: 0;
		left: 0;
	}

.bringing-section__cube:nth-child(1)[data-parallax-el] {
		overflow: visible;
		-webkit-mask-image: none;
		        mask-image: none;
	}

.bringing-section__cube:nth-child(1)[data-parallax-el] img, .bringing-section__cube:nth-child(1)[data-parallax-el] svg {
			position: absolute !important;
		}

.bringing-section__cube:nth-child(1){
      width: 59.75%;
      position: absolute;
      top: 0;
      left: 0;
}

.bringing-section__cube:nth-child(2) {
      position: relative;
      overflow: hidden;
      /* fix for hover scale on image on Safari 15.1 */
      -webkit-mask-image: radial-gradient(white, black);
              mask-image: radial-gradient(white, black);
    }

.bringing-section__cube:nth-child(2)::before {
		content: "";
		display: block;
		padding-bottom: max(min(112.19063545150503%, 9999px), 0px);
	}

.bringing-section__cube:nth-child(2) img, .bringing-section__cube:nth-child(2) svg, .bringing-section__cube:nth-child(2) video, .bringing-section__cube:nth-child(2) iframe {
		width: 100%;
		height: 100%;
		position: absolute;
		-o-object-fit: cover;
		   object-fit: cover;
		top: 0;
		left: 0;
	}

.bringing-section__cube:nth-child(2)[data-parallax-el] {
		overflow: visible;
		-webkit-mask-image: none;
		        mask-image: none;
	}

.bringing-section__cube:nth-child(2)[data-parallax-el] img, .bringing-section__cube:nth-child(2)[data-parallax-el] svg {
			position: absolute !important;
		}

.bringing-section__cube:nth-child(2){
      width: 64.23%;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
}

@media not all and (min-width: 640px) {
      .bringing-section .bringing-section__content {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      .bringing-section .bringing-section__text {
        width: 100%;
        margin-bottom: 32px;
      }

      .bringing-section .bringing-section__buttons {
        width: 100%;
      }
  }

.services-section {
  position: relative;
  z-index: 10;
  overflow: hidden;
}

.services-section .cont {
    max-width: 1400px;
  }

.services-section__bg {
    overflow: hidden;
    border-radius: 10px;
    position: relative;
  }

.services-section__bg-image {
    position: relative;
    overflow: hidden;
    /* fix for hover scale on image on Safari 15.1 */
    -webkit-mask-image: radial-gradient(white, black);
            mask-image: radial-gradient(white, black);

  }

.services-section__bg-image::before {
		content: "";
		display: block;
		padding-bottom: max(min(60.82255782088815%, 9999px), 0px);
	}

.services-section__bg-image img, .services-section__bg-image svg, .services-section__bg-image video, .services-section__bg-image iframe {
		width: 100%;
		height: 100%;
		position: absolute;
		-o-object-fit: cover;
		   object-fit: cover;
		top: 0;
		left: 0;
	}

.services-section__bg-image[data-parallax-el] {
		overflow: visible;
		-webkit-mask-image: none;
		        mask-image: none;
	}

.services-section__bg-image[data-parallax-el] img, .services-section__bg-image[data-parallax-el] svg {
			position: absolute !important;
		}

.services-section__bg-image{
    position: absolute;
    width: clamp(2672px, calc(3069px + -397.00 * ((100vw - 768px) / 672)), 3069px);
    top: clamp(-7px, calc(0px + -7.00 * ((100vw - 768px) / 672)), 0px);
    transform: translateX(-50%);
    z-index: -2;
    left: 44%;
}

.services-section__bg-decor {
    position: relative;
    overflow: hidden;
    /* fix for hover scale on image on Safari 15.1 */
    -webkit-mask-image: radial-gradient(white, black);
            mask-image: radial-gradient(white, black);
  }

.services-section__bg-decor::before {
		content: "";
		display: block;
		padding-bottom: max(min(114.00783124921057%, 9999px), 0px);
	}

.services-section__bg-decor img, .services-section__bg-decor svg, .services-section__bg-decor video, .services-section__bg-decor iframe {
		width: 100%;
		height: 100%;
		position: absolute;
		-o-object-fit: cover;
		   object-fit: cover;
		top: 0;
		left: 0;
	}

.services-section__bg-decor[data-parallax-el] {
		overflow: visible;
		-webkit-mask-image: none;
		        mask-image: none;
	}

.services-section__bg-decor[data-parallax-el] img, .services-section__bg-decor[data-parallax-el] svg {
			position: absolute !important;
		}

.services-section__bg-decor{
    width: clamp(486px, calc(486px + 939.00 * ((100vw - 768px) / 672)), 1425px);
    position: absolute;
    top: clamp(-258px, calc(-106px + -152.00 * ((100vw - 768px) / 672)), -106px);
    right: clamp(-919px, calc(-425px + -494.00 * ((100vw - 768px) / 672)), -425px);
    mix-blend-mode: soft-light;
    z-index: -1;
}

.services-section__wrap {
    padding-top: clamp(38px, calc(38px + 82.00 * ((100vw - 768px) / 672)), 120px);
    padding-bottom: clamp(40px, calc(40px + 78.00 * ((100vw - 768px) / 672)), 118px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

.services-section__content {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

.services-section__content:not(:last-child) {
      margin-bottom: clamp(32px, calc(32px + 37.00 * ((100vw - 768px) / 672)), 69px);
    }

.services-section__title-container {
    width: 78.29%;
    position: relative;
    padding-left: clamp(200px, calc(200px + 214.00 * ((100vw - 768px) / 672)), 414px);
    padding-bottom: clamp(80px, calc(80px + 31.00 * ((100vw - 768px) / 672)), 111px);
  }

.services-section__title {
    position: absolute;
    left: 0;
    bottom: clamp(-3px, calc(0px + -3.00 * ((100vw - 768px) / 672)), 0px);
    mix-blend-mode: overlay;

    /*&:not(:last-child) {
      margin-bottom: ac(27px, 32px);
    }*/
  }

.services-section__title h2 {
      color: var(--cl-white);
      line-height: 0.94;
    }

.services-section__title h2 span{
        line-height: inherit;
      }

.services-section__title h2 span:not(:first-of-type){
        /*  transform: translateY(-6%);*/
        }

.services-section__title h2 span:nth-of-type(2) {
          margin-left: clamp(28px, calc(28px + 38.00 * ((100vw - 768px) / 672)), 66px);
        }

.services-section__text {
    width: 100%;
  }

.services-section__buttons {
    justify-content: flex-end;
    padding-bottom: clamp(16px, calc(20px + -4.00 * ((100vw - 768px) / 672)), 20px);
    padding-right: clamp(0px, calc(0px + 16.00 * ((100vw - 768px) / 672)), 16px);
  }

@media not all and (min-width: 551px) {

.services-section__buttons {
		width: 100%;
  }

		.services-section__buttons .btn {
			width: 100%;
		}

			.services-section__buttons .btn__text {
				text-align: left;
				justify-content: flex-start;
			}
	}

.services-section__list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: clamp(12px, calc(12px + 8.00 * ((100vw - 768px) / 672)), 20px);
    grid-row-gap: clamp(12px, calc(12px + 8.00 * ((100vw - 768px) / 672)), 20px);
  }

@media not all and (min-width: 901px) {
      .services-section .services-section__title-container {
        padding-left: 0;
        padding-bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      .services-section .services-section__title {
        position: relative;
        left: auto;
        bottom: auto;
        margin-bottom: clamp(16px, calc(16px + 8.00 * ((100vw - 768px) / 672)), 24px);
      }
	}

@media not all and (min-width: 851px) {
      .services-section .services-section__wrap {
        padding-left: 12px;
        padding-right: 12px;
      }

      .services-section .services-section__content {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      .services-section .services-section__title-container {
        margin-bottom: 32px;
        width: 100%;
      }

      .services-section .services-section__buttons {
        width: 100%;
        justify-content: flex-start;
      }

      .services-section .services-section__list {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
	}

@media not all and (min-width: 551px) {
      .services-section .services-section__list {
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }

      .services-section .services-section__buttons {
        padding-bottom: 0;
      }

      .services-section .services-section__bg-decor {
        opacity: .8;
      }
	}

@media not all and (min-width: 360px) {
      .services-section .services-section__wrap {
        padding-left: 6px;
        padding-right: 6px;
      }
  }

@media not all and (min-width: 551px) {

.services-section + .testimonials-section {
      margin-top: -701px;
      padding-top: 701px
  }
	}

@media (max-width: 374px){
    .services-section .services-section__title h2{
      font-size: 30px;
    }
    }

.stats-section {
  margin-bottom: -40px;
  position: relative;
  z-index: 10;
  overflow: hidden;
}

.stats-section .cont {
    max-width: 1400px;
  }

.stats-section__bg {
    overflow: hidden;
    border-radius: 10px;
    position: relative;
  }

.stats-section__bg-image {
    position: absolute;
    inset: 0;
    z-index: -2;
  }

@media (min-width: 640px) {

.stats-section__bg-image--mob {
        display: none;
    }
  }

.stats-section__bg-image--mob img {
        -o-object-position: top;
           object-position: top;
        min-height: 748px;
      }

@media not all and (min-width: 640px) {

.stats-section__bg-image--desk {
        display: none
    }
  }

.stats-section__bg-image img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
    }

.stats-section__bg-decor {
    position: relative;
    overflow: hidden;
    /* fix for hover scale on image on Safari 15.1 */
    -webkit-mask-image: radial-gradient(white, black);
            mask-image: radial-gradient(white, black);
  }

.stats-section__bg-decor::before {
		content: "";
		display: block;
		padding-bottom: max(min(100%, 9999px), 0px);
	}

.stats-section__bg-decor img, .stats-section__bg-decor svg, .stats-section__bg-decor video, .stats-section__bg-decor iframe {
		width: 100%;
		height: 100%;
		position: absolute;
		-o-object-fit: cover;
		   object-fit: cover;
		top: 0;
		left: 0;
	}

.stats-section__bg-decor[data-parallax-el] {
		overflow: visible;
		-webkit-mask-image: none;
		        mask-image: none;
	}

.stats-section__bg-decor[data-parallax-el] img, .stats-section__bg-decor[data-parallax-el] svg {
			position: absolute !important;
		}

.stats-section__bg-decor{
    width: clamp(514px, calc(514px + 426.00 * ((100vw - 768px) / 672)), 940px);
    position: absolute;
    bottom: clamp(-503px, calc(-264px + -239.00 * ((100vw - 768px) / 672)), -264px);
    left: clamp(-249px, calc(-249px + 3.00 * ((100vw - 768px) / 672)), -246px);
    mix-blend-mode: soft-light;
    opacity: .6;
    z-index: -1;
}

.stats-section__wrap {
    padding-top: clamp(40px, calc(40px + 20.00 * ((100vw - 768px) / 672)), 60px);
    padding-bottom: clamp(40px, calc(40px + 58.00 * ((100vw - 768px) / 672)), 98px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 1280px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

  }

.stats-section__content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

.stats-section__content:not(:last-child) {
      margin-bottom: clamp(30px, calc(30px + 90.00 * ((100vw - 768px) / 672)), 120px);
    }

.stats-section__text {
    margin-top: clamp(0px, calc(0px + 20.00 * ((100vw - 768px) / 672)), 20px);
    width: 40.78%;
  }

.stats-section__text.small-size p, .stats-section__text.small-size li {
        font-size: clamp(16px, calc(18px + -2.00 * ((100vw - 768px) / 672)), 18px);
        line-height: 1.5;
      }

@media not all and (min-width: 640px) {

.stats-section__text.small-size p, .stats-section__text.small-size li {
          line-height: 24px
      }
  }

.stats-section__title {
    width: 57.11%;
    mix-blend-mode: overlay;
    margin-left: auto;

    /*&:not(:last-child) {
      margin-bottom: ac(27px, 32px);
    }*/
  }

.stats-section__title h2 {
      color: var(--cl-white);
    }

.stats-section__title h2 span:first-child {
          margin-left: clamp(26px, calc(26px + 50.00 * ((100vw - 768px) / 672)), 76px);
        }

.stats-section__list {
    width: 100%;
    max-width: 1221px;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-column-gap: clamp(16px, calc(16px + 64.00 * ((100vw - 768px) / 672)), 80px);
    grid-row-gap: clamp(16px, calc(16px + 24.00 * ((100vw - 768px) / 672)), 40px);
  }

/*&__slider {
    overflow: visible;
    margin-left: 0;
    width: 100%;
    max-width: 1221px;

    &:not(.swiper-initialized) {
      display: none;
    }
  }*/

.stats-section__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

/*&__item-count-container {
    mix-blend-mode: overlay;
  }*/

.stats-section__item-count {
    position: relative;
    font-size: clamp(40px, calc(40px + 40.00 * ((100vw - 768px) / 672)), 80px);
    font-weight: 700;
    line-height: clamp(48px, calc(48px + 36.00 * ((100vw - 768px) / 672)), 84px);
    font-family: var(--font-second);
    color: var(--cl-white);
    mix-blend-mode: overlay;
  }

.stats-section__item-count:not(:last-child) {
      margin-bottom: clamp(15px, calc(15px + 11.00 * ((100vw - 768px) / 672)), 26px);
    }

.stats-section__item-title {
    font-family: var(--font-main);
    font-weight: 400;
    font-size: clamp(24px, calc(24px + 6.00 * ((100vw - 768px) / 672)), 30px);
    line-height: normal;
  }

.stats-section__item-title:not(:last-child) {
      margin-bottom: clamp(6px, calc(6px + 9.00 * ((100vw - 768px) / 672)), 15px);
    }

@media not all and (min-width: 640px) {

.stats-section__item-title{
      font-family: var(--font-second);
      font-weight: 500;
      line-height: 28px;
  }
  }

@media not all and (min-width: 901px) {
      .stats-section .stats-section__content {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      .stats-section .stats-section__text {
        width: 100%;
        margin-bottom: 32px;
      }

      .stats-section .stats-section__title {
        width: 100%;
        max-width: clamp(264px, calc(264px + 476.00 * ((100vw - 768px) / 672)), 740px);
        margin-left: 0;
      }

      .stats-section .stats-section__list {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
	}

@media not all and (min-width: 640px) {
      .stats-section .stats-section__wrap {
        padding-left: 12px;
        padding-right: 12px;
      }
  }

@media not all and (min-width: 551px) {
      .stats-section .stats-section__title {
        margin-left: auto;
      }

      .stats-section .stats-section__list {
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }

      .stats-section .stats-section__item {
        flex-direction: row;
        grid-column-gap: 23px;
      }

      .stats-section .stats-section__item-count {
        min-width: 100px;
        margin-bottom: 0;
      }
	}

@media not all and (min-width: 360px) {
      .stats-section .stats-section__wrap {
        padding-left: 10px;
        padding-right: 10px;
      }

      .stats-section .stats-section__item-title {
        font-size: 22px;
      }
      .stats-section .stats-section__item-count{
        font-size: 30px;
        min-width: 80px;
      }
  }

.stats-section + .roles-section{
    --mt:clamp(96px, calc(96px + 236.00 * ((100vw - 768px) / 672)), 332px);
    padding-top: var(--mt);
    margin-top: calc(var(--mt) * -1);
  }

.stats-section + .roles-section .cont{
      padding-top: clamp(96px, calc(96px + 105.00 * ((100vw - 768px) / 672)), 201px);
    }

.text-section {
  position: relative;
  z-index: 10;
}

.text-section__wrap {
    display: flex;
  }

.text-section__title h2 br + span {
          margin-left: clamp(32px, calc(32px + 40.00 * ((100vw - 768px) / 672)), 72px);
        }

.text-section__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

.text-section__buttons {

  }

.text-section.home-page {
    margin-top: clamp(40px, calc(40px + 10.00 * ((100vw - 768px) / 672)), 50px);
    padding-top: clamp(40px, calc(40px + 40.00 * ((100vw - 768px) / 672)), 80px);
    padding-bottom: clamp(16px, calc(16px + 20.00 * ((100vw - 768px) / 672)), 36px);
  }

@media not all and (min-width: 640px) {

.text-section.home-page {
      margin-top: 0;
  }
  }

.text-section.home-page .text-section__wrap {
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        grid-row-gap: clamp(30px, calc(32px + -2.00 * ((100vw - 768px) / 672)), 32px);
      }

.text-section.home-page .text-section__title-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

.text-section.home-page .text-section__title {
        width: 100%;
        max-width: clamp(310px, calc(310px + 410.00 * ((100vw - 768px) / 672)), 720px);
        text-align: left;
      }

.text-section.home-page .text-section__text {
        margin-top: clamp(0px, calc(8px + -8.00 * ((100vw - 768px) / 672)), 8px);
        width: 48.79%;
      }

@media not all and (min-width: 1024px) {

.text-section.home-page .text-section__text {
          width: 52%
      }
  }

@media not all and (min-width: 768px) {

.text-section.home-page .text-section__text {
          width: 100%
      }
  }

.text-section.home-page .text-section__buttons {
        padding-top: clamp(0px, calc(0px + 30.00 * ((100vw - 768px) / 672)), 30px);
        width: 47.99%;
      }

@media not all and (min-width: 1024px) {

.text-section.home-page .text-section__buttons {
          width: 46%
      }
  }

@media not all and (min-width: 768px) {

.text-section.home-page .text-section__buttons {
          width: 100%
      }
  }

.logos-section {
  padding-top: clamp(48px, calc(48px + 72.00 * ((100vw - 768px) / 672)), 120px);
  padding-bottom: clamp(48px, calc(48px + 72.00 * ((100vw - 768px) / 672)), 120px);
  position: relative;
  z-index: 10;
  overflow: hidden;
}

.logos-section__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

.logos-section__slider-buttons .slider-btn {
      margin-top: clamp(26px, calc(26px + 6.00 * ((100vw - 768px) / 672)), 32px);
    }

.logos-section__slider {
    overflow: visible;
  }

.logos-section__slider:not(.swiper-initialized) {
      display: none;
    }

.logos-section__item {
    padding: clamp(12.59px, calc(12.59px + 3.41 * ((100vw - 768px) / 672)), 16px);
    border-radius: 10px;
    background: linear-gradient(135deg, rgba(121,0,231,1) 0%, rgba(27,212,237,1) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
  }

.logos-section__item:after {
      position: absolute;
      inset: 2px;
      border-radius: 8px;
      background: var(--cl-white);
      transition: background-color .3s ease;
      z-index: 1;
      content: "";
    }

.logos-section__item:hover:after {
        background-color: var(--cl-lilac);
      }

.logos-section__item-image {
    height: clamp(93px, calc(93px + 25.00 * ((100vw - 768px) / 672)), 118px);
    width: auto;
    position: relative;
    z-index: 2;
  }

.logos-section__item-image img {
      width: 100%;
      height: 100%;
      -o-object-fit: contain;
         object-fit: contain;
    }

@media not all and (min-width: 551px) {
      .logos-section .logos-section__slider {
        max-width: 240px;
      }
	}

.roles-section {
  position: relative;
  z-index: 5;
  padding-top: clamp(96px, calc(96px + 104.00 * ((100vw - 768px) / 672)), 200px);
  padding-bottom: clamp(235px, calc(277px + -42.00 * ((100vw - 375px) / 1065)), 277px);
  background: var(--cl-turquoise);
  overflow: hidden;
}

.roles-section__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
  }

.roles-section__bg-decor {
    position: relative;
    overflow: hidden;
    /* fix for hover scale on image on Safari 15.1 */
    -webkit-mask-image: radial-gradient(white, black);
            mask-image: radial-gradient(white, black);
  }

.roles-section__bg-decor::before {
		content: "";
		display: block;
		padding-bottom: max(min(93.88009578734076%, 9999px), 0px);
	}

.roles-section__bg-decor img, .roles-section__bg-decor svg, .roles-section__bg-decor video, .roles-section__bg-decor iframe {
		width: 100%;
		height: 100%;
		position: absolute;
		-o-object-fit: cover;
		   object-fit: cover;
		top: 0;
		left: 0;
	}

.roles-section__bg-decor[data-parallax-el] {
		overflow: visible;
		-webkit-mask-image: none;
		        mask-image: none;
	}

.roles-section__bg-decor[data-parallax-el] img, .roles-section__bg-decor[data-parallax-el] svg {
			position: absolute !important;
		}

.roles-section__bg-decor{
    position: absolute;
    width: clamp(576px, calc(576px + 835.00 * ((100vw - 768px) / 672)), 1411px);
    top: clamp(-25px, calc(101px + -126.00 * ((100vw - 768px) / 672)), 101px);
    left: clamp(-52px, calc(-52px + 198.00 * ((100vw - 768px) / 672)), 146px);
    z-index: -1;
    mix-blend-mode: soft-light;
    opacity: .70;
}

@media not all and (min-width: 360px) {

.roles-section__bg-decor {
      opacity: 1
  }
  }

.roles-section__content {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

.roles-section__content:not(:last-child) {
      margin-bottom: clamp(32px, calc(32px + 18.00 * ((100vw - 768px) / 672)), 50px);
    }

.roles-section__title {
    width: calc(100% - 300px);
  }

.roles-section__title h2 {
      color: var(--cl-navy);
    }

.roles-section__buttons {
    justify-content: flex-end;
    margin-bottom: clamp(0px, calc(0px + 14.00 * ((100vw - 768px) / 672)), 14px);
  }

@media not all and (min-width: 551px) {

.roles-section__buttons {
		width: 100%;
  }

		.roles-section__buttons .btn {
			width: 100%;
		}

			.roles-section__buttons .btn__text {
				text-align: left;
				justify-content: flex-start;
			}
	}

.roles-section__slider-buttons .slider-btn {
      margin-top: clamp(32px, calc(32px + 16.00 * ((100vw - 768px) / 672)), 48px);
    }

.roles-section__slider {
    overflow: visible;
  }

.roles-section__slider:not(.swiper-initialized) {
      display: none;
    }

@media not all and (min-width: 640px) {
      .roles-section .roles-section__content {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      .roles-section .roles-section__title {
        width: 100%;
        margin-bottom: 24px;
      }

      .roles-section .roles-section__buttons {
        width: 100%;
      }
  }

.specialism-section {
  padding-top: clamp(20px, calc(20px + 20.00 * ((100vw - 768px) / 672)), 40px);
  padding-bottom: clamp(20px, calc(20px + 20.00 * ((100vw - 768px) / 672)), 40px);
  position: relative;
  z-index: 10;
}

.specialism-section__color-bg {
    background: var(--cl-beige);
    position: relative;
  }

.specialism-section__color-bg .cont-main {
      position: relative;
    }

.specialism-section__wrap {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: clamp(32px, calc(32px + 18.00 * ((100vw - 768px) / 672)), 50px);
    padding-bottom: clamp(24px, calc(24px + 30.00 * ((100vw - 768px) / 672)), 54px);
  }

.specialism-section__title {
    width: 100%;
    padding-right: 51.21%;
  }

.specialism-section__title:not(:last-child) {
      margin-bottom: clamp(26px, calc(26px + 24.00 * ((100vw - 768px) / 672)), 50px);
    }

@media not all and (min-width: 768px) {

.specialism-section__title{
      padding-right: 0;
  }
  }

@media (min-width: 768px) {

.specialism-section__slider-buttons {
      display: none;
  }
  }

.specialism-section__slider-buttons:not(:last-child) {
      margin-bottom: clamp(24px, calc(24px + 8.00 * ((100vw - 768px) / 672)), 32px);
    }

.specialism-section__list {
    width: calc(51.21% - clamp(24px, calc(24px + 81.00 * ((100vw - 768px) / 672)), 105px));
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: clamp(547px, calc(547px + 100.00 * ((100vw - 768px) / 672)), 647px);
  }

@media (min-width: 640px) {

.specialism-section__list {
      padding-right: 10px;
  }
  }

@media not all and (min-width: 768px) {

.specialism-section__list {
      display: none;
  }
  }

.specialism-section__list li {
      font-size: clamp(20px, calc(20px + 5.00 * ((100vw - 768px) / 672)), 25px);
      line-height: 1.4;
      transition: all .3s ease;
      padding-left: 0;
      font-weight: 400;
      color: var(--cl-black);
      position: relative;
      cursor: pointer;
      font-family: var(--font-main);
    }

.specialism-section__list li:before {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        height: 1px;
        background: var(--cl-leaf);
        transition: width .3s ease;
        content: "";
        width: 0;
      }

.specialism-section__list li:not(:last-child) {
        margin-bottom: clamp(16px, calc(16px + 4.00 * ((100vw - 768px) / 672)), 20px);
      }

.specialism-section__list li.active {
        padding-left: clamp(24px, calc(24px + 14.00 * ((100vw - 768px) / 672)), 38px);
        font-size: clamp(22px, calc(22px + 7.00 * ((100vw - 768px) / 672)), 29px);
        font-weight: 600;
        color: var(--cl-leaf);
        letter-spacing: -0.5px;
      }

.specialism-section__list li.active:before {
          width: clamp(18px, calc(18px + 12.00 * ((100vw - 768px) / 672)), 30px);
        }

.specialism-section__list li.active:not(:last-child) {
          margin-bottom: clamp(14px, calc(14px + 1.00 * ((100vw - 768px) / 672)), 15px);
        }

@media not all and (min-width: 1439px) {

.specialism-section__list li.active{
          font-size: clamp(22px, calc(22px + 4.00 * ((100vw - 768px) / 672)), 26px);
  }
	}

@media not all and (min-width: 1100px) {

.specialism-section__list li.active{
          font-size: clamp(20px, calc(20px + 2.00 * ((100vw - 768px) / 672)), 22px);
  }
	}

@media not all and (min-width: 768px) {

.specialism-section__list li.active{
          font-size: 22px;
  }
  }

.specialism-section__list li:not(.active):hover {
          color: var(--cl-mint);
        }

.specialism-section__slider-container {
    /*padding-top: ac(227px, 190px);*/
    width: 48.84%;
    padding-right: clamp(6px, calc(20px + -14.00 * ((100vw - 768px) / 672)), 20px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

@media not all and (min-width: 951px) {

.specialism-section__slider-container {
      padding-right: 0;
      padding-left: 20px
  }
	}

@media not all and (min-width: 768px) {

.specialism-section__slider-container {
      width: 100%;
      padding-left: 0
  }
  }

@media not all and (min-width: 640px) {

.specialism-section__slider-container {
      padding-top: 100px;
      position: relative;
      min-height: 544px;
      padding-bottom: 24px
  }
  }

.specialism-section__slider-image {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 48.95%;
  }

.specialism-section__slider-image img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
      -o-object-position: right bottom;
         object-position: right bottom;
    }

@media not all and (min-width: 640px) {

.specialism-section__slider-image{
      left: calc((-100vw - -87.20vw) / 2);
      right: calc((-100vw - -87.20vw) / 2);
      width: auto;
  }
      
      .specialism-section__slider-image img {
        -o-object-position: top left;
           object-position: top left;
      }
  }

.specialism-section__slider-image:before {
      position: absolute;
      inset: 0;
      z-index: 1;
      content: "";
      background: var(--cl-beige);
      mix-blend-mode: color;
    }

.specialism-section__slider {
    margin-top: auto;
    overflow: hidden;
    margin-left: clamp(-105px, calc(-24px + -81.00 * ((100vw - 768px) / 672)), -24px);
  }

@media not all and (min-width: 951px) {

.specialism-section__slider {
      margin-left: 0;
      margin-right: -10px
  }
	}

@media not all and (min-width: 640px) {

.specialism-section__slider {
      overflow: visible;
      max-width: 297px
  }
  }

@media not all and (min-width: 390px) {

.specialism-section__slider {
      max-width: 279px
  }
	}

.specialism-section__item {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: clamp(22px, calc(22px + 28.00 * ((100vw - 768px) / 672)), 50px) clamp(20px, calc(20px + 30.00 * ((100vw - 768px) / 672)), 50px) clamp(19px, calc(19px + 19.00 * ((100vw - 768px) / 672)), 38px);
    min-height: clamp(272px, calc(272px + 141.00 * ((100vw - 768px) / 672)), 413px);
    background: var(--cl-mint);
    height: auto;
  }

.specialism-section__item-title {
    font-size: clamp(22px, calc(22px + 28.00 * ((100vw - 768px) / 672)), 50px);
    line-height: 1.2;
    font-weight: 600;
  }

@media not all and (min-width: 640px) {

.specialism-section__item-title {
      line-height: 1.4;
  }
  }

.specialism-section__item-title:not(:last-child) {
      margin-bottom: clamp(8px, calc(8px + 12.00 * ((100vw - 768px) / 672)), 20px);
    }

.specialism-section__item-text.big-size p {
        font-family: var(--font-second);
        font-weight: 500;
        font-size: clamp(14px, calc(14px + 10.00 * ((100vw - 768px) / 672)), 24px);
        line-height: 1.42;
      }

.specialism-section__item-text.big-size[data-simplebar] {
        max-height: calc((clamp(14px, calc(14px + 10.00 * ((100vw - 768px) / 672)), 24px) * 1.42) * 6);
      }

.specialism-section__item-text:not(:last-child) {
      margin-bottom: clamp(8px, calc(8px + 12.00 * ((100vw - 768px) / 672)), 20px);
    }

.specialism-section__item-text[data-simplebar] {
      width: calc(100% + 10px);
      padding-right: 10px;
    }

.specialism-section__item-text[data-simplebar] .simplebar-track.simplebar-vertical {
        width: clamp(3px, calc(3px + 1.00 * ((100vw - 768px) / 672)), 4px);
        background: var(--cl-background);
        border-radius: 0;
      }

.specialism-section__item-text[data-simplebar] .simplebar-track.simplebar-vertical .simplebar-scrollbar {
          background: var(--cl-leaf);
          border-radius: 0;
        }

.specialism-section__item-text[data-simplebar] .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
            content: none;
          }

.specialism-section__item-links {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

@media not all and (min-width: 640px) {

.specialism-section{
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 44px;
  }

    .specialism-section .specialism-section__wrap {
      padding-bottom: 0;
    }
  }

.credentials-section {
  position: relative;
  z-index: 2;
  padding-top: clamp(20px, calc(20px + 20.00 * ((100vw - 768px) / 672)), 40px);
  padding-bottom: clamp(32px, calc(32px + 8.00 * ((100vw - 768px) / 672)), 40px);
  overflow: visible;
}

.credentials-section__page-decor {
    position: relative;
    overflow: hidden;
    /* fix for hover scale on image on Safari 15.1 */
    -webkit-mask-image: radial-gradient(white, black);
            mask-image: radial-gradient(white, black);
  }

.credentials-section__page-decor::before {
		content: "";
		display: block;
		padding-bottom: max(min(114.69932313470794%, 9999px), 0px);
	}

.credentials-section__page-decor img, .credentials-section__page-decor svg, .credentials-section__page-decor video, .credentials-section__page-decor iframe {
		width: 100%;
		height: 100%;
		position: absolute;
		-o-object-fit: cover;
		   object-fit: cover;
		top: 0;
		left: 0;
	}

.credentials-section__page-decor[data-parallax-el] {
		overflow: visible;
		-webkit-mask-image: none;
		        mask-image: none;
	}

.credentials-section__page-decor[data-parallax-el] img, .credentials-section__page-decor[data-parallax-el] svg {
			position: absolute !important;
		}

.credentials-section__page-decor{
    position: absolute;
    z-index: -1;
    top: clamp(-210px, calc(-180px + -30.00 * ((100vw - 768px) / 672)), -180px);
    width: clamp(400px, calc(400px + 366.77 * ((100vw - 768px) / 672)), 766.77px);
    left: clamp(-206px, calc(-100px + -106.00 * ((100vw - 768px) / 672)), -100px);
}

/*top: ac(-170px, -100px);*/

@media not all and (min-width: 640px) {

.credentials-section__page-decor {
      left: 0;
      width: 88.80%;
      max-width: 400px
  }
  }

.credentials-section__wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
  }

.credentials-section__title {
    width: 100%;
  }

.credentials-section__title:not(:last-child) {
      margin-bottom: clamp(22px, calc(22px + 36.00 * ((100vw - 768px) / 672)), 58px);
    }

/*.ml {
      margin-left: ac(76px, 32px);
    }*/

.credentials-section__title h2 br + span {
          margin-left: clamp(32px, calc(32px + 40.00 * ((100vw - 768px) / 672)), 72px);
        }

.credentials-section__slider-buttons .slider-btn {
      margin-bottom: clamp(38px, calc(38px + 2.00 * ((100vw - 768px) / 672)), 40px);
    }

.credentials-section__slider {
    overflow: visible;
    margin-left: 0;
  }

.credentials-section__slider:not(.swiper-initialized) {
      display: none;
    }

@media not all and (min-width: 451px) {

.credentials-section__slider{
      max-width: 184px;
  }
	}

.credentials-section__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

.credentials-section__item-count {
    padding-top: clamp(1px, calc(1px + 6.00 * ((100vw - 768px) / 672)), 7px);
    padding-bottom: clamp(2px, calc(2px + 3.00 * ((100vw - 768px) / 672)), 5px);
    padding-left: clamp(10px, calc(10px + 8.00 * ((100vw - 768px) / 672)), 18px);
    position: relative;
    font-size: clamp(40px, calc(40px + 30.00 * ((100vw - 768px) / 672)), 70px);
    font-weight: 600;
    line-height: clamp(48px, calc(48px + 32.00 * ((100vw - 768px) / 672)), 80px);
  }

.credentials-section__item-count:before {
      top: 0;
      bottom: 0;
      left: 0;
      content: "";
      position: absolute;
      background: var(--cl-leaf);
      width: clamp(2.5px, calc(2.5px + 1.50 * ((100vw - 768px) / 672)), 4px);
      transform: translateX(-50%);
    }

@media not all and (min-width: 640px) {

.credentials-section__item-count:before {
        transform: translateX(0)
    }
  }

.credentials-section__item-count:not(:last-child) {
      margin-bottom: clamp(14px, calc(14px + 14.00 * ((100vw - 768px) / 672)), 28px);
    }

.credentials-section__item-title {
    font-family: var(--font-main);
    font-weight: 700;
    font-size: clamp(16px, calc(16px + 8.00 * ((100vw - 768px) / 672)), 24px);
    line-height: 1.42;
  }

.credentials-section__item-title:not(:last-child) {
      margin-bottom: clamp(6px, calc(6px + 9.00 * ((100vw - 768px) / 672)), 15px);
    }

.credentials-section__item-text p, .credentials-section__item-text li {
      font-size: clamp(14px, calc(14px + 2.00 * ((100vw - 768px) / 672)), 16px);
    }

@media not all and (min-width: 640px) {

.credentials-section__item-text p, .credentials-section__item-text li {
        line-height: 1.42
    }
  }

.credentials-section__item-text[data-simplebar] {
      max-height: calc((clamp(14px, calc(14px + 2.00 * ((100vw - 768px) / 672)), 16px) * 1.62) * 8);
      width: calc(100% + 10px);
      padding-right: 10px;
    }

.credentials-section__item-text[data-simplebar] .simplebar-track.simplebar-vertical {
        width: clamp(3px, calc(3px + 1.00 * ((100vw - 768px) / 672)), 4px);
        background: var(--cl-beige);
        border-radius: 0;
      }

.credentials-section__item-text[data-simplebar] .simplebar-track.simplebar-vertical .simplebar-scrollbar {
          background: var(--cl-leaf);
          border-radius: 0;
        }

.credentials-section__item-text[data-simplebar] .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
            content: none;
          }

@media not all and (min-width: 640px) {

.credentials-section__item-text[data-simplebar]{
        max-height: calc((clamp(14px, calc(14px + 2.00 * ((100vw - 768px) / 672)), 16px) * 1.42) * 8);
  }
  }

.testimonials-section {
  position: relative;
  z-index: 5;
  margin-top: clamp(-471px, calc(-471px + 81.00 * ((100vw - 768px) / 672)), -390px);
  padding-top: clamp(390px, calc(471px + -81.00 * ((100vw - 768px) / 672)), 471px);
  padding-bottom: clamp(40px, calc(40px + 40.00 * ((100vw - 768px) / 672)), 80px);
  overflow: hidden;
  border-top-left-radius: 5px;
}

.testimonials-section__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: clamp(-220px, calc(-220px + 186.00 * ((100vw - 768px) / 672)), -34px);
    bottom: clamp(-221px, calc(0px + -221.00 * ((100vw - 768px) / 672)), 0px);
    z-index: -2;
  }

.testimonials-section__bg img {
      width: 100%;
      height: 100%;
      -o-object-fit: fill;
         object-fit: fill;
    }

@media (min-width: 1500px) {
      .testimonials-section__bg img {
        -o-object-fit: cover;
           object-fit: cover;
      }
	}

@media (min-width: 640px) {

.testimonials-section__bg--mob {
        display: none
    }
  }

@media not all and (min-width: 640px) {

.testimonials-section__bg--desk {
        display: none
    }
  }

.testimonials-section__wrap {
    padding-top: clamp(56px, calc(56px + 64.00 * ((100vw - 768px) / 672)), 120px);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    grid-column-gap: clamp(24px, calc(24px + 6.00 * ((100vw - 768px) / 672)), 30px);
  }

@media not all and (min-width: 768px) {

.testimonials-section__wrap {
      flex-direction: column
  }
  }

.testimonials-section__content {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
  }

.testimonials-section__content .slider-buttons {
      justify-content: flex-end;
      position: relative;
      z-index: 10;
    }

@media (min-width: 1280px) {

.testimonials-section__content .slider-buttons .slider-btn {
          margin-top: 0;
          margin-bottom: clamp(16px, calc(16px + 8.00 * ((100vw - 768px) / 672)), 24px)
      }
  }

@media not all and (min-width: 640px) {

.testimonials-section__content .slider-buttons .slider-btn {
          margin-top: 28px
      }
  }

.testimonials-section__content:not(:last-child) {
      margin-bottom: clamp(32px, calc(32px + 10.00 * ((100vw - 768px) / 672)), 42px);
    }

.testimonials-section__title-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: clamp(270px, calc(270px + 470.00 * ((100vw - 768px) / 672)), 740px);
    position: relative;
  }

.testimonials-section__title-container:not(:last-child) {
      margin-bottom: clamp(24px, calc(24px + 8.00 * ((100vw - 768px) / 672)), 32px);
    }

.testimonials-section__duplicate-title {
    font-size: clamp(36px, calc(36px + 88.00 * ((100vw - 768px) / 672)), 124px);
    font-weight: 800;
    line-height: normal;
    font-family: var(--font-second);
    color: var(--cl-white);
    mix-blend-mode: overlay;
    position: absolute;
    left: clamp(0px, calc(17px + -17.00 * ((100vw - 768px) / 672)), 17px);
    top: clamp(-10px, calc(6px + -16.00 * ((100vw - 768px) / 672)), 6px);
    width: clamp(310px, calc(310px + 740.00 * ((100vw - 768px) / 672)), 1050px);
    opacity: 0;
    transition: opacity 1s ease, transform 1s ease;
    will-change: opacity, transform;
  }

.testimonials-section__duplicate-title span:first-child {
        margin-left: clamp(80px, calc(80px + 215.00 * ((100vw - 768px) / 672)), 295px);
      }

@media not all and (min-width: 360px) {

.testimonials-section__duplicate-title span:first-child {
          margin-left: 40px
      }
  }

.testimonials-section__title {
    position: relative;
    z-index: 1;
  }

.testimonials-section__title.animated + .testimonials-section__duplicate-title {
        opacity: .3;
      }

.testimonials-section__slider {
    width: 100%;
    max-width: 100%;
  }

.testimonials-section__slider:not(.swiper-initialized) {
      display: none;
    }

@media not all and (min-width: 551px) {

.testimonials-section__slider{
      overflow: visible;
  }
	}

.testimonials-section__cubes {
    position: relative;
    /*mix-blend-mode: soft-light;*/
  }

.testimonials-section__cubes::before {
		content: "";
		display: block;
		padding-bottom: max(min(91.67838724800751%, 9999px), 0px);
	}

.testimonials-section__cubes{
    position: absolute;
    bottom: clamp(-340px, calc(188px + -528.00 * ((100vw - 768px) / 672)), 188px);
    right: clamp(-248px, calc(-220px + -28.00 * ((100vw - 768px) / 672)), -220px);
    width: 99.83%;
    min-width: 607px;
    max-width: 1279px;
    z-index: -1;
    opacity: .2;
    pointer-events: none;
}

.testimonials-section__cube:nth-child(1) {
      position: relative;
      overflow: hidden;
      /* fix for hover scale on image on Safari 15.1 */
      -webkit-mask-image: radial-gradient(white, black);
              mask-image: radial-gradient(white, black);
    }

.testimonials-section__cube:nth-child(1)::before {
		content: "";
		display: block;
		padding-bottom: max(min(90.19185213050417%, 9999px), 0px);
	}

.testimonials-section__cube:nth-child(1) img, .testimonials-section__cube:nth-child(1) svg, .testimonials-section__cube:nth-child(1) video, .testimonials-section__cube:nth-child(1) iframe {
		width: 100%;
		height: 100%;
		position: absolute;
		-o-object-fit: cover;
		   object-fit: cover;
		top: 0;
		left: 0;
	}

.testimonials-section__cube:nth-child(1)[data-parallax-el] {
		overflow: visible;
		-webkit-mask-image: none;
		        mask-image: none;
	}

.testimonials-section__cube:nth-child(1)[data-parallax-el] img, .testimonials-section__cube:nth-child(1)[data-parallax-el] svg {
			position: absolute !important;
		}

.testimonials-section__cube:nth-child(1){
      width: 76.85%;
      position: absolute;
      top: 0;
      right: 0;
}

.testimonials-section__cube:nth-child(2) {
      position: relative;
      overflow: hidden;
      /* fix for hover scale on image on Safari 15.1 */
      -webkit-mask-image: radial-gradient(white, black);
              mask-image: radial-gradient(white, black);
    }

.testimonials-section__cube:nth-child(2)::before {
		content: "";
		display: block;
		padding-bottom: max(min(99.98700285937095%, 9999px), 0px);
	}

.testimonials-section__cube:nth-child(2) img, .testimonials-section__cube:nth-child(2) svg, .testimonials-section__cube:nth-child(2) video, .testimonials-section__cube:nth-child(2) iframe {
		width: 100%;
		height: 100%;
		position: absolute;
		-o-object-fit: cover;
		   object-fit: cover;
		top: 0;
		left: 0;
	}

.testimonials-section__cube:nth-child(2)[data-parallax-el] {
		overflow: visible;
		-webkit-mask-image: none;
		        mask-image: none;
	}

.testimonials-section__cube:nth-child(2)[data-parallax-el] img, .testimonials-section__cube:nth-child(2)[data-parallax-el] svg {
			position: absolute !important;
		}

.testimonials-section__cube:nth-child(2){
      width: 60.12%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
}

@media not all and (min-width: 640px) {
      .testimonials-section .testimonials-section__content {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      .testimonials-section .testimonials-section__title-container {
        margin-bottom: 0;
      }
  }

.testimonials-section + .stats-section {
    margin-top: clamp(48px, calc(48px + 32.00 * ((100vw - 768px) / 672)), 80px);
  }

.get-in-touch-section {
  position: relative;
  z-index: 50;
  overflow: visible;
}

.get-in-touch-section__wrap {
    position: relative;
  }

.get-in-touch-section__container {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-45.7%) translateZ(0);
    border-radius: clamp(12px, calc(12px + 8.00 * ((100vw - 768px) / 672)), 20px);
    -webkit-clip-path: inset(0 0 0 0 round clamp(12px, calc(12px + 8.00 * ((100vw - 768px) / 672)), 20px));
            clip-path: inset(0 0 0 0 round clamp(12px, calc(12px + 8.00 * ((100vw - 768px) / 672)), 20px));
    padding: clamp(40px, calc(40px + 20.00 * ((100vw - 768px) / 672)), 60px) clamp(24px, calc(24px + 36.00 * ((100vw - 768px) / 672)), 60px);
    z-index: 10;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    max-width: 1278px;
    margin-left: clamp(-4px, calc(0px + -4.00 * ((100vw - 768px) / 672)), 0px);
    overflow: hidden;
  }

.get-in-touch-section__title-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 35.92%;
  }

.get-in-touch-section__title:not(:last-child) {
      margin-bottom: clamp(23px, calc(23px + 17.00 * ((100vw - 768px) / 672)), 40px);
    }

.get-in-touch-section__text {
    margin-top: clamp(60px, calc(60px + 44.00 * ((100vw - 768px) / 672)), 104px);
    width: 40.41%;
    margin-left: clamp(-20px, calc(-20px + 15.00 * ((100vw - 768px) / 672)), -5px);
  }

@media not all and (min-width: 640px) {
        .get-in-touch-section__text.small-size p, .get-in-touch-section__text.small-size li {
          line-height: 24px;
        }
  }

@media not all and (min-width: 551px) {

.get-in-touch-section__buttons {
		width: 100%;
  }

		.get-in-touch-section__buttons .btn {
			width: 100%;
		}

			.get-in-touch-section__buttons .btn__text {
				text-align: left;
				justify-content: flex-start;
			}
	}

.get-in-touch-section__bg-image {
    position: absolute;
    inset: 0;
    z-index: -2;
  }

.get-in-touch-section__bg-image img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
    }

@media (min-width: 640px) {

.get-in-touch-section__bg-image--mob {
        display: none
    }
  }

@media not all and (min-width: 640px) {

.get-in-touch-section__bg-image--desk {
        display: none
    }
  }

.get-in-touch-section__bg-decor-container {
    position: relative;
  }

.get-in-touch-section__bg-decor-container::before {
		content: "";
		display: block;
		padding-bottom: max(min(100.40241448692153%, 9999px), 0px);
	}

.get-in-touch-section__bg-decor-container{
    width: clamp(378px, calc(378px + 119.00 * ((100vw - 768px) / 672)), 497px);
    position: absolute;
    mix-blend-mode: soft-light;
    bottom: 23px;
    right: clamp(-162px, calc(-162px + 60.00 * ((100vw - 768px) / 672)), -102px);
}

.get-in-touch-section__bg-decor {
    position: relative;
    overflow: hidden;
    /* fix for hover scale on image on Safari 15.1 */
    -webkit-mask-image: radial-gradient(white, black);
            mask-image: radial-gradient(white, black);
  }

.get-in-touch-section__bg-decor::before {
		content: "";
		display: block;
		padding-bottom: max(min(100.40241448692153%, 9999px), 0px);
	}

.get-in-touch-section__bg-decor img, .get-in-touch-section__bg-decor svg, .get-in-touch-section__bg-decor video, .get-in-touch-section__bg-decor iframe {
		width: 100%;
		height: 100%;
		position: absolute;
		-o-object-fit: cover;
		   object-fit: cover;
		top: 0;
		left: 0;
	}

.get-in-touch-section__bg-decor[data-parallax-el] {
		overflow: visible;
		-webkit-mask-image: none;
		        mask-image: none;
	}

.get-in-touch-section__bg-decor[data-parallax-el] img, .get-in-touch-section__bg-decor[data-parallax-el] svg {
			position: absolute !important;
		}

.get-in-touch-section__bg-decor{
    width: 100%;
}

@media not all and (min-width: 1100px) {
      .get-in-touch-section .get-in-touch-section__title-container {
        width: 42%;
      }

      .get-in-touch-section .get-in-touch-section__text {
        margin-left: 10px;
        width: 55%;
      }
	}

@media not all and (min-width: 768px) {
      .get-in-touch-section .get-in-touch-section__container {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      .get-in-touch-section .get-in-touch-section__title-container {
        width: 100%;
      }

      .get-in-touch-section .get-in-touch-section__title {
        max-width: 300px;
      }

      .get-in-touch-section .get-in-touch-section__text {
        margin-top: 32px;
        margin-left: 0;
        width: 100%;
      }
  }

@media not all and (min-width: 640px) {
      .get-in-touch-section .get-in-touch-section__bg-decor-container {
        bottom: -161px;
        right: -160px;
        transform: rotate(180deg) translateY(-50%);
      }

      .get-in-touch-section .get-in-touch-section__container {
        min-height: 440px;
        left: -12px;
        right: -12px;
        transform: translateY(-50%) translateZ(0);
      }
  }

@media not all and (min-width: 360px) {
      .get-in-touch-section .get-in-touch-section__container {
        left: -6px;
        right: -6px;
      }
  }

/* Common effects */

[data-view] {
  --distance: 30px;
  --view-speed: 0.5s;
  --view-easing: ease-out;
  transition-duration: var(--view-speed);
  transition-timing-function: var(--view-easing);
  will-change: transform, opacity;
}

[data-view].animated {
    opacity: 1;
    transform: none;
  }

[data-view='fade'], [data-view^='fade'], [data-view='zoom'], [data-view^='zoom'] {
  opacity: 0;
  transition-property: opacity, transform;
}

/* Slide effect */

[data-view='slide'], [data-view^='slide'] {
  transition-property: transform;
}

[data-view='slide'].animated, [data-view^='slide'].animated {
    transform: none;
  }

/* Fade and slide effects */

[data-view='slide-tl'], [data-view='fade-tl'] {
  transform: translateY(var(--distance)) translateX(var(--distance));
}

[data-view='slide-tr'], [data-view='fade-tr'] {
  transform: translateY(var(--distance)) translateX(calc(var(--distance) * -1));
}

[data-view='slide-bl'], [data-view='fade-bl'] {
  transform: translateY(calc(var(--distance) * -1)) translateX(var(--distance));
}

[data-view='slide-br'], [data-view='fade-br'] {
  transform: translateY(calc(var(--distance) * -1))
  translateX(calc(var(--distance) * -1));
}

[data-view='fade-l'], [data-view='slide-l'] {
  transform: translateX(var(--distance));
}

[data-view='fade-r'], [data-view='slide-r'] {
  transform: translateX(calc(var(--distance) * -1));
}

[data-view='fade-t'], [data-view='slide-t'] {
  transform: translateY(var(--distance));
}

[data-view='fade-b'], [data-view='slide-b'] {
  transform: translateY(calc(var(--distance) * -1));
}

/* Zoom effect */

[data-view^='zoom'].animated, [data-view^='zoom'].animated {
    opacity: 1;
    transform: none scale(1);
  }

[data-view='zoom'] {
  transform: scale(0.1);
}

[data-view='zoom-l'] {
  transform: translateX(var(--distance)) scale(0.1);
}

[data-view='zoom-tl'] {
  transform: translateY(var(--distance)) translateX(var(--distance)) scale(0.1);
}

[data-view='zoom-tr'] {
  transform: translateY(var(--distance)) translateX(calc(var(--distance) * -1))
  scale(0.1);
}

[data-view='zoom-br'] {
  transform: translateY(calc(var(--distance) * -1))
  translateX(calc(var(--distance) * -1)) scale(0.1);
}

[data-view='zoom-bl'] {
  transform: translateY(calc(var(--distance) * -1)) translateX(var(--distance))
  scale(0.1);
}

[data-view='zoom-r'] {
  transform: translateX(calc(var(--distance) * -1)) scale(0.1);
}

[data-view='zoom-t'] {
  transform: translateY(var(--distance)) scale(0.1);
}

[data-view='zoom-b'] {
  transform: translateY(calc(var(--distance) * -1)) scale(0.1);
}

/* Rotate effect */

[data-view^='rotate'], [data-view^='rotate'] {
  opacity: 0;
  transform: rotate(180deg);
}

[data-view='rotate-l'] {
  transform: rotate(180deg) translateX(calc(var(--distance) * -1));
}

[data-view='rotate-r'] {
  transform: rotate(180deg) translateX(var(--distance));
}

[data-view='rotate-t'] {
  transform: rotate(180deg) translateY(calc(var(--distance) * -1));
}

[data-view='rotate-b'] {
  transform: rotate(180deg) translateY(var(--distance));
}

/*# sourceMappingURL=style.css.map */
